import React from "react";

const Value = () => {
  const BulletIcon = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill="url(#paint0_linear_590_6015)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_6015"
          x1="-2.84483"
          y1="3.33333"
          x2="35.2274"
          y2="6.02122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <div className=" px-60 py-20 flex flex-wrap">
      <div className="w-1/2">
        <h2 className="text-5xl font-bold text-left pb-4">
          Why Is It So <span className="text-custom-primary">Valuable?</span>
        </h2>
        <p className=" text-base">
          Lorem ipsum dolor sit amet consectetur. Convallis ut elementum magna
          amet risus commodo.{" "}
        </p>
      </div>
      <div className="w-1/2">
        <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            {BulletIcon}
          </div>
          <p className="text-lg font-semibold w-11/12 ">
            Mesenchymal stem cells are used extensively in the exciting area of
            regenerative medicine research.{" "}
          </p>
        </div>
        <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            {BulletIcon}
          </div>
          <p className="text-lg font-semibold w-11/12 ">
            Mesenchymal stem cells are used extensively in the exciting area of
            regenerative medicine research.{" "}
          </p>
        </div>
        <div className="p-5 pb-6 flex items-center border-b border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            {BulletIcon}
          </div>
          <p className="text-lg font-semibold w-11/12 ">
            Mesenchymal stem cells are used extensively in the exciting area of
            regenerative medicine research.{" "}
          </p>
        </div>
        <div className="p-5 pb-6 flex items-center ">
          <div id="icon" className="w-1/12">
            {BulletIcon}
          </div>
          <p className="text-lg font-semibold w-11/12 ">
            Mesenchymal stem cells are used extensively in the exciting area of
            regenerative medicine research.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Value;
