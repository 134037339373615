import React, { useEffect, useState } from "react";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";

const ResourcesMiniNav = () => {
  const [url, setUrl] = useState("");
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    setUrl(currentUrl);

    if (currentUrl.includes("blog")) {
      console.log('The URL contains "blog".');
      setActiveClass("blog");
    } else if (currentUrl.includes("news")) {
      console.log('The URL contains "news".');
      setActiveClass("news");
    } else if (currentUrl.includes("featured-moms")) {
      console.log('The URL contains "news".');
      setActiveClass("moms");
    } else {
      console.log('The URL does not contain "blog" or "news".');
      setActiveClass("");
    }
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <ParentContainer containerHeight={"py-1"} containerStyle={"border-b"}>
      <div className="flex gap-7 py-2 max-md:justify-center">
        <Link
          to="/blog"
          className={`text-base font-semibold hover:text-custom-primary underline inline ${
            activeClass === "blog" ? "text-custom-primary" : ""
          }`}
        >
          Blogs
        </Link>
        <Link
          to="/news"
          className={`text-base font-semibold hover:text-custom-primary underline inline ${
            activeClass === "news" ? "text-custom-primary" : ""
          }`}
        >
          News
        </Link>
        <Link
          to="/featured-moms"
          className={`text-base font-semibold hover:text-custom-primary underline inline ${
            activeClass === "moms" ? "text-custom-primary" : ""
          }`}
        >
          Featured Moms
        </Link>
      </div>
    </ParentContainer>
  );
};

export default ResourcesMiniNav;
