import React, { useEffect, useState } from "react";
import HeroSection from "./RegenerativeMedicine/HeroSection";
import Uses from "./RegenerativeMedicine/Uses";
import Value from "./RegenerativeMedicine/Value";
import Power from "./RegenerativeMedicine/Power";
import CryoInfinite from "./RegenerativeMedicine/CryoInfinite";
import Why from "./RegenerativeMedicine/Why";
import Limitless from "./RegenerativeMedicine/Limitless";
import MasterStem from "./RegenerativeMedicine/MasterStem";
import ArabiaCell from "./RegenerativeMedicine/ArabiaCell";
import TranslplantNumbers from "../components/TranslplantNumbers";
import assets from "../assets/assets";
import MainHeroSection from "../components/MainHeroSection";
import MainValuable from "../components/MainValuable";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";

const RegenerativeMedicine = () => {
  const [regmed, setRegmed] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/regenerative-medicine?populate=Translpant_Numbers,Translpant_Numbers.image,Hero_Section,Hero_Section.Video,Uses_Image,Uses_List,Why_Valuable,Why_Valuable.Points,Power_Image,Why_CryoInfinite,Why_CryoInfinite.Points,Cell_Bank_Image,Steps_Step,Steps_Step.Image"
        );

        setRegmed(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (!regmed) {
    return <div>Error loading data.</div>;
  }
  const [beforeDelimiter, afterDelimiter] =
    regmed.data.attributes.Hero_Section.Heading.split("||");
  const [beforeDelimiter1, afterDelimiter1] =
    regmed.data.attributes.Why_Valuable.Heading.split("||");
  const [beforeDelimiter2, afterDelimiter2] =
    regmed.data.attributes.Why_CryoInfinite.Heading.split("||");

  return (
    <div>
      <MainHeroSection
        HeadingBlack={beforeDelimiter}
        HeadingBreak={true}
        HeadingBlue={afterDelimiter}
        HeadingPara={regmed.data.attributes.Hero_Section.Paragraph}
        IconOne={regmed.data.attributes.Hero_Section.Point_One}
        IconTwo={regmed.data.attributes.Hero_Section.Point_Two}
        IconThree={regmed.data.attributes.Hero_Section.Point_Three}
        BannerImage={
          ApiUrl + regmed.data.attributes.Hero_Section.Video.data.attributes.url
        }
        BannerText={regmed.data.attributes.Hero_Section.Video_Heading}
      />

      <Uses data={regmed.data.attributes} />
      <MainValuable
        HeadingBlack={beforeDelimiter1}
        HeadingBlue={afterDelimiter1}
        HeadingPara={regmed.data.attributes.Why_Valuable.Para}
        isButton={false}
        ValueList={regmed.data.attributes.Why_Valuable.Points.map(
          (point, index) => point.List
        )}
      />

      <Power data={regmed.data.attributes} />
      <TranslplantNumbers
        backgroundImage={
          ApiUrl +
          regmed.data.attributes.Translpant_Numbers.image.data.attributes.url
        }
        mobileBackgroundImage={assets.mt2}
        numberone={regmed.data.attributes.Translpant_Numbers.Number_One}
        numbertwo={regmed.data.attributes.Translpant_Numbers.Number_Two}
        numberthree={regmed.data.attributes.Translpant_Numbers.Number_Three}
        textone={regmed.data.attributes.Translpant_Numbers.Text_One}
        texttwo={regmed.data.attributes.Translpant_Numbers.Text_Two}
        textthree={regmed.data.attributes.Translpant_Numbers.Text_Three}
        title={regmed.data.attributes.Translpant_Numbers.Heading}
      />

      <CryoInfinite data={regmed.data.attributes} />
      <MainValuable
        HeadingBlack={beforeDelimiter2}
        HeadingBlue={afterDelimiter2}
        HeadingPara={regmed.data.attributes.Why_CryoInfinite.Para}
        isButton={false}
        ValueList={regmed.data.attributes.Why_CryoInfinite.Points.map(
          (point, index) => point.List
        )}
      />
      <Limitless data={regmed.data.attributes} />
      <MasterStem data={regmed.data.attributes} />

      <ArabiaCell data={regmed.data.attributes} />
    </div>
  );
};

export default RegenerativeMedicine;
