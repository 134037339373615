import React from "react";
import ParentContainer from "../components/ParentContainer";

const TermsCondition = () => {
  return (
    <ParentContainer>
      <div className="flex flex-col gap-3">
        <h1 className="text-5xl font-bold  text-left  max-md:text-2xl max-lg:w-full max-lg:mb-4">
          Welcome to CellSave Arabia FZ-LLC- The Family Stem Cell Bank!{" "}
        </h1>

        <p>
          These terms and conditions outline the rules and regulations for the
          use of CellSave Arabia FZ-LLC’s Website, located at{" "}
          <a href="https://cellsave.com/">https://cellsave.com/</a>.
        </p>

        <p>
          CellSave Arabia FZ-LLC maintains this{" "}
          <a href="https://cellsave.ae/">website</a> (“Site”). These terms and
          conditions outline the general terms (“Terms”) for the use of{" "}
          <a href="https://cellsave.ae/">CellSave Arabia</a> site. By accessing
          this <a href="https://cellsave.ae/">website</a>, You agree to be bound
          by these Terms and further agree that you are responsible for
          compliance with any applicable laws, if you do not agree to take all
          of the terms and conditions stated on this page you are prohibited
          from using or accessing the <a href="https://cellsave.ae/">website</a>
          . Customers using the website who are minors/under the age of 18 shall
          not register as a user of the website and shall not transact on or use
          the website.
        </p>

        <p>
          The following terminology applies to these Terms of Use, Privacy
          Policy and all Agreements: “Client”, “You” and “Your” refers to you,
          the person accessing this website and compliant to the Company’s terms
          and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”,
          refers to our Company. “Party”, “Parties”, or “Us”, refers to both the
          Client and ourselves. Any use of the above terminology or other words
          in the singular, plural, capitalization and/or he/she or they, are
          taken as interchangeable and therefore as referring to same. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services in
          accordance with and subject to, prevailing law of the United Arab
          Emirates, which is our country of domicile. All disputes arising in
          connection therewith shall be heard only by a court of competent
          jurisdiction in U.A.E. We will not trade with or provide any services
          to OFAC (Office of Foreign Assets Control) and sanctioned countries in
          accordance with the law of UAE.
        </p>

        <p>
          <strong>Cookies</strong>
        </p>

        <p>
          We employ the use of cookies. By accessing this{" "}
          <a href="https://cellsave.ae/">website</a>, you agreed to use cookies
          in agreement with the CellSave Arabia FZ-LLC’s Privacy Policy. Most
          interactive websites use cookies to let us retrieve the user’s details
          for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>

        <p>
          <strong>License</strong>
        </p>

        <p>
          Unless otherwise stated, CellSave Arabia FZ-LLC and/or its licensors
          own the intellectual property rights for all material on CellSave
          Arabia FZ-LLC- The Family Stem Cell Bank. All intellectual property
          rights are reserved. You may access this from the{" "}
          <a href="https://cellsave.ae/">CellSave Arabia</a> website for your
          own personal use subjected to restrictions set in these terms and
          conditions. You must not:
        </p>

        <ul class="wp-block-list">
          <li>
            Republish material from{" "}
            <a href="https://cellsave.ae/">CellSave Arabia</a> website
          </li>

          <li>
            Sell, rent or sub-license material from{" "}
            <a href="https://cellsave.ae/">CellSave Arabia</a> website
          </li>

          <li>
            Reproduce, duplicate or copy material from{" "}
            <a href="https://cellsave.ae/">CellSave Arabia</a> website
          </li>

          <li>
            Redistribute content from{" "}
            <a href="https://cellsave.ae/">CellSave Arabia</a> website
          </li>
        </ul>

        <p>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          CellSave Arabia does not filter, edit, publish or review Comments
          prior to their presence on the website. Comments do not reflect the
          views and opinions of CellSave Arabia, its agents and/or affiliates.
          Comments reflect the views and opinions of the person who post their
          views and opinions. To the extent permitted by applicable laws,
          CellSave Arabia shall not be liable for the Comments or for any
          liability, damages or expenses caused and/or suffered as a result of
          any use of and/or posting of and/or appearance of the Comments on this
          website. CellSave Arabia reserves the right to monitor all Comments
          and to remove any Comments which can be considered inappropriate,
          offensive or causes breach of these Terms and Conditions. You warrant
          and represent that:
        </p>

        <ul class="wp-block-list">
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>

          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>

          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>

          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>

        <p>
          You hereby grant CellSave Arabia FZ-LLC a non-exclusive license to
          use, reproduce, edit and authorize others to use, reproduce and edit
          any of your Comments in any and all forms, formats or media.
        </p>

        <p>
          <strong>Hyperlinking to our Content</strong>
        </p>

        <p>
          The following organizations may link to our Website without prior
          written approval:
        </p>

        <ul class="wp-block-list">
          <li>Government agencies;</li>

          <li>Search engines;</li>

          <li>News organizations;</li>

          <li>
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses;
            and
          </li>

          <li>
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site.
          </li>
        </ul>

        <p>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
        </p>

        <p>
          We may consider and approve other link requests from the following
          types of organizations:
        </p>

        <ul class="wp-block-list">
          <li>commonly-known consumer and/or business information sources;</li>

          <li>dot.com community sites;</li>

          <li>associations or other groups representing charities;</li>

          <li>online directory distributors;</li>

          <li>internet portals;</li>

          <li>accounting, law and consulting firms; and</li>

          <li>educational institutions and trade associations.</li>
        </ul>

        <p>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of CellSave Arabia FZ-LLC; and
          (d) the link is in the context of general resource information.
        </p>

        <p>
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
        </p>

        <p>
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to CellSave Arabia FZ-LLC. Please include your name,
          your organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Approved organizations may hyperlink to our Website as follows:
        </p>

        <ul class="wp-block-list">
          <li>By use of our corporate name; or</li>

          <li>By use of the uniform resource locator being linked to; or</li>

          <li>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party’s site.
          </li>
        </ul>

        <p>
          No use of CellSave Arabia FZ-LLC’s logo or other artwork will be
          allowed for linking absent a trademark license agreement.
        </p>

        <p>
          <strong>iFrames</strong>
        </p>

        <p>
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>

        <p>
          <strong>Content Liability</strong>
        </p>

        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <p>
          Visa or MasterCard debit and credit cards in AED &amp; QAR will be
          accepted for payment<br></br>Cardholder must retain a copy of
          transaction records and Merchant policies and rules<br></br>User is
          responsible for maintaining the confidentiality of his account
        </p>

        <p>
          <strong>Your Privacy</strong>
        </p>

        <p>Please read Privacy Policy</p>

        <p>
          <strong>Reservation of Rights</strong>
        </p>

        <p>
          We reserve the right to request that you remove all links or any
          particular link to our <a href="https://www.cellsave.ae/">website</a>.
          You approve to immediately remove all links to our Website upon
          request. We also reserve the right to amend these terms and conditions
          and it’s linking policy at any time. By continuously linking to our{" "}
          <a href="https://www.cellsave.ae/">website</a>, you agree to be bound
          to and follow these linking terms and conditions.
        </p>

        <p>
          <strong>Removal of links from our website</strong>
        </p>

        <p>
          If you find any link on our{" "}
          <a href="https://www.cellsave.ae/">website</a> that is offensive for
          any reason, you are free to contact and inform us any moment. We will
          consider requests to remove links but we are not obligated to or so or
          to respond to you directly.
        </p>

        <p>
          We do not ensure that the information on this{" "}
          <a href="https://www.cellsave.ae/">website</a> is correct, we do not
          warrant its completeness or accuracy; nor do we promise to ensure that
          the website remains available or that the material on the website is
          kept up to date.
        </p>

        <p>
          <strong>Disclaimer</strong>
        </p>

        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>

        <ul class="wp-block-list">
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>

          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>

          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>

          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>

        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>

        <p>
          <strong>Amendment</strong>
        </p>

        <p>
          CellSave Arabia FZ-LLC may change its Terms of Use and Privacy Policy
          at any time by publishing the new version on the website.
        </p>

        <p>
          <strong>Our Details</strong>
        </p>

        <p>
          All comments, queries and requests relating to our use of your
          information are welcomed and should be addressed to CellSave Arabia
          FZ-LLC.
        </p>

        <p>
          For questions or concerns relating to our terms and conditions, we can
          be contacted by email at infoarabia@cellsave.com or at:
        </p>

        <p>
          CellSave Arabia,<br></br>Dubai Healthcare City,<br></br>Al Razi
          Medical Complex 64,<br></br>Dubai, United Arab Emirates.
        </p>
      </div>
    </ParentContainer>
  );
};

export default TermsCondition;
