import React from "react";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";

const Preservation = () => {
  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-primary to-custom-secondary flex justify-center flex-col  items-center gap-4 text-white"
      }
    >
      <h3 className="text-3xl text-center font-bold max-md:text-2xl">
        Newborn stem cell preservation
      </h3>
      <p className="w-3/5 text-center font-semibold max-md:w-full max-md:text-sm">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime repellat
        rem explicabo eligendi sunt necessitatibus excepturi debitis cupiditate
        facere ipsum. Necessitatibus quis magni quam tempora. Deserunt
        accusantium dolorum dolor laborum.
      </p>
      <Link to="/checkout" className="flex w-full gap-5 justify-center ">
        <div className=" border-white border text-white flex rounded-3xl py-1 px-4 font-semibold">
          <p className="text-base self-center">Order Now</p>
        </div>
        <svg
          width="39"
          height="38"
          viewBox="0 0 39 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="19.5"
            cy="19"
            r="19"
            transform="rotate(-90 19.5 19)"
            fill="white"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            fill="url(#paint0_linear_590_3775)"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            stroke="url(#paint0_linear_590_3775)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6816 18.8779L22.9741 18.8779"
            stroke="url(#paint0_linear_590_3775)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_590_3775"
              x1="-3.10345"
              y1="4.22222"
              x2="45.1213"
              y2="7.62688"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8884D5" />
              <stop offset="1" stop-color="#CF0072" />
            </linearGradient>
          </defs>
        </svg>
      </Link>
    </ParentContainer>
  );
};

export default Preservation;
