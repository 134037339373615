import React from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { ApiUrl } from "../../components/ApiUrl";

const HeroSection = ({ data }) => {
  const bulletIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" stroke="#8884D5" stroke-width="4" />
    </svg>
  );

  const [beforeDelimiter, afterDelimiter] = data.Heading.split("||");

  return (
    <ParentContainer containerStyle={"flex flex-col gap-2"}>
      <h1 className="text-4xl font-bold max-md:text-2xl">
        {beforeDelimiter}{" "}
        <span className=" text-custom-primary">{afterDelimiter}</span>
      </h1>
      <p className="text-base max-md:text-sm">{data.Paragraph}</p>
      <div className="pt-8 flex flex-wrap items-center">
        <div className="w-1/2  max-md:w-full max-md:pb-4">
          <img
            src={ApiUrl + data.Image.data.attributes.url}
            alt=""
            className="w-10/12  max-md:w-full"
          />
        </div>
        <div className="w-1/2 flex flex-wrap gap-5  max-md:w-full">
          <div>
            <div className="flex gap-2 items-center">
              <div id="svg">{bulletIcon}</div>
              <p className="text-base font-bold">{data.Point_One}</p>
            </div>
            <p className="text-base ">{data.Point_One_Text}</p>
          </div>
          <div>
            <div className="flex gap-2 items-center">
              <div id="svg">{bulletIcon}</div>
              <p className="text-base font-bold">{data.Point_Two}</p>
            </div>
            <p className="text-base ">{data.Point_Two_Text}</p>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default HeroSection;
