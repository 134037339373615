import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import Button from "../../components/Button";
import { Waypoint } from "react-waypoint";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const MedTabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
    } else setActiveTab(tab);
  };

  const main = useRef();
  const parentRef = useRef();

  const observerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= 0.1) {
          observerRef.current = true;
          observer.disconnect();
          initializeScrollTrigger();
        }
      },
      {
        threshold: [0.1],
      }
    );

    observer.observe(main.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const initializeScrollTrigger = () => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 2,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              //   markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  };
  return (
    <div ref={main} className="overflow-hidden">
      <ParentContainer
        containerStyle={"flex flex-col bg-center bg-no-repeat bg-cover"}
        containerCSS={{ backgroundImage: `url(${ApiUrl+ data.Translpant_Meds_Tab_Bg.data.attributes.url})` }}
      >
        <div className="flex  gap-5 justify-start pb-5" id="medTabs">
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!items-center  !justify-end !px-0 !w-auto box"}
            white={true}
          />
        </div>
        <h3 className=" text-4xl   font-bold   pb-5 text-white capitalize box max-md:text-2xl">
          {data.Translpant_Meds_Heading}
        </h3>
        <p className="text-left text-lg text-white box max-md:text-sm">
          {data.Translpant_Meds_Para}
        </p>
        <div className="circle">
          <div className="container mx-auto p-4 mt-5">
            <div className="   grid grid-cols-4 max-md:grid-cols-1 gap-4">
              <button
                className={`max-md:order-1 py-3 px-5 rounded-lg flex  justify-between items-center hover:bg-gradient-to-r  hover:from-custom-primary hover:to-custom-secondary hover:text-white
                ${
                  activeTab === "tab1"
                    ? "bg-gradient-to-r  from-custom-primary to-custom-secondary text-white"
                    : "bg-white "
                }`}
                onClick={() => handleTabClick("tab1")}
              >
                {data.Translpant_Meds_Tab_Heading_One}
                {activeTab === "tab1" ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 3.33317H24.9997C33.333 3.33317 36.6663 6.6665 36.6663 14.9998V24.9998C36.6663 33.3332 33.333 36.6665 24.9997 36.6665H14.9997C6.66634 36.6665 3.33301 33.3332 3.33301 24.9998V14.9998C3.33301 6.6665 6.66634 3.33317 14.9997 3.33317Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 22.2666L19.9995 16.3999L25.8829 22.2666"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 36.6668H24.9997C33.333 36.6668 36.6663 33.3335 36.6663 25.0002V15.0002C36.6663 6.66683 33.333 3.3335 24.9997 3.3335H14.9997C6.66634 3.3335 3.33301 6.66683 3.33301 15.0002V25.0002C3.33301 33.3335 6.66634 36.6668 14.9997 36.6668Z"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 17.7334L19.9995 23.6001L25.8829 17.7334"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
              <button
                className={`max-md:order-3 py-3 px-5 rounded-lg flex  justify-between items-center hover:bg-gradient-to-r  hover:from-custom-primary hover:to-custom-secondary hover:text-white
                ${
                  activeTab === "tab2"
                    ? "bg-gradient-to-r  from-custom-primary to-custom-secondary text-white"
                    : "bg-white "
                }`}
                onClick={() => handleTabClick("tab2")}
              >
                {data.Translpant_Meds_Tab_Heading_Two}{" "}
                {activeTab === "tab2" ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 3.33317H24.9997C33.333 3.33317 36.6663 6.6665 36.6663 14.9998V24.9998C36.6663 33.3332 33.333 36.6665 24.9997 36.6665H14.9997C6.66634 36.6665 3.33301 33.3332 3.33301 24.9998V14.9998C3.33301 6.6665 6.66634 3.33317 14.9997 3.33317Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 22.2666L19.9995 16.3999L25.8829 22.2666"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 36.6668H24.9997C33.333 36.6668 36.6663 33.3335 36.6663 25.0002V15.0002C36.6663 6.66683 33.333 3.3335 24.9997 3.3335H14.9997C6.66634 3.3335 3.33301 6.66683 3.33301 15.0002V25.0002C3.33301 33.3335 6.66634 36.6668 14.9997 36.6668Z"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 17.7334L19.9995 23.6001L25.8829 17.7334"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
              <button
                className={` max-md:order-5 py-3 px-5 rounded-lg flex  justify-between items-center hover:bg-gradient-to-r  hover:from-custom-primary hover:to-custom-secondary hover:text-white
                ${
                  activeTab === "tab3"
                    ? "bg-gradient-to-r  from-custom-primary to-custom-secondary text-white hover:bg-gradient-to-r  hover:from-custom-primary hover:to-custom-secondary hover:text-white"
                    : "bg-white "
                }`}
                onClick={() => handleTabClick("tab3")}
              >
                {data.Translpant_Meds_Tab_Heading_Three}{" "}
                {activeTab === "tab3" ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 3.33317H24.9997C33.333 3.33317 36.6663 6.6665 36.6663 14.9998V24.9998C36.6663 33.3332 33.333 36.6665 24.9997 36.6665H14.9997C6.66634 36.6665 3.33301 33.3332 3.33301 24.9998V14.9998C3.33301 6.6665 6.66634 3.33317 14.9997 3.33317Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 22.2666L19.9995 16.3999L25.8829 22.2666"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 36.6668H24.9997C33.333 36.6668 36.6663 33.3335 36.6663 25.0002V15.0002C36.6663 6.66683 33.333 3.3335 24.9997 3.3335H14.9997C6.66634 3.3335 3.33301 6.66683 3.33301 15.0002V25.0002C3.33301 33.3335 6.66634 36.6668 14.9997 36.6668Z"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 17.7334L19.9995 23.6001L25.8829 17.7334"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
              <button
                className={` max-md:order-7 py-3 px-5 rounded-lg flex  justify-between items-center hover:bg-gradient-to-r  hover:from-custom-primary hover:to-custom-secondary hover:text-white
                ${
                  activeTab === "tab4"
                    ? "bg-gradient-to-r  from-custom-primary to-custom-secondary text-white "
                    : "bg-white "
                }`}
                onClick={() => handleTabClick("tab4")}
              >
                {data.Translpant_Meds_Tab_Heading_Four}{" "}
                {activeTab === "tab4" ? (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 3.33317H24.9997C33.333 3.33317 36.6663 6.6665 36.6663 14.9998V24.9998C36.6663 33.3332 33.333 36.6665 24.9997 36.6665H14.9997C6.66634 36.6665 3.33301 33.3332 3.33301 24.9998V14.9998C3.33301 6.6665 6.66634 3.33317 14.9997 3.33317Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 22.2666L19.9995 16.3999L25.8829 22.2666"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9997 36.6668H24.9997C33.333 36.6668 36.6663 33.3335 36.6663 25.0002V15.0002C36.6663 6.66683 33.333 3.3335 24.9997 3.3335H14.9997C6.66634 3.3335 3.33301 6.66683 3.33301 15.0002V25.0002C3.33301 33.3335 6.66634 36.6668 14.9997 36.6668Z"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1162 17.7334L19.9995 23.6001L25.8829 17.7334"
                      stroke="#37424A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
              {/* </div> */}
              {/* <div className="mt-5"> */}
              {activeTab === "tab1" && (
                <div className=" bg-white p-8 max-md:p-4 rounded-lg max-md:order-2 col-span-4 max-md:col-span-1">
                  {/* <h4 className=" text-xl  font-semibold ">Cancers</h4> */}
                  <div className="grid grid-cols-3 pt-5 max-md:pt-0 max-md:!grid-cols-1 max-lg:grid-cols-2">
                    {data.Translpant_Meds_Tab_Content_One.map((tab, index) => (
                      <div className="flex items-start gap-2 " key={index}>
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-2/12"
                        >
                          <rect
                            x="0.896484"
                            width="20"
                            height="20"
                            rx="10"
                            fill="url(#paint0_linear_590_5594)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_590_5594"
                              x1="-1.00007"
                              y1="2.22222"
                              x2="24.3814"
                              y2="4.01415"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#8884D5" />
                              <stop offset="1" stop-color="#CF0072" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <p className="w-10/12">{tab.List}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === "tab2" && (
                <div className=" bg-white p-8 max-md:p-4 rounded-lg max-md:order-4 col-span-4 max-md:col-span-1">
                  {/* <h4 className=" text-xl  font-semibold ">Cancers</h4> */}
                  <div className="grid grid-cols-3 pt-5 max-md:pt-0 max-md:!grid-cols-1 max-lg:grid-cols-2">
                    {data.Translpant_Meds_Tab_Content_Two.map((tab, index) => (
                      <div className="flex items-center gap-2" key={index}>
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-2/12"
                        >
                          <rect
                            x="0.896484"
                            width="20"
                            height="20"
                            rx="10"
                            fill="url(#paint0_linear_590_5594)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_590_5594"
                              x1="-1.00007"
                              y1="2.22222"
                              x2="24.3814"
                              y2="4.01415"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#8884D5" />
                              <stop offset="1" stop-color="#CF0072" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <p className="w-10/12">{tab.List}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === "tab3" && (
                <div className=" bg-white p-8 max-md:p-4 rounded-lg max-md:order-6 col-span-4 max-md:col-span-1">
                  {/* <h4 className=" text-xl  font-semibold ">Cancers</h4> */}
                  <div className="grid grid-cols-3 pt-5 max-md:pt-0 max-md:!grid-cols-1 max-lg:grid-cols-2">
                    {data.Translpant_Meds_Tab_Content_Three.map(
                      (tab, index) => (
                        <div className="flex items-center gap-2" key={index}>
                          <svg
                            width="21"
                            height="20"
                            viewBox="0 0 21 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-2/12"
                          >
                            <rect
                              x="0.896484"
                              width="20"
                              height="20"
                              rx="10"
                              fill="url(#paint0_linear_590_5594)"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_590_5594"
                                x1="-1.00007"
                                y1="2.22222"
                                x2="24.3814"
                                y2="4.01415"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#8884D5" />
                                <stop offset="1" stop-color="#CF0072" />
                              </linearGradient>
                            </defs>
                          </svg>
                          <p className="w-10/12">{tab.List}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {activeTab === "tab4" && (
                <div className=" bg-white p-8 max-md:p-4 rounded-lg max-md:order-8 col-span-4 max-md:col-span-1">
                  {/* <h4 className=" text-xl  font-semibold ">Cancers</h4> */}
                  <div className="grid grid-cols-3 pt-5 max-md:pt-0 max-md:!grid-cols-1 max-lg:grid-cols-2">
                    {data.Translpant_Meds_Tab_Content_Four.map((tab, index) => (
                      <div className="flex items-center gap-2" key={index}>
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-2/12"
                        >
                          <rect
                            x="0.896484"
                            width="20"
                            height="20"
                            rx="10"
                            fill="url(#paint0_linear_590_5594)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_590_5594"
                              x1="-1.00007"
                              y1="2.22222"
                              x2="24.3814"
                              y2="4.01415"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#8884D5" />
                              <stop offset="1" stop-color="#CF0072" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <p className="w-10/12">{tab.List}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default MedTabs;
