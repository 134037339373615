import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Advantage = ({ data }) => {
  const [beforeDelimiter, afterDelimiter] = data.Advantage_Heading.split("||");

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const observerRef = useRef();

  const main = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= 0.1) {
          observerRef.current = true;
          observer.disconnect();
          initializeScrollTrigger();
        }
      },
      {
        threshold: [0.1],
      }
    );

    observer.observe(main.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const initializeScrollTrigger = () => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, opacity: 0 },
          {
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: box,
              start: "bottom 70%",
              end: "top 75%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  };

  return (
    <ParentContainer
      containerStyle={
        "flex items-start max-md:flex-wrap max-md:gap-5 max-md:py-20"
      }
      containerHeight={"py-60"}
    >
      <div
        id="sticky-heading"
        className="sticky top-2/4 max-md:w-full max-md:relative max-md:mb-5 w-1/2 "
      >
        <h2 className="text-5xl font-bold text-left max-md:text-2xl pb-2">
          {beforeDelimiter}
          <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text">
            {afterDelimiter}
          </span>
        </h2>
        <p className="text-left text-xl opacity-80 w-4/5 max-md:text-base max-md:w-full">
          {data.Advantage_Para}
        </p>
      </div>

      <div
        ref={main}
        className={` w-1/2  text-xl max-md:text-base  !last:mb-0 max-md:w-full overflow-hidden ${
          isVisible ? "animate__animated animate__fadeInRight" : ""
        }`}
      >
        <div className="p-5 pb-6 flex justify-between items-center border-b box border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            <svg
              width="100%"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_590_6015)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_6015"
                  x1="-2.84483"
                  y1="3.33333"
                  x2="35.2274"
                  y2="6.02122"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <p className="text-lg font-semibold w-10/12 max-md:text-sm ">
            {data.Advantage_Point_One}
          </p>
        </div>
        <div className="p-5 pb-6 flex justify-between items-center border-b box border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            <svg
              width="100%"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_590_6015)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_6015"
                  x1="-2.84483"
                  y1="3.33333"
                  x2="35.2274"
                  y2="6.02122"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <p className="text-lg font-semibold w-10/12 max-md:text-sm ">
            {data.Advantage_Point_Two}
          </p>
        </div>
        <div className="p-5 pb-6 flex justify-between items-center border-b box border-[#EAECF0]">
          <div id="icon" className="w-1/12">
            <svg
              width="100%"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="30"
                height="30"
                rx="15"
                fill="url(#paint0_linear_590_6015)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_6015"
                  x1="-2.84483"
                  y1="3.33333"
                  x2="35.2274"
                  y2="6.02122"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <p className="text-lg font-semibold w-10/12 max-md:text-sm ">
            {data.Advantage_Point_Three}
          </p>
        </div>
      </div>
    </ParentContainer>
  );
};

export default Advantage;
