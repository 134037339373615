// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem("jwt"); // Check for JWT token in local storage

  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
