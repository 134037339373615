import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../assets/assets";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import Button from "../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const MainAdvantagesSection = ({
  HeadingBlack,
  HeadingBlue,
  Linkto,
  TwoImages,
  Image,
  isPara,
  Para,
  Advantages,
}) => {
  const bulletIcon = (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10.5781" r="8" stroke="#8884D5" stroke-width="4" />
    </svg>
  );
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };
  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <div ref={main} className="overflow-hidden">
      <ParentContainer
        containerStyle={"bg-gradient-to-r   flex flex-wrap items-center "}
        className=" "
      >
        <h2 className="text-5xl font-bold text-left pb-12 w-2/3 box max-md:w-full max-md:text-2xl max-md:pb-4">
          {HeadingBlack}{" "}
          <span className="text-custom-primary">{HeadingBlue}</span>
        </h2>
        <Button
          Linkto={"/checkout"}
          titleof={"Order Now"}
          CustomStyle={
            "!items-center !w-1/3 !justify-end !px-0 circle max-md:!w-full max-md:!justify-start"
          }
        />
        <div className="flex flex-wrap  justify-between items-center pt-10 w-full max-lg:gap-8">
          <Waypoint onEnter={handleEnter}>
            <div
              className={`max-md:w-full max-md:pb-4 ${
                TwoImages ? "w-1/2 max-lg:w-full" : "w-1/3"
              } ${isVisible ? "animate__animated animate__fadeInLeft" : ""}`}
            >
              <img src={Image} alt="" className="w-11/12 box" />
            </div>
          </Waypoint>
          <div
            className={`max-md:w-full ${
              TwoImages ? "w-1/2 max-lg:w-full" : "w-2/3"
            } ${isVisible ? "animate__animated animate__fadeInRight" : ""}`}
          >
            {isPara ? (
              <p className="text-xl pb-8 circle max-md:text-sm">{Para}</p>
            ) : (
              ""
            )}
            <div className="grid grid-cols-2 font-semibold gap-2 max-md:grid-cols-1">
              {Advantages.map((advantage, index) => (
                <div className="flex gap-3 text-lg items-center circle max-md:text-sm">
                  <div id="icon">{bulletIcon}</div>
                  <p>{advantage}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

MainAdvantagesSection.defaultProps = {
  Linkto: "/checkout",
};

export default MainAdvantagesSection;
