import React from "react";

const AccordionItem = ({ title, children, isOpen, onClick, customStyle }) => {
  return (
    <div className={`bg-[#F7F6FF] rounded-lg ${customStyle}`}>
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-4 text-left"
      >
        <span className="text-lg font-medium max-md:text-base">{title}</span>
        {isOpen ? (
          <svg
            width="22"
            height="4"
            viewBox="0 0 22 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 2.11523L20 2.11524"
              stroke="url(#paint0_diamond_590_4591)"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <radialGradient
                id="paint0_diamond_590_4591"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(9.99999 2.11523) rotate(4.96974) scale(23.0868 25.9179)"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </radialGradient>
            </defs>
          </svg>
        ) : (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 11.1152L20 11.1152"
              stroke="url(#paint0_linear_590_4600)"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 20.1152L11 2.11523"
              stroke="url(#paint1_linear_590_4600)"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_4600"
                x1="2"
                y1="10.6152"
                x2="20"
                y2="10.6152"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_590_4600"
                x1="10.5"
                y1="20.1152"
                x2="10.5"
                y2="2.11523"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        )}
      </button>
      {isOpen && <div className="p-4 ">{children}</div>}
    </div>
  );
};

export default AccordionItem;
