import React, { useState } from "react";
import ParentContainer from "../components/ParentContainer";
import ProfileSideBar from "../components/ProfileSideBar";
import HeroSection from "./ProfileHealthHistory/HeroSection";
import Attention from "./ProfileHealthHistory/Attention";
import Form from "./ProfileHealthHistory/Form";
import axios from "axios";

const ChangePass = () => {
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { oldPassword, newPassword, confirmPassword } = formData;

    if (newPassword !== confirmPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    axios
      .post("/api/change-password", { oldPassword, newPassword })
      .then((response) => {
        console.log("Password changed successfully:", response);
        // Handle successful password change
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        // Handle error
      });
  };
  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] max-lg:bg-white flex justify-stretch "
    >
      <div
        onClick={() => setIsActive(true)}
        className="fixed z-20 top-1/2 left-0 lg:hidden cursor-pointer bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-2 py-2 rounded-r-xl font-semibold"
      >
        <svg
          class="feather feather-menu"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="3" x2="21" y1="12" y2="12" />
          <line x1="3" x2="21" y1="6" y2="6" />
          <line x1="3" x2="21" y1="18" y2="18" />
        </svg>
      </div>
      <div className="w-1/5 bg-white border-r max-lg:hidden">
        <ProfileSideBar />
      </div>
      {isActive && (
        <div className=" bg-white border-r fixed left-0 h-screen z-30 max-md:animate__animated max-md:animate__fadeInUp">
          <ProfileSideBar mobileState={true} isActive={setIsActive} />
        </div>
      )}
      <div className="w-4/5 max-lg:w-full">
        <HeroSection />
        <div className="flex flex-wrap p-8 bg-white max-lg:px-0">
          <div className=" border rounded-lg p-8 pt-2 w-full max-md:px-4">
            <form onSubmit={handleSubmit}>
              <div className="pt-4 pb-4 grid grid-cols-4 gap-4">
                <h4 className="text-lg font-semibold mb-2 col-span-4">
                  Change Your Password
                </h4>
                <div className="col-span-4 grid grid-cols-3 gap-4">
                  <div className="max-md:col-span-3">
                    <label htmlFor="oldPassword" className="block mb-1">
                      Old Password
                    </label>
                    <input
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                      value={formData.oldPassword}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="max-md:col-span-3">
                    <label htmlFor="newPassword" className="block mb-1">
                      New Password
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                      value={formData.newPassword}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="max-md:col-span-3">
                    <label htmlFor="confirmPassword" className="block mb-1">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="border border-gray-300 rounded-md px-3 py-2 w-full"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <button
                type="submit"
                className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default ChangePass;
