import React, { useEffect, useState } from "react";
import HeroSection from "./Contact/HeroSection";
import Support from "./Contact/Support";
import Form from "./Contact/Form";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";

const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get(ApiUrl + "/api/contact");
        setContactData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (!contactData) {
    return <div>Error loading data.</div>;
  }

  return (
    <div>
      <Support data={contactData.data.attributes} />
      <Form data={contactData.data.attributes} />
      <HeroSection data={contactData.data.attributes} />
    </div>
  );
};

export default Contact;
