import React, { useEffect, useState } from "react";
import BlogListing from "../components/BlogListing";
import assets from "../assets/assets";
import ParentContainer from "../components/ParentContainer";
import BlogIndex from "./Blog/BlogIndex";
import ResourcesMiniNav from "../components/ResourcesMiniNav";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import Button from "../components/Button";

const BlogPage = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blogImage, setBlogImage] = useState("");

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/blogs?populate=*&pagination[pageSize]=99"
        );

        setSource(response.data);
        console.log("all blogs", response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();

    const fetchblogimages = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/blog-banner?populate=*"
        );

        setBlogImage(response.data);
        console.log("blog images", response.data);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    };

    fetchblogimages();
  }, []);

  if (loading) {
    return <div></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  const blogData = source.data.filter(
    (blog) =>
      blog.attributes.blog_category?.data?.attributes?.Category ===
        "Pregnancy" ||
      blog.attributes.blog_category?.data?.attributes?.Category === "Stem Cells"
  );

  console.log("blog", blogData);

  return (
    <div>
      <ResourcesMiniNav />
      <BlogListing data={blogData} />
      <ParentContainer containerStyle={"!py-0"}>
        <div className="w-full flex justify-center relative">
          {blogImage?.data?.attributes?.image && (
            <>
              <img
                src={
                  ApiUrl + blogImage.data.attributes.image.data.attributes.url
                }
                alt=""
                className="max-md:hidden rounded-lg"
              />
              <img
                src={
                  ApiUrl +
                  blogImage.data.attributes.mobile_image.data.attributes.url
                }
                alt=""
                className="md:hidden rounded-lg"
              />
              <div className="absolute bottom-[40px] left-[42%]  flex items-center justify-center max-md:left-[12%]">
                <Button
                  Linkto={"/checkout"}
                  titleof={"Order Now"}
                  CustomStyle={"box"}
                  white={true}
                  CustomStyle={"w-full !px-0"}
                />
              </div>
            </>
          )}
        </div>
      </ParentContainer>
      <BlogIndex data={blogData} />
    </div>
  );
};

export default BlogPage;
