import React, { useRef, useState } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ApiUrl } from "../../../components/ApiUrl";
import assets from "../../../assets/assets";

const AdsccMHHP = () => {
  const printRef = useRef();
  const [files, setFiles] = useState([]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");
  const [buttonText, setButtonText] = useState("Send Contract");

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };

  const handleGeneratePdf = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setButtonText("Generating PDF..."); // Update button text

    const element = printRef.current;

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // Top, left, bottom, right margins in inches
      filename: "contract.pdf",
      html2canvas: { scale: 2 }, // Increase scale for better quality
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("files", pdfBlob, "adscc-mhhp.pdf");

        files.forEach((file) => {
          formData.append("files", file);
        });

        const recipientEmails = to
          ? `${to}, it@cellsave.com, maqcadet3@gmail.com`
          : "maqcadet3@gmail.com";
        formData.append("to", recipientEmails);
        formData.append("subject", subject || "Adscc MHHP");
        formData.append("text", text || "Your Copy of the Adscc MHHP");
        formData.append("html", html || "Your Copy of the Adscc MHHP");

        handleSubmit(formData);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setButtonText("Error! Try Again"); // Update button text in case of error
      });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (formData) => {
    setButtonText("Sending..."); // Update button text

    try {
      const response = await axios.post(
        ApiUrl + "/api/pdfmailer/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
      setButtonText("Sent Successfully"); // Update button text on success
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Error! Try Again"); // Update button text in case of error
    } finally {
      setTimeout(() => {
        setButtonText("Send Contract"); // Reset button text after a delay
      }, 3000);
    }
  };
  return (
    <div className="flex w-full bg-gray-600 justify-center items-center pt-10 flex-col">
      <form onSubmit={handleGeneratePdf}>
        <div
          ref={printRef}
          style={{
            background: "#fff",
            width: "100%", // Use a responsive width
            maxWidth: "210mm", // Ensure it does not exceed A4 width
            minHeight: "297mm",
            padding: "20px", // Optional: Add padding
            boxSizing: "border-box", // Include padding in width calculations
            pageBreakInside: "avoid", // Prevent breaking inside this element
            overflowWrap: "break-word", // Ensure words break correctly
            wordWrap: "break-word", // Compatibility for word wrapping
          }}
          className="contract "
        >
          <div>
            <p className="flex items-center w-full gap-5">
              <span
                style={{ color: "#cf0072", fontSize: "30px" }}
                className="text-left flex-1 leading-10 text-xl md:text-3xl lg:text-4xl text-gray-800 "
              >
                <strong>Medical Health History Profile</strong>
              </span>{" "}
              <img className="w-1/5" src={assets.cricleLogo} alt="" />
            </p>

            <div className="text-left text-gray-700 mb-8">
              <h2 className="text-sm md:text-md lg:text-xl font-semibold text-purple-700">
                MEDICAL & HEALTH HISTORY PROFILE
              </h2>
              <ul className="list-disc pl-5">
                <li>
                  As per AABB and International Standards, this form should be
                  signed ‘not more than 7 days before the birth of your baby.
                </li>
                <li>
                  This form provides relevant information such as Educational
                  Materials and Medication List to guide the Client in answering
                  the questionnaire.
                </li>
                <li>
                  The Mother and the Father should answer all questions on this
                  form.
                </li>
                <li>
                  It is important that the Mother signs this form with her full
                  name.
                </li>
              </ul>
            </div>
            <form className="space-y-6">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-left text-gray-700 font-bold">
                    Mother’s Last Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-left text-gray-700 font-bold">
                    Mother’s First Name:
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label className="block text-left text-gray-700 font-bold">
                    Mother’s Mobile Number:
                  </label>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="text-left text-gray-700 mt-8">
                <h2 className="text-sm md:text-md lg:text-xl font-semibold text-purple-700">
                  DONOR EDUCATIONAL MATERIALS / MEDICATION LIST
                </h2>
                <p>Have you ever taken any of these medications?</p>
                <h3 className="text-sm md:text-md lg:text-xl font-bold text-gray-900 mt-4">
                  HEPATITIS B IMMUNE GLOBULIN
                </h3>
                <p>Given the following is exposure to Hepatitis B.</p>
                <p className="mt-2">
                  *NOTE: This is different from the Hepatitis B Vaccine, which
                  is a series of 3 injections given over a 6 month period to
                  prevent future infection from exposure to Hepatitis B.
                </p>
                <p className="mt-2">
                  Hepatitis B Immune Globulin (HBIG) is an injected material
                  used to prevent Infection following exposure to Hepatitis B.
                  HBIG does not prevent Hepatitis B infection in every case,
                  therefore potential donors who have taken Hepatitis B Immune
                  Globulin should be evaluated by the Medical Director to be
                  sure they were not infected. Hepatitis B can be transmitted
                  through transfusion and transplants, to be a patient.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  UNLICENSED VACCINE
                </h3>
                <p>
                  Usually associated with a research protocol, Unlicensed
                  Vaccine is usually associated with a research protocol and the
                  effect with regard to stem cell recipients is unknown.
                  Potential donors who have taken unlicensed vaccines should be
                  evaluated by the Medical Director.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  INSULIN FROM COWS (Bovine, or Beef, Insulin)
                </h3>
                <p>
                  Used to treat diabetes, Insulin from Cows (Bovine, or Beef,
                  Insulin) is an injected material used to treat diabetes. If
                  this insulin was imported from countries in which “Mad Cow
                  Disease” has been found, it could contain material from
                  infected cattle. There is concern that “Mad Cow Disease” is
                  transmitted by transfusions and transplants. Potential donors
                  who have taken Insulin from cows should be evaluated by the
                  Medical Director.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  GROWTH HORMONES FROM PITUITARY GLANDS
                </h3>
                <p>
                  Used usually for children with delayed or impaired growth.
                  Growth Hormone from Pituitary Gland was prescribed for
                  children with delayed or impaired growth. The hormone was
                  obtained from pituitary glands, which are found in the brain.
                  Some people who took this hormone developed a rare nervous
                  system condition called Creutzfeldt - Jakob disease (CJD).
                  Potential donors who have taken growth hormone from human
                  pituitary glands should be evaluated by the Medical Director.
                </p>
                <p className="font-bold mt-8">
                  PLEASE READ THIS INFORMATION BEFORE YOU COMPLETE THE
                  QUESTIONNAIRE!
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  ACCURACY AND HONESTY ARE ESSENTIAL
                </h3>
                <p>
                  Your complete honesty in answering all questions is very
                  important for the safety of patients who receive your stem
                  cells. All information you provide is confidential.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  DONOR ELIGIBILITY – SPECIFIC INFORMATION (why we ask about
                  sexual contact)
                </h3>
                <p>
                  Sexual contact may cause contagious diseases like HIV to get
                  into the bloodstream and be spread through transfusions or
                  transplants to someone else.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  HIV/AIDS Risk Behavior and Symptoms
                </h3>
                <p>
                  AIDS is caused by HIV. HIV is spread mainly through sexual
                  contact with an infected person or by sharing needles or
                  syringes used for injecting drugs.
                </p>
                <h3 className="font-bold text-gray-900 mt-4">
                  Inform the staff if YOU
                </h3>
                <ul className="list-disc pl-5 mt-2">
                  <li>Have AIDS or have ever had a positive HIV test</li>
                  <li>
                    Have used needles to take drugs, steroids, or anything not
                    prescribed by your doctor in the past 5 years
                  </li>
                  <li>
                    Have had sexual contact in the past 12 months with anyone
                    described above
                  </li>
                  <li>Have had syphilis or gonorrhea in the past 12 months</li>
                  <li>
                    In the past 12 months have been in juvenile detention,
                    lockup, jail, or prison for more than 72 hours
                  </li>
                  <li>
                    Have any of the following conditions that can be signs or
                    symptoms of HIV/AIDS:
                  </li>
                </ul>
                <ul className="list-none pl-5 mt-4">
                  <li>*** Unexplained weight loss or night sweats</li>
                  <li>*** Blue or purple spots in your mouth or skin</li>
                  <li>*** Swollen lymph nodes for more than one month</li>
                  <li>*** White spots or unusual sores in your mouth</li>
                  <li>*** Cough that won’t go away or shortness of breath</li>
                  <li>*** Diarrhea that won’t go away</li>
                  <li>
                    *** Fever of more than 38 degrees Celsius for more than 10
                    days
                  </li>
                </ul>
                <p className="mt-4">
                  Remember that you CAN give HIV to someone else even if you
                  feel well and have a negative HIV test. This is because tests
                  cannot detect infections for a period of time after a person
                  is exposed to HIV.
                </p>
              </div>
              <div className="text-left text-gray-700 mt-8">
                <h2 className="text-orange-600 font-bold text-xl mt-8 underline">
                  Please fill out this form within seven (7) days of Delivery
                </h2>
                <h3 className="text-purple-700 font-semibold mt-4">
                  Health of the MOTHER and baby’s BIOLOGICAL FATHER
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Are you in good general health? If NO, please explain
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="generalHealth"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="generalHealth"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Are you suffering from any chronic disease? If YES, please
                    specify
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="chronicDisease"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="chronicDisease"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4 page-break">
                  <label className="block text-gray-700 font-semibold">
                    Are you taking any prescribed medication/s? If YES, please
                    specify name/reason
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="medication"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="medication"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Are you having complications with this pregnancy? If YES,
                    please specify
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="complications"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="complications"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Are you having planned cesarean delivery?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="cesarean"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="cesarean"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Do you currently have an infectious skin disease? N/A for
                    father
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="skinDisease"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="skinDisease"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold">
                    Do you currently have any medical condition that could be
                    affected adversely by the collection procedure? (E.g.:
                    Cancer, Diabetes, Blood Disease, Bleeding Problems, Lung
                    Disease, Heart Disease, Chest Pain, Stroke, Seizure or
                    Multiple Sclerosis)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="medicalCondition"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="medicalCondition"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  Have you taken any of the following Medications/Vaccines
                  within the specified timeframe?
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Accutane (isotretinoin) or Proscar for your skin – acne
                    medication within the last month
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="accutane"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="accutane"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Propecia (finasteride) – hair loss treatment within the last
                    month
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="propecia"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="propecia"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Live Vaccines (Measles, Mumps) within the last month
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="liveVaccines"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="liveVaccines"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Smallpox Vaccine within the past 8 weeks
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="smallpoxVaccine"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="smallpoxVaccine"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Any chemotherapy during pregnancy N/A for father
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="chemotherapy"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="chemotherapy"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Immune Globulin (Not Rh Immune Globulin) within the past 12
                    months
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="immuneGlobulin"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="immuneGlobulin"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Experimental Medications/Vaccines within the past 12 months
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="experimentalMedications"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="experimentalMedications"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Rabies Vaccine – for exposure within the past 12 months
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="rabiesVaccine"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="rabiesVaccine"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Soriatane (acitretin) or Tegison (etretinate) – for
                    psoriasis within the past 3 years
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="soriatane"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="soriatane"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Insulin from a Cow Source Ever
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="insulinCowSource"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="insulinCowSource"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Growth Hormone from human Pituitary Glands (not infertility
                    hormones) Ever
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="growthHormone"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="growthHormone"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Since 1980, have you received a transfusion blood,
                    platelets, plasma, cryoprecipitate or granulocytes in the
                    UK?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="transfusionUK"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="transfusionUK"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Since 1980, have you spent more than a total of 6 months in
                    Europe? (This includes living or travelling)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="europeStay"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="europeStay"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    In the past 6 months, have you been bitten by an animal
                    suspected of having rabies?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="animalBiteRabies"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="animalBiteRabies"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  In the past 12 months, have YOU or the baby’s BIOLOGICAL
                  FATHER:
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Received blood or blood factor products, derivatives or a
                    tissue organ transplant?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="bloodProducts"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="bloodProducts"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Come into contact with someone else’s blood (E.g.,
                    accidental needle stick)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="contactBlood"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="contactBlood"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had a tattoo, any type of piercings (ear or body),
                    acupuncture or had a needle gun used on you? Circle
                    applicable.
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="tattooPiercings"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="tattooPiercings"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Received shots, vaccinations, including Rh Immune Globulin?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="shotsVaccinations"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="shotsVaccinations"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been diagnosed with West Nile Virus?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="westNileVirus"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="westNileVirus"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been in jail or prison for more than 72 hours?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="jailPrison"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="jailPrison"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had sexual contact with: Someone with Hepatitis, Jaundice or
                    HIV?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="sexualContactHepatitis"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="sexualContactHepatitis"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had sexual contact with: Anyone who has Hemophilia or has
                    used clotting factor concentrates?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="sexualContactHemophilia"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="sexualContactHemophilia"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had an accidental needle-stick?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="accidentalNeedleStick"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="accidentalNeedleStick"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  Have YOU or the baby’s BIOLOGICAL FATHER ever:
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been diagnosed with Sepsis? Or Bacteremia?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="sepsisBacteremia"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="sepsisBacteremia"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="sepsisBacteremia"
                        value="None"
                        className="mr-2"
                      />{" "}
                      None of the Above
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been diagnosed with, or tested positive for HIV HLTV,
                    Syphilis, Hepatitis B or C?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="HIV"
                        className="mr-2"
                      />{" "}
                      HIV
                    </label>
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="HLTV"
                        className="mr-2"
                      />{" "}
                      HLTV
                    </label>
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="Syphilis"
                        className="mr-2"
                      />{" "}
                      Syphilis
                    </label>
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="HepatitisB"
                        className="mr-2"
                      />{" "}
                      Hepatitis B
                    </label>
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="HepatitisC"
                        className="mr-2"
                      />{" "}
                      Hepatitis C
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="diagnosedHIVHLTVSyphilis"
                        value="None"
                        className="mr-2"
                      />{" "}
                      None of the above
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been significantly exposed to substances that may be
                    transferred in toxic amounts (Lead, Mercury)?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="exposureToxicSubstances"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="exposureToxicSubstances"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been diagnosed with Tuberculosis, Malaria, Chagas disease or
                    Babesiosis or do you have acute respiratory disease?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedTBMalaria"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="diagnosedTBMalaria"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been diagnosed with any form of Creutzfeldt-Jakob disease
                    (CJD) or other Human Transmissible Spongiform
                    Encephalopathy?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="diagnosedCJD"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="diagnosedCJD"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had head or brain surgery with a transplant of brain
                    covering (dura mater)?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="brainSurgery"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="brainSurgery"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had a transplant or medical procedure involving exposure to
                    organs, tissue or living cells from an animal?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="transplantExposure"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="transplantExposure"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Been deferred as a blood donor for a reason other than
                    anemia or being underweight?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="deferredBloodDonor"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="deferredBloodDonor"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Taken intravenous drugs not prescribed by a physician or had
                    sexual contact with someone who has?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="intravenousDrugs"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="intravenousDrugs"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Since 1977, have you lived in Africa or had sexual contact
                    with anyone who was born or lived in Africa?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="africaContact"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="africaContact"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    In the past 12 weeks, had contact with someone who had a
                    smallpox vaccination?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="smallpoxContact"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="smallpoxContact"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    From 1980 through 1996, did you spend time that adds up to
                    three (3) months or more in the United Kingdom? (Review list
                    of countries in the UK)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="timeInUK1980to1996"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="timeInUK1980to1996"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    From 1980 to present, did you spend time that adds up to
                    five (5) years or more in Europe? (Review list of countries
                    in Europe)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="timeInEurope1980toPresent"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="timeInEurope1980toPresent"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    From 1980 to present, did you receive a transfusion of blood
                    or blood components in the United Kingdom or France? (Review
                    list of countries in the UK.)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="transfusionUKFrance"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="transfusionUKFrance"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Have you ever had babesiosis?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="babesiosis"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="babesiosis"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Have you ever been in Africa?
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="beenInAfrica"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="beenInAfrica"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  At any time during the pregnancy has the mother:
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Had a medical diagnosis of a Zika virus infection
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="zikaVirusDiagnosis"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="zikaVirusDiagnosis"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Lived in or traveled to an area with an increased risk for
                    Zika virus transmission? (Review the list of ZIKA virus
                    areas of transmission)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="zikaVirusTravel"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="zikaVirusTravel"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  Has anyone been diagnosed in your Maternal or Paternal Family:
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="aplasticAnemia"
                      className="mr-2"
                    />{" "}
                    Aplastic Anemia
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="fanconiAnemia"
                      className="mr-2"
                    />{" "}
                    Fanconi Anemia
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="thalassemia"
                      className="mr-2"
                    />{" "}
                    Thalassemia (Major or Minor)
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="cgDisease"
                      className="mr-2"
                    />{" "}
                    Chronic Granulomatous Disease (CGD)
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="sickleCell"
                      className="mr-2"
                    />{" "}
                    Sickle Cell Anemia (Disease or Trait)
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="hunterSyndrome"
                      className="mr-2"
                    />{" "}
                    Hunter Syndrome, Hurler Syndrome or any other storage
                    disorder
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    <input
                      type="checkbox"
                      name="familyHistory"
                      value="immunodeficiency"
                      className="mr-2"
                    />{" "}
                    Severe combined Immunodeficiency Syndrome or Blood/Bleeding
                    and Genetic disorders
                  </label>
                  <label className="block text-gray-700 mt-2 flex items-center">
                    Had Creutzfeldt-Jakob Disease (CJD)
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="traveledOutsideUAE"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="traveledOutsideUAE"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Has the mother traveled outside of UAE in the past 6 months?
                    If yes, please mention when and where.
                  </label>
                  <div className="flex items-center mt-2">
                    <label className="mr-4 flex items-center">
                      <input
                        type="radio"
                        name="traveledOutsideUAE"
                        value="Yes"
                        className="mr-2"
                      />{" "}
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="traveledOutsideUAE"
                        value="No"
                        className="mr-2"
                      />{" "}
                      No
                    </label>
                  </div>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Please mention when and where"
                  />
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  MEDICAL DIRECTOR (for CELLSAVE Use only)
                </h3>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Is the donor eligible according to defined risk-based
                    clinical criteria?
                  </label>
                </div>
                <div className="mt-4 page-break">
                  <label className="block text-gray-700">
                    MHHP updated (if obtained more than 7 days before CB
                    collection)?
                  </label>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">
                    Medical Director Signature:
                  </label>
                  <textarea
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    rows={6}
                  ></textarea>
                </div>
                <div className="mt-4">
                  <label className="block text-gray-700">Date:</label>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <h3 className="text-purple-700 font-semibold mt-6">
                  Acknowledgment of Medical and Health History Profile
                </h3>
                <div className="mt-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="acknowledgment"
                      className="mr-2"
                    />
                    I have completed the Medical and Health History Profile in
                    this Section C. I certify that all the information I have
                    provided to Cellsave is true and correct to the best of my
                    knowledge.
                  </label>
                </div>
                <div className="mt-4">
                  <label className="block text-purple-700 font-semibold">
                    Please enter your (MOTHER) Full Name and Email Address to
                    receive a copy of agreement.
                  </label>
                  <p className="mt-2 mb-5 text-xs text-gray-500">
                    Code: CSA/CSL/FOR/002.006a /Rev00/11-Aug-2023
                  </p>
                  <input
                    type="text"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Your legal name"
                  />
                  <input
                    type="email"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Your email address"
                    name="signed-email"
                    required
                    onChange={handleEmailChange}
                  />

                  <input
                    type="date"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Date"
                    required
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <button
          type="submit"
          className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white rounded-lg px-8 py-4 m-4"
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default AdsccMHHP;
