import React from "react";

const Order = ({ data }) => {
  return (
    <div className="flex flex-wrap p-8 bg-white max-lg:px-0">
      <div className=" border rounded-lg  p-8 w-full max-lg:px-4">
        <div className="header-order flex font-semibold p-4 max-md:flex-wrap max-md:gap-4 max-md:hidden">
          <div className="w-1/6 max-md:w-full">
            <h3>Order ID</h3>
          </div>
          <div className="w-2/6 max-md:w-full">
            <h3>Product Name</h3>
          </div>
          <div className="w-1/6 max-md:w-full">
            <h3>
              Price <br /> (AED)
            </h3>
          </div>
          <div className="w-1/6 max-md:w-full">
            <h3>
              Total Price <br /> (AED)
            </h3>
          </div>
          <div className="w-1/6 max-md:w-full">
            <h3>Status</h3>
          </div>
        </div>
        {data.map((order, index) => (
          <div className="order flex p-4 py-8 rounded-lg my-4 bg-[#F7F6FF] max-md:flex-wrap max-md:gap-4">
            <div className="w-1/6 max-md:w-full">
              <h3 className="font-semibold md:hidden">Order Number </h3>
              <h3>{order.id}</h3>
            </div>
            <div className="w-2/6 max-md:w-full">
              <h3 className="font-semibold md:hidden">Product Name</h3>

              <h3>{order.Product_Name}</h3>
            </div>
            <div className="w-1/6 max-md:w-full">
              <h3 className="font-semibold md:hidden">
                Price <br /> (AED){" "}
              </h3>
              <h3> {order.Paid_Amount && order.Paid_Amount}</h3>
            </div>
            <div className="w-1/6 max-md:w-full ">
              <h3 className="font-semibold md:hidden">
                Total Price <br /> (AED)
              </h3>
              <h3> {order.Total_Amount && order.Total_Amount}</h3>
            </div>
            <div className="w-1/6 text-green-600 max-md:w-full">
              <h3 className="font-semibold md:hidden text-black">Status</h3>
              <h3>{order.Status}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
