import React, { useEffect, useState, useRef } from "react";
import ParentContainer from "../components/ParentContainer";
import FirstStep from "./Checkout/FirstStep";
import SecondStep from "./Checkout/SecondStep";
import ThirdStep from "./Checkout/ThirdStep";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import assets from "../assets/assets";
import { Link } from "react-router-dom";

const OrderNow = () => {
  const [activeClass, setActiveClass] = useState("firstStep");
  const handleClick = (item) => {
    setActiveClass(item);
    console.log("active class", item);
  };
  const loginContainerRef = useRef(null);

  const [productPageData, setProductPageData] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [dataReturn, setDataReturn] = useState(null);
  const [loadingHomePage, setLoadingHomePage] = useState(true);
  const [loadingSource, setLoadingSource] = useState(true);
  const [orderData, setOrderData] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchSourceData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + `/api/products?populate=Points,Image`
        );

        setSourceData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching source data:", error);
        setError(true);
      } finally {
        setLoadingSource(false);
      }
    };

    fetchSourceData();
  }, []);

  console.log("orderdata", orderData);

  const [status, setStatus] = useState(null);
  const [id, setId] = useState(null);
  const handleClickOutside = (event) => {
    if (
      loginContainerRef.current &&
      !loginContainerRef.current.contains(event.target)
    ) {
      setStatus(null);
    }
  };
  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const successParam = params.get("success");
    const failedParam = params.get("failed");
    const idParam = params.get("id");

    console.log("Success Param:", successParam);
    console.log("Failed Param:", failedParam);
    console.log("ID Param:", idParam);

    // Update the state based on the presence of query parameters
    if (successParam !== null) {
      setStatus("success");
    } else if (failedParam !== null) {
      setStatus("failed");
    }

    if (idParam !== null) {
      setId(idParam);
      console.log(idParam, "this is id"); // Log idParam directly
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Run once on mount

  useEffect(() => {
    if (status === "success" && id !== null) {
      checkPayment();
    }
  }, [status, id]); // Re-run when `status` or `id` changes

  const checkPayment = async () => {
    console.log("Checking order with status:", status, "and id:", id);

    if (status === "success" && id !== null) {
      try {
        const couponResponse = await axios.post(ApiUrl + "/api/total/check", {
          orderid: id,
        });

        console.log("Coupon Response Data:", couponResponse.data);

        // Ensure dataReturn is being set correctly
        setDataReturn(couponResponse.data);
        console.log("datareturn", dataReturn);
        const params = new URLSearchParams(window.location.search);

        // Log the state after updating
        console.log("DataReturn after setting state:", couponResponse.data);
      } catch (error) {
        console.error("Error creating coupon:", error);
      }
    } else {
      console.log("Skipping coupon check due to invalid status or id");
    }
  };
  useEffect(() => {
    console.log("DataReturn state updated:", dataReturn);
  }, [dataReturn]);
  if (loadingSource) {
    return <div></div>;
  }

  if (error || !sourceData) {
    return <div>Error loading data.</div>;
  }

  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] flex justify-stretch flex-wrap max-md:bg-white relative"
    >
      {status === "success" && dataReturn && (
        <div
          ref={loginContainerRef}
          className="fixed top-1/2 shadow-md rounded-lg left-1/2 z-30 -translate-x-1/2 -translate-y-1/2 bg-white w-[750px] max-lg:!w-[350px] flex justify-center flex-col items-center"
        >
          <img
            src={assets.success}
            alt=""
            className="pt-10 px-20 w-2/5 max-md:w-full"
          />
          {dataReturn.message ? (
            <p className="text-base text-center mb-1">
              Message : <strong>{dataReturn.message}</strong>
            </p>
          ) : (
            <p className="text-base text-center pb-10">
              Order Created{" "}
              <p className="text-base text-center ">
                Username : <strong>{dataReturn.username}</strong>
              </p>
              <p className="text-base text-center ">
                Password : <strong>{dataReturn.randomPassword}</strong>
              </p>
              <p className="text-base text-center ">
                Email : <strong>{dataReturn.email}</strong>
              </p>
              <p className="text-base text-center ">
                Order Ref : <strong>{dataReturn.order.id}</strong>
              </p>
            </p>
          )}

          <div
            className=" absolute left-5 top-5 cursor-pointer"
            onClick={() => setStatus(null)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="flex mt-4 max-md:hidden">
            <div>
              <p className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white py-4 px-8 text-base text-center">
                If you’re located in Abu Dhabi & Al Ain, fill out the following
                documents:
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  <Link
                    to="https://forms.cellsave.com/client-service-agreement/"
                    className=" text-white font-semibold "
                  >
                    Service Agreement
                  </Link>{" "}
                </div>
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  <Link
                    to="https://forms.cellsave.com/adscc-medical-health-history-profile/"
                    className=" text-white font-semibold"
                  >
                    Medical Health History
                  </Link>{" "}
                </div>
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  {" "}
                  <Link to="#" className=" text-white font-semibold">
                    Fee Schedule - Sent over email
                  </Link>
                </div>
              </p>
            </div>

            <div className="">
              <p className="bg-gradient-to-r to-custom-primary from-custom-secondary text-white py-4 px-8 text-base text-center">
                If you’re located in other cities and countries, fill out the
                documents below:
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  <Link
                    to="https://forms.cellsave.com/service-agreement/"
                    className=" text-white font-semibold "
                  >
                    Service Agreement
                  </Link>{" "}
                </div>
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  <Link
                    to="https://forms.cellsave.com/medical-health-history-profile/"
                    className=" text-white font-semibold"
                  >
                    Medical Health History
                  </Link>{" "}
                </div>
                <div className="p-2 border border-1 rounded-lg mt-2 ">
                  {" "}
                  <Link to="#" className=" text-white font-semibold">
                    Fee Schedule - Sent over email
                  </Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      )}
      {status === "failed" && (
        <div
          ref={loginContainerRef}
          className="fixed top-1/2 shadow-md rounded-lg left-1/2 z-30 -translate-x-1/2 -translate-y-1/2 bg-white "
        >
          <img src={assets.failed} alt="" className="pt-10 px-20 " />
          <p className="text-base text-center pb-10">Order Not Created</p>
          <div
            className=" absolute left-5 top-5 cursor-pointer"
            onClick={() => setStatus(null)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      )}
      <div className="bg-white w-full p-8 max-md:p-0  max-md:pt-20">
        <h1 className="text-3xl font-bold pb-4 max-md:text-2xl">Order Now</h1>
        <form className="flex flex-col gap-8 pb-8">
          <div
            // onClick={() => handleClick("firstStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "firstStep" ? "hidden" : ""
            }`}
          >
            <div className="max-md:w-2/12">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="max-md:w-full"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M14.48 12.1L18.5 10.9H20.6V25H17.56V14.02L15.12 14.62L14.48 12.1Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold max-md:w-10/12 max-md:text-base">
              Let’s Get Started
            </p>
          </div>
          <div className={`${activeClass == "firstStep" ? "" : "hidden"}`}>
            <FirstStep handleClickfrom={handleClick} />
          </div>
          <div
            // onClick={() => handleClick("secondStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "secondStep" ? "hidden" : ""
            }`}
          >
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M18.18 13.56C17.6333 13.56 17.1467 13.7133 16.72 14.02C16.3067 14.3267 15.84 14.8 15.32 15.44L13.16 13.7C13.5067 13.2333 13.8533 12.82 14.2 12.46C14.56 12.1 14.94 11.8 15.34 11.56C15.7533 11.3067 16.2067 11.12 16.7 11C17.1933 10.8667 17.7533 10.8 18.38 10.8C19.1267 10.8 19.7933 10.9067 20.38 11.12C20.98 11.32 21.4867 11.6133 21.9 12C22.3267 12.3733 22.6467 12.8267 22.86 13.36C23.0867 13.8933 23.2 14.4867 23.2 15.14C23.2 15.7267 23.12 16.2533 22.96 16.72C22.8133 17.1733 22.5867 17.6067 22.28 18.02C21.9867 18.4333 21.6067 18.8467 21.14 19.26C20.6733 19.66 20.1333 20.1 19.52 20.58L17.24 22.34H23.34V25H12.98V22.56L17.64 18.74C18.08 18.38 18.4533 18.06 18.76 17.78C19.0667 17.4867 19.3133 17.2133 19.5 16.96C19.7 16.6933 19.84 16.44 19.92 16.2C20.0133 15.9467 20.06 15.6733 20.06 15.38C20.06 14.78 19.8867 14.3267 19.54 14.02C19.1933 13.7133 18.74 13.56 18.18 13.56Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold">You Kit Info</p>
          </div>
          <div className={`${activeClass == "secondStep" ? "" : "hidden"}`}>
            <SecondStep
              order={setOrderData}
              data={sourceData.data}
              handleClickfrom={handleClick}
            />
          </div>
          <div
            // onClick={() => handleClick("thirdStep")}
            className={`flex items-center gap-2 p-8 border rounded-lg bg-[#F7F6FF] ${
              activeClass == "thirdStep" ? "hidden" : ""
            }`}
          >
            <div>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M19.08 13.64H13.58V11H23.04V13.32L19.5 16.7C19.98 16.78 20.4467 16.9067 20.9 17.08C21.3533 17.2533 21.7533 17.5 22.1 17.82C22.4467 18.1267 22.7267 18.5133 22.94 18.98C23.1533 19.4333 23.26 19.9867 23.26 20.64C23.26 21.3067 23.14 21.92 22.9 22.48C22.6733 23.04 22.34 23.5267 21.9 23.94C21.4733 24.3533 20.9467 24.6733 20.32 24.9C19.6933 25.1267 18.9933 25.24 18.22 25.24C16.9533 25.24 15.8733 25.02 14.98 24.58C14.1 24.1267 13.36 23.54 12.76 22.82L14.88 20.8C15.36 21.3467 15.86 21.7667 16.38 22.06C16.9133 22.34 17.54 22.48 18.26 22.48C18.8467 22.48 19.32 22.3267 19.68 22.02C20.0533 21.7133 20.24 21.2933 20.24 20.76C20.24 20.1733 20 19.7267 19.52 19.42C19.0533 19.1133 18.38 18.96 17.5 18.96H16.22L15.74 17L19.08 13.64Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold capitalize">
              more detailed info
            </p>
          </div>
          <div className={`${activeClass == "thirdStep" ? "" : "hidden"}`}>
            <ThirdStep
              order={orderData}
              data={sourceData.data}
              handleClickfrom={handleClick}
            />
          </div>
        </form>
      </div>
      {activeClass === "thirdStep" || activeClass === "secondStep" ? (
        <div
          id="fixedPrice"
          className="fixed md:hidden w-full left-0 bottom-0 bg-gradient-to-r from-custom-primary to-custom-secondary h-[100px] flex items-center pl-8"
        >
          <h2 className="font-semibold bg-white px-2 py-2 rounded-lg">
            Total Price:{" "}
            <span className="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text">
              {orderData.totalPrice.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              AED
            </span>
          </h2>
        </div>
      ) : (
        ""
      )}
    </ParentContainer>
  );
};

export default OrderNow;
