import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavigateWithReload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = useRef(location.pathname);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (previousPath.current !== location.pathname) {
      setIsLoading(true);
      previousPath.current = location.pathname;
      setTimeout(() => {
        navigate(0); // Reload the page
      }, 500);
    }
  }, [location, navigate]);

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
          <div className="loader"> </div>
        </div>
      )}
    </>
  );
};

export default NavigateWithReload;
