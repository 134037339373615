import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const Uses = ({ data }) => {
  const bulletIcon = (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10.5781" r="8" stroke="#8884D5" stroke-width="4" />
    </svg>
  );

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();
  const boxesRef = useRef([]);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      boxesRef.current.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  const [beforeDelimiter, afterDelimiter] = data.Uses_Heading.split("||");

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"bg-[#F7F6FF] overflow-hidden"}>
        <h2
          className="text-5xl font-bold text-left pb-12 box max-md:text-2xl "
          ref={(el) => (boxesRef.current[0] = el)}
        >
          {beforeDelimiter}{" "}
          <span className="text-custom-primary">{afterDelimiter}</span>
        </h2>
        <div
          className="flex flex-wrap items-center box "
          ref={(el) => (boxesRef.current[1] = el)}
        >
          <div
            className={`w-1/3 max-md:w-full max-md:flex max-md:justify-center  ${
              isVisible ? "animate__animated animate__fadeInLeft" : ""
            }`}
          >
            <img
              src={ApiUrl + data.Uses_Image.data.attributes.url}
              alt=""
              className="w-4/5 max-md:w-3/5"
            />
          </div>
          <div
            className={`w-2/3 max-md:w-full max-md:pt-4${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <p className="text-xl pb-8 circle max-md:text-sm">
              {data.Uses_Para}
            </p>
            <div className="grid grid-cols-2 gap-2 circle max-md:grid-cols-2 text-lg max-md:text-sm">
              {data.Uses_List.map((list, index) => (
                <div className="flex gap-3  items-center" key={index}>
                  <div id="icon">{bulletIcon}</div>
                  <p>{list.List}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Uses;
