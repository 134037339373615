import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const NewText = ({ data }) => {
  const [
    beforeDelimiter,
    afterDelimiter,
  ] = data.data.attributes.Reason_Section_One.split("||");
  const [
    beforeDelimiter2,
    afterDelimiter2,
  ] = data.data.attributes.Reason_Section_Two.split("||");
  const [
    beforeDelimiter3,
    afterDelimiter3,
  ] = data.data.attributes.Reason_Section_Three.split("||");

  const parentRef = useRef(null);
  const childRef = useRef(null);
  const textRef = useRef(null);
  const [text, setText] = useState(beforeDelimiter);
  const [secondText, setSecondText] = useState(afterDelimiter);

  useEffect(() => {
    const parentElement = parentRef.current;
    const childElement = childRef.current;
    const textElement = textRef.current;

    const updateText = (newText, newSecondText) => {
      gsap.to(textElement, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          setText(newText);
          setSecondText(newSecondText);
          gsap.to(textElement, { opacity: 1, duration: 0.5 });
        },
      });
    };

    const createTrigger = (start, end, color, newText, newSecondText) => {
      ScrollTrigger.create({
        trigger: parentElement,
        start: `${start}% center`,
        end: `${end}% center`,
        onEnter: () => {
          gsap.to(childElement, { background: color, duration: 0.5 });
          if (text === beforeDelimiter && newText === beforeDelimiter) {
            return;
          } else {
            updateText(newText, newSecondText);
          }
        },
        onEnterBack: () => {
          gsap.to(childElement, { background: color, duration: 0.5 });
          updateText(newText, newSecondText);
        },
      });
    };

    createTrigger(0, 33, "#ffffff00", beforeDelimiter, afterDelimiter);
    createTrigger(
      33,
      66,
      "linear-gradient(to right, #8884D5, #CF0072)",
      beforeDelimiter2,
      afterDelimiter2
    );
    createTrigger(66, 100, "white", beforeDelimiter3, afterDelimiter3);
    return () => {
      // Clean up scroll triggers on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div className=" h-[300vh] w-full" ref={parentRef}>
      <div
        className="h-screen w-full sticky top-0 flex items-center justify-center bodypadding"
        ref={childRef}
      >
        <h1
          className={` ${
            text === beforeDelimiter2 ? "text-white" : ""
          } max-md:text-2xl text-5xl font-bold text-center w-full mx-5 max-md:mx-0`}
          ref={textRef}
        >
          {text}{" "}
          <span
            className={` ${
              text === beforeDelimiter2
                ? "text-white"
                : "bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text"
            } `}
          >
            {secondText}
          </span>
        </h1>
      </div>
    </div>
  );
};

export default NewText;
