import React, { useEffect, useState } from "react";
import BlogMainSection from "./BlogSingle/BlogMainSection";
import Preservation from "./BlogSingle/Preservation";
import RelatedBlog from "./BlogSingle/RelatedBlog";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

const BlogSingle = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + `/api/blogs/${id}?populate=Image,blog_category`
        );

        setSource(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, [id]);

  if (loading) {
    return <div></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  console.log("blog single", source);

  return (
    <div>
      <BlogMainSection data={source.data} />
      {/* <Preservation /> */}
      <RelatedBlog data={source.data.attributes.blog_category.data.id} />
    </div>
  );
};

export default BlogSingle;
