import React from "react";

const ParentContainer = ({
  children,
  containerHeight,
  containerStyle,
  containerCSS,
}) => {
  return (
    <div
      className={`bodypadding ${containerHeight}  ${containerStyle} `}
      style={containerCSS}
    >
      {children}
    </div>
  );
};

ParentContainer.defaultProps = {
  containerHeight: "py-20 ",
};

export default ParentContainer;
