import React, { useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";

const FirstStep = ({ handleClickfrom }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    date: "",
  });
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("Next"); // 'send', 'loading', or 'error'

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim()) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
    if (phone) {
      setErrors((prev) => ({ ...prev, phone: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim())
      newErrors.lastName = "Last name is required.";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "A valid email address is required.";
    }
    if (!formData.date.trim()) newErrors.date = "Date is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setStatus("loading"); // Set status to 'loading'
    console.log(formData.date);
    try {
      // Submit data to the first endpoint
      const checkoutResponse = await axios.post(
        `${ApiUrl}/api/checkout-leads`,
        {
          data: {
            First_Name: formData.firstName,
            Last_Name: formData.lastName,
            Phone: formData.phone,
            Email: formData.email,
            date: formData.date,
          },
        }
      );
      console.log(
        "Data submitted to checkout-leads successfully:",
        checkoutResponse.data
      );

      try {
        // Submit data to the Salesforce endpoint
        const salesforceResponse = await axios.post(
          `${ApiUrl}/api/salesforce`,
          {
            FirstName: formData.firstName,
            LastName: formData.lastName,
            Email: formData.email,
            MobilePhone: formData.phone,
            Origin_Program__c: "Partial Order Lead",
            Lead_Quality__c: "Warm",
            AttendingDoctor__c: "a00f400000XCppCAAT",
            Hospital_Clinics__c: "a01f400000KF5VMAA1",
            Program_Running__c: "Website",
            Origin_Hospital__c: "a01f400000KF5VMAA1",
            Origin_Doctor__c: "a00f400000XCppCAAT",
            Due_Date__c: formData.date,
          }
        );
        console.log(
          "Data submitted to Salesforce successfully:",
          salesforceResponse.data
        );
      } catch (salesforceError) {
        console.error(
          "Error submitting data to Salesforce:",
          salesforceError.response?.data || salesforceError.message
        );
      }

      try {
        const recipientEmails = ` maqcadet3@gmail.com, infoarabia@cellsave.com, marketing@cellsave.com, cs-callcenter@cellsave.com, tala.hammami@cellsave.com, cs-agents@cellsave.com`;

        const emailPayload = {
          to: recipientEmails,
          subject: "New Partial Lead Form Submission",
          text: "You have a new message from the Partial Lead.",
          html: `
            <p><strong>First Name:</strong> ${formData.firstName}</p>
            <p><strong>Last Name:</strong> ${formData.lastName}</p>
            <p><strong>Phone Number:</strong> ${formData.phone}</p>
            <p><strong>Email:</strong> ${formData.email}</p>
            <p><strong>Expected Date:</strong> ${formData.date}</p>
          `,
        };

        // Submit email
        const emailResponse = await axios.post(
          `${ApiUrl}/api/email/send`,
          emailPayload
        );
        console.log("Email sent successfully:", emailResponse.data);
      } catch (emailError) {
        console.error(
          "Error sending email:",
          emailError.response?.data || emailError.message
        );
      }
      setStatus("Next"); // Reset status to 'send' after success

      handleClickfrom("secondStep");
    } catch (checkoutError) {
      console.error(
        "Error submitting data to checkout-leads:",
        checkoutError.response?.data || checkoutError.message
      );
      setStatus("error");
    }
  };

  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px] max-md:text-sm">
      <div className="p-8 bg-white rounded-lg max-md:p-4">
        <div className="flex items-center gap-2">
          <div className="max-md:w-2/12">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="max-md:w-full"
            >
              <circle cx="18" cy="18" r="18" fill="#8884D5" />
              <path
                d="M14.48 12.1L18.5 10.9H20.6V25H17.56V14.02L15.12 14.62L14.48 12.1Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="text-lg font-semibold max-md:w-10/12 max-md:text-base">
            Let’s Get Started
          </p>
        </div>
        <p className="text-base pt-2 max-md:text-sm">
          To get started, please complete the form below.
        </p>

        <div className="pt-4">
          <div className="pt-2 pb-8 grid grid-cols-6 gap-4">
            <div className="col-span-6 grid grid-cols-2 gap-4">
              <div className="max-md:col-span-4">
                <label htmlFor="firstName" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {errors.firstName && (
                  <span className="text-red-500 text-sm">
                    {errors.firstName}
                  </span>
                )}
              </div>

              <div className="max-md:col-span-4">
                <label htmlFor="lastName" className="block mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                />
                {errors.lastName && (
                  <span className="text-red-500 text-sm">
                    {errors.lastName}
                  </span>
                )}
              </div>
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="phone" className="block mb-1">
                Phone Number
              </label>
              <PhoneInput
                country={"ae"}
                value={formData.phone}
                onChange={handlePhoneChange}
                enableSearch
                disableSearchIcon
                required
              />
              {errors.phone && (
                <span className="text-red-500 text-sm">{errors.phone}</span>
              )}
            </div>
            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
            </div>
            <div className="col-span-2 max-md:col-span-4">
              <label className="block mb-1" htmlFor="date">
                Due Date
              </label>
              <input
                className="border border-gray-300 rounded-md px-3 py-2 w-full text-[15px]"
                type="date"
                name="date"
                id="date"
                value={formData.date}
                onChange={handleInputChange}
                placeholder="Due Date"
                required
              />
              {errors.date && (
                <span className="text-red-500 text-sm">{errors.date}</span>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl"
        >
          {status}
        </button>
      </div>
    </div>
  );
};

FirstStep.propTypes = {
  handleClickfrom: PropTypes.func.isRequired,
};

export default FirstStep;
