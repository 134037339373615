import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Button from "../../components/Button";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const Medicine = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  const observerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= 0.1) {
          observerRef.current = true;
          observer.disconnect();
          initializeScrollTrigger();
        }
      },
      {
        threshold: [0.1],
      }
    );

    observer.observe(main.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const initializeScrollTrigger = () => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 50, scale: 0 },
          {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 4,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 20,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 90%",
          end: "bottom 90%",
          scrub: true,
          //  markers: true,
        },
      });
    }, main);

    return () => {
      ctx.revert();
    };
  };

  const [beforeDelimiter1, afterDelimiter1] =
    data.Impact_Med_Heading.split("||");

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={"flex flex-wrap justify-center overflow-hidden"}
      >
        <div className="w-full flex flex-wrap justify-between box max-md:just overflow-hidden">
          <h3 className=" text-4xl w-2/3  font-bold   pb-5  max-md:text-2xl max-md:w-full">
            {beforeDelimiter1}
            <span className=" text-custom-primary">{afterDelimiter1}</span>
          </h3>
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!items-center !w-auto !justify-end !px-0"}
          />
        </div>
        <div
          className="flex gap-40 pt-16 items-center max-md:flex-wrap max-lg:gap-10 overflow-hidden"
          ref={parentRef}
        >
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 pb-16 imgs max-md:w-full `}
          >
            <img
              src={ApiUrl + data.Impact_Med_Image_One.data.attributes.url}
              alt=""
              className="w-full max-md:w-3/5"
            />
            <p className="w-4/5 text-left text-lg max-md:text-sm max-md:text-center ">
              {data.Impact_Med_Text_One}
            </p>
          </div>
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 imgs max-md:w-full $`}
          >
            <img
              src={ApiUrl + data.Impact_Med_Image_Two.data.attributes.url}
              alt=""
              className="w-full max-md:w-3/5"
            />
            <p className="w-4/5 text-left text-lg max-md:text-sm  max-md:text-center">
              {data.Impact_Med_Text_Two}
            </p>
          </div>
          <div
            className={`w-1/3 flex flex-wrap justify-center gap-5 pt-16 imgs max-md:w-full `}
          >
            <img
              src={ApiUrl + data.Impact_Med_Image_Three.data.attributes.url}
              alt=""
              className="w-full max-md:w-3/5"
            />
            <p className="w-4/5 text-left text-lg max-md:text-sm max-md:text-center">
              {data.Impact_Med_Three}
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Medicine;
