import axios from "axios";
import React, { useRef, useState } from "react";
import { ApiUrl } from "../../components/ApiUrl";

const History = () => {
  const questions = [
    "Are_you_in_good_general_health?__if_NO,_please_explain",
    "Are_you_suffering_from_any_chronic_disease?__If_YES,_please_specify",
    "Are_you_taking_any_prescribed_medication_s?_If_YES,_please_specify_name/_reason",
    "Are_you_having_complications_with_this_pregnancy?_If_YES,_please_specify",
    "Are_you_having_planned_cesarean_delivery?",
    "Do_you_currently_have_an_infectious_skin_disease?_N/A_for_father",
    "Do_you_currently_have_any_medical_condition_that_could_be_affected_adversely_by_the_collection_procedure?",
    "Accutane_(isotretinoin)or_Proscar_for_your_skin–_acne_medication__within_the_last_month",
    "Propecia_(finasteride)_–_hair_loss_treatment_within_the_last_month",
    "Live_Vaccines_(Measles,_Mumps)__within_the_last_month",
    "Smallpox_Vaccine__within_the_past_8_weeks",
    "Any_chemotherapy__during_pregnancy_N/A_for_father",
    "Immune_Globulin_(Not_Rh_Immune_Globulin)__within_the_past_12_months",
    "Experimental_Medications/Vaccines__within_the_past_12_months",
    "Rabies_Vaccine_–_for_exposure__within_the_past_12_months",
    "Soriatane_(acitretin)or_Tegison(etretinate)_–_for_psoriasis__within_the_past_3_years",
    "Insulin_from_a_Cow_Source__Ever",
    "Growth_Hormone_from_human_Pituitary_Glands_(not_infertility_hormones)__Ever",
    "Since_1980,_have_you_received_a_transfusion_blood,_platelets,_plasma,_cryoprecipitate_or_granulocytes_in_the_UK?",
    "Since_1980,have_you_spent_more_than_a_total_of_6_months_in_Europe?(This_includes_living_or_travelling)",
    "In_the_past_6_months,_have_you_been_bitten_by_an_animal_suspected_of_having_rabies?",
    "Received_blood_or_blood_factor_products,_derivatives_or_a_tissue_organ_transplant?",
    "Come_into_contact_with_someone_else’s_blood_(_E.g.,accidental_needle_stick)",
    "Had_a_tattoo,any_type_of_piercings(ear_or_body),_acupuncture_or_had_a_needle_gun_used_on_you?_Circle_applicable.",
    "Received_shots,_vaccinations,_including_Rh_Immune_Globulin?",
    "Been_diagnosed_with_West_Nile_Virus?",
    "Been_in_jail_or_prison_for_more_than_72_hours?",
    "Had_sexual_contact_with:_Someone_with_Hepatitis,_Jaundice_or_HIV?",
    "Had_sexual_contact_with:_Anyone_who_has_Hemophilia_or_has_used_clotting_factor_concentrates?",
    "Had_an_accidental_needle-stick?",
    "Been_diagnosed_with_Sepsis?_Or_Bacteremia?",
    "Been_diagnosed_with,_or_tested_positive_for_HIV,_HLTV,_Syphilis,_Hepatitis_B_or_C?",
    "Been_significantly_exposed_to_substances_that_may_be_transferred_in_toxic_amounts_(Lead,_Mercury)?",
    "Been_diagnosed_with_Tuberculosis,_Malaria,_Chagas_disease_or_Babesiosis_or_do_you_have_acute_respiratory_disease?",
    "Been_diagnosed_with_any_form_of_Creutzfeldt-Jakob_disease_(CJD)_or_other_Human_Transmissible_Spongiform_Encephalopathy?",
    "Had_head_or_brain_surgery_with_a_transplant_of_brain_covering_(dura_mater)?",
    "Had_a_transplant_or_medical_procedure_involving_exposure_to_organs,_tissue_or_living_cells_from_an_animal?",
    "Been_deferred_as_blood_donor_for_a_reason_other_than_anemia_or_being_underweight?",
    "Taken_intravenous_drugs_not_prescribed_by_a_physician_or_had_sexual_contact_with_someone_who_has?",
    "Since_1977,_have_you_lived_in_Africa_or_had_sexual_contact_with_anyone_who_was_born_or_lived_in_Africa?",
    "In_the_past_12_weeks,_had_contact_with_someone_who_had_a_smallpox_vaccination?",
    "From_1980_through_1996,Did_you_spend_time_that_adds_up_to_three(3)months_or_more_in_the_United_Kingdom?(Review_list_of_countries_in_the_UK)",
    "From_1980_to_present,did_you_spend_time_that_adds_up_to_five(5)years_or_more_in_Europe?(Review_list_of_countries_in_Europe)",
    "From_1980_to_present,did_you_receive_a_transfusion_of_blood_or_blood_components_in_the_United_Kingdom_or_France?(Review_list_of_countries_in_the_UK)",
    "Have_you_ever_had_babesiosis?",
    "Have_you_ever_been_in_Africa?",
    "Had_a_medical_diagnosis_of_a_Zika_virus_infection",
    "Lived_in_or_traveled_to_an_area_with_an_increased_risk_for_Zika_virus_transmission?_(Review_the_list_of_ZIKA_virus_areas_of_transmission)",
    "Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family:",
    "Had_Creutzfeldt-Jakob_Disease_(CJD)",
    "Has_the_mother_traveled_outside_of_UAE_in_the_past_6_months?_If_yes,_please_mention_when_and_where.",
    "Acknowledgment_of_Medical_and_Health_History_Profile",
    "Agreement_Terms",
    "Mother_Legal_Name",
    "Mother_email_address",
  ];
  const formatString = (str) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/[()]/g, ""); // Remove parentheses
  };

  const formRef = useRef();
  const [status, setStatus] = useState(null);
  const [formData, setFormData] = useState(null);
  console.log(localStorage.getItem("user"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    setFormData(data);
    // Format data as "name: value" pairs
    const formattedData = Object.entries(data)
      .map(([key, value]) => `${formatString(key)}:\n ${value}`)
      .join("\n \n");

    // Send data to Strapi
    try {
      const response = await axios.put(
        ApiUrl + `/api/users/${localStorage.getItem("user")}`,
        {
          History_Text: formattedData,

          // Replace 'textField' with the actual field name in Strapi
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStatus("we received your request");
        console.log("Data sent to Strapi successfully");
      } else {
        console.error("Failed to send data to Strapi");
      }
    } catch (error) {
      console.error("Error sending data to Strapi:", error);
    }
  };

  return (
    <div className="pt-10 text-base max-md:text-sm">
      <form ref={formRef} onSubmit={handleSubmit}>
        <p>Health of the MOTHER and baby’s BIOLOGICAL FATHER</p>

        {questions.slice(0, 7).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>
            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id={`radio-excellent-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="yes"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-excellent-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id={`radio-good-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="no"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-good-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  no
                </label>
              </div>
              {index < 3 && (
                <input
                  className="bg-[#F6F6F6] p-3 rounded-lg"
                  type="text"
                  name={`${question + index}_input`}
                  placeholder=""
                  id="username"
                />
              )}
            </div>
          </div>
        ))}

        <p>
          Have you taken any of the following Medications/Vaccines within the
          specified timeframe?
        </p>
        {questions.slice(7, 21).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>
            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id={`radio-excellent-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="yes"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-excellent-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id={`radio-good-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="no"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-good-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  no
                </label>
              </div>
            </div>
          </div>
        ))}

        <p>In the past 12 months, have YOU or the baby’s BIOLOGICAL FATHER:</p>
        {questions.slice(21, 30).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>
            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id={`radio-excellent-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="yes"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-excellent-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id={`radio-good-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="no"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-good-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  no
                </label>
              </div>
            </div>
          </div>
        ))}

        <p>Have YOU or the baby’s BIOLOGICAL FATHER ever:</p>
        {questions.slice(30, 46).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>

            {index === 1 ? (
              <div className="text-center flex flex-wrap justify-start gap-5">
                <div className="flex items-center">
                  <input
                    id={`radio-hiv-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="hiv"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-hiv-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    HIV
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-hltv-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="hltv"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-hltv-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    HLTV
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-Syphilis-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="Syphilis"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-Syphilis-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    Syphilis
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-Hepatitis-B-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="Hepatitis-B"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-Hepatitis-B-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    Hepatitis-B
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-Hepatitis-C-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="Hepatitis-C"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-Hepatitis-C-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    Hepatitis-C
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-none-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="none"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-none-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    none of the above
                  </label>
                </div>
              </div>
            ) : (
              <div className="text-center flex flex-wrap justify-start gap-5">
                <div className="flex items-center">
                  <input
                    id={`radio-excellent-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="yes"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-excellent-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    yes
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id={`radio-good-${question + index}`} // Unique ID for each option
                    type="radio"
                    name={`${question + index}`} // Group radio buttons by question index
                    value="no"
                    className="hidden"
                  />
                  <label
                    htmlFor={`radio-good-${question + index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                    no
                  </label>
                </div>
              </div>
            )}
          </div>
        ))}
        <p>At any time during the pregnancy has the mother: </p>
        {questions.slice(46, 48).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>

            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id={`radio-excellent-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="yes"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-excellent-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id={`radio-good-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="no"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-good-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  no
                </label>
              </div>
            </div>
          </div>
        ))}
        <p>Has anyone been diagnosed in your Maternal or Paternal Family:</p>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Aplastic-Anemia"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family:"
              value="Aplastic-Anemia"
            />{" "}
            Aplastic Anemia
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Fanconi-Anemia"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family1:"
              value="Fanconi-Anemia"
            />{" "}
            Fanconi Anemia
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Chronic-Granulomatosis-Disease"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family2:"
              value="Chronic-Granulomatosis-Disease"
            />{" "}
            Chronic Granulomatosis Disease
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Thalassemia"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family3:"
              value="Thalassemia"
            />{" "}
            Thalassemia
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Hunter-Syndrome"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family4:"
              value="Hunter-Syndrome"
            />{" "}
            Hunter Syndrome
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Severe_combined_Immunodeficiency_Syndrome Blood/Bleeding_and_Genetic_disorders"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family5:"
              value="Severe_combined_Immunodeficiency_Syndrome Blood/Bleeding_and_Genetic_disorders"
            />{" "}
            Severe combined Immunodeficiency Syndrome or Blood/Bleeding and
            Genetic disorders
          </label>
        </div>
        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="Sickle-Cell-Anemia"
              name="Has_anyone_been_diagnosed_in_your_Maternal_or_Paternal_Family6:"
              value="Sickle-Cell-Anemia"
            />{" "}
            Sickle Cell Anemia
          </label>
        </div>
        {questions.slice(49, 51).map((question, index) => (
          <div className="inputContainer" key={index}>
            <p className="font-bold pb-2">{formatString(question)}</p>

            <div className="text-center flex flex-wrap justify-start gap-5">
              <div className="flex items-center">
                <input
                  id={`radio-excellent-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="yes"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-excellent-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  yes
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id={`radio-good-${question + index}`} // Unique ID for each option
                  type="radio"
                  name={`${question + index}`} // Group radio buttons by question index
                  value="no"
                  className="hidden"
                />
                <label
                  htmlFor={`radio-good-${question + index}`}
                  className="flex items-center cursor-pointer"
                >
                  <span className="w-4 h-4 inline-block mr-1 rounded-full border border-grey"></span>
                  no
                </label>
              </div>
            </div>
          </div>
        ))}

        <div class="checkbox esig-checkbox">
          <label>
            <input
              type="checkbox"
              id="accept-terms"
              name="accept-terms"
              value="accept-terms"
            />{" "}
            I have completed the Medical and Health History Profile in this
            Section C. I certify that all the information I have provided to
            Cellsave is true and correct to the best of my knowledge.
          </label>
        </div>

        <p>
          Please enter your (MOTHER) Full Name and Email Address to receive a
          copy of agreement.
        </p>
        <div className=" w-full flex gap-2">
          <input
            className="bg-[#F6F6F6] p-3 rounded-lg"
            type="text"
            name={`Mother_Name_input`}
            placeholder="Mother Name"
            id="mother_name"
          />
          <input
            className="bg-[#F6F6F6] p-3 rounded-lg"
            type="email"
            name={`Mother_email_input`}
            placeholder="Mother Email"
            id="mother_email"
          />
        </div>

        <button
          className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
          type="submit"
        >
          {status ? status : "Submit"}
        </button>
      </form>
      {/* {formData && (
        <div>
          <h2>Form Data</h2>
          <ul>
            {Object.entries(formData).map(([key, value]) => (
              <li key={key}>
                <strong>{formatString(key)}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>
      )} */}
    </div>
  );
};

export default History;
