import React, { useState, useEffect } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { ApiUrl } from "../../components/ApiUrl";

const BlogIndex = ({ data }) => {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(10);
  const [isButton, setIsButton] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (max >= data.length) {
      setDisableButton(true);
    }
  }, [max, data.length]);

  return (
    <ParentContainer containerStyle={"flex justify-center flex-wrap"}>
      <div className="border-b border-custom-primary pb-2">
        <h3 className="text-2xl text-custom-primary font-semibold">
          Top Reads Blogs
        </h3>
      </div>
      {data && data.length > 0 ? (
        <>
          <div className="custom-parent grid grid-cols-4 gap-5 pt-8 max-lg:grid-cols-2 max-md:grid-cols-1">
            {data.slice(min, max).map((blog, index) => (
              <div key={index}>
                <div id="imageindex">
                  {blog.attributes.Image.data && (
                    <img
                      src={ApiUrl + blog.attributes.Image.data.attributes.url}
                      alt={blog.attributes.Title}
                      className="w-full object-cover object-center h-[250px] rounded-lg max-md:h-[100px] max-md:w-auto"
                    />
                  )}
                </div>
                <div className="py-5">
                  <p className="text-xs text-gray-500">
                    {" "}
                    {blog.attributes.blog_category?.data?.attributes
                      ?.Category ?? ""}
                  </p>
                  <Link
                    to={`/blog-single/${blog.id}`}
                    className="text-xl font-semibold underline max-md:text-base"
                  >
                    {blog.attributes.Title}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          {!disableButton && (
            <div
              onClick={() => setMax(max + 8)}
              className="flex text-base w-1/2 gap-5 justify-center px-5 group max-md:w-full max-lg:text-center max-lg:items-center cursor-pointer"
              onMouseEnter={() => setIsButton(true)}
              onMouseLeave={() => setIsButton(false)}
            >
              <p className="max-md:text-sm border-black border-2 rounded-3xl group-hover:px-8 transition-all py-1 px-2 font-semibold">
                Read More
              </p>
              <svg
                width="39"
                height="38"
                viewBox="0 0 39 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={isButton ? "arrowmove" : ""}
              >
                <circle
                  cx="19.5"
                  cy="19"
                  r="19"
                  transform="rotate(-90 19.5 19)"
                  fill="url(#paint0_linear_590_3775)"
                />
                <path
                  d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
                  fill="white"
                />
                <path
                  d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.6816 18.8779L22.9741 18.8779"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_3775"
                    x1="-3.10345"
                    y1="4.22222"
                    x2="45.1213"
                    y2="7.62688"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#8884D5" />
                    <stop offset="1" stopColor="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
        </>
      ) : (
        <p className="text-center">No blogs available.</p>
      )}
    </ParentContainer>
  );
};

export default BlogIndex;
