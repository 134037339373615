import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import { getAllFAQs, getFAQsByCategory, searchFAQs } from "../api";

const AccordionSetm = ({ title, children, isOpen, onClick }) => {
  return (
    <div className="  w-full border-b">
      <button
        onClick={onClick}
        className="w-full flex justify-between items-center p-8 text-left"
      >
        <div className="flex gap-2 w-full items-center">
          {" "}
          <span className="text-lg font-medium w-11/12">{title}</span>
          <div className="w-1/12 flex justify-end">
            {isOpen ? (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1668H13.7497C18.333 20.1668 20.1663 18.3335 20.1663 13.7502V8.25016C20.1663 3.66683 18.333 1.8335 13.7497 1.8335H8.24967C3.66634 1.8335 1.83301 3.66683 1.83301 8.25016V13.7502C1.83301 18.3335 3.66634 20.1668 8.24967 20.1668Z"
                  stroke="url(#paint1_linear_590_5766)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5766"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5766"
                    x1="0.0945021"
                    y1="3.87053"
                    x2="23.3609"
                    y2="5.51313"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33301 11H14.6663"
                  stroke="url(#paint0_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 14.6666V7.33325"
                  stroke="url(#paint1_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.24967 20.1666H13.7497C18.333 20.1666 20.1663 18.3333 20.1663 13.7499V8.24992C20.1663 3.66659 18.333 1.83325 13.7497 1.83325H8.24967C3.66634 1.83325 1.83301 3.66659 1.83301 8.24992V13.7499C1.83301 18.3333 3.66634 20.1666 8.24967 20.1666Z"
                  stroke="url(#paint2_linear_590_5758)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_5758"
                    x1="6.63761"
                    y1="11.1111"
                    x2="14.0135"
                    y2="14.9298"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_5758"
                    x1="10.9052"
                    y1="8.14807"
                    x2="12.1805"
                    y2="8.16034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_5758"
                    x1="0.0945021"
                    y1="3.87029"
                    x2="23.3609"
                    y2="5.51289"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
      </button>
      {isOpen && <div className="px-8 py-4">{children}</div>}
    </div>
  );
};

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(0); // Initialize to keep the first item open by default
  const [activeFilter, setActiveFilter] = useState(null);

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else if (openIndex == index) {
      setOpenIndex(null);
    }
  };

  const [faqs, setFAQs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const data = await getAllFAQs();
        setFAQs(data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchFAQs();
  }, []);

  const filterCategory = async (cat) => {
    try {
      const data = await getFAQsByCategory(cat);
      setFAQs(data);
      setLoading(false);
      setError(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const searchFaqs = async (term) => {
    try {
      const data = await searchFAQs(term);
      setFAQs(data);
      setLoading(false);
      setError(null);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();
    searchFaqs(searchTerm);
  };

  return (
    <ParentContainer containerStyle={"flex flex-col gap-2"}>
      <h1 className="text-3xl font-bold max-md:text-2xl">
        Frequently <span className="text-custom-primary">Asked Questions</span>
      </h1>
      <p className="text-base max-md:text-sm">
        FAQ providing answers and information about CellSave.
      </p>
      <form
        onSubmit={handleSearch}
        className="bg-white rounded-lg  flex justify-between items-center gap-2 w-full shadow-md py-5 px-5"
      >
        <input
          type="text"
          placeholder="Search for answers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-10/12 outline-0 text-base bg-transparent"
        />
        <button
          onClick={() => setActiveFilter(null)}
          type="submit"
          className="inline w-2/12 flex justify-end"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="url(#paint0_linear_590_10279)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="url(#paint1_linear_590_10279)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_10279"
                x1="0.198276"
                y1="4.11111"
                x2="24.3107"
                y2="5.81344"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#8884D5" />
                <stop offset="1" stopColor="#CF0072" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_590_10279"
                x1="19.8103"
                y1="20.2222"
                x2="22.3485"
                y2="20.4014"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#8884D5" />
                <stop offset="1" stopColor="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        </button>
      </form>

      <div className="flex flex-wrap justify-between pt-5 mt-5 items-center">
        <h2
          onClick={() => {
            filterCategory("1");
            setActiveFilter("overview");
            setOpenIndex(0);
          }}
          className={`${
            activeFilter == "overview"
              ? "rounded-full border-black border-2 "
              : ""
          } text-xl font-semibold px-4 py-2 max-md:text-base max-md:w-full`}
        >
          Stem Cell Overview
        </h2>
        <h2
          onClick={() => {
            filterCategory("2");
            setActiveFilter("process");
            setOpenIndex(0);
          }}
          className={`${
            activeFilter == "process"
              ? "rounded-full border-black border-2 "
              : ""
          } text-xl font-semibold px-4 py-2 max-md:text-base max-md:w-full`}
        >
          The Collection Process
        </h2>
        <h2
          onClick={() => {
            filterCategory("3");
            setActiveFilter("storage");
            setOpenIndex(0);
          }}
          className={`${
            activeFilter == "storage"
              ? "rounded-full border-black border-2 "
              : ""
          } text-xl font-semibold px-4 py-2 max-md:text-base max-md:w-full`}
        >
          Processing & Storage
        </h2>
        <h2
          onClick={() => {
            filterCategory("4");
            setActiveFilter("general");
            setOpenIndex(0);
          }}
          className={`${
            activeFilter == "general"
              ? "rounded-full border-black border-2 "
              : ""
          } text-xl font-semibold px-4 py-2 max-md:text-base max-md:w-full`}
        >
          General
        </h2>
      </div>
      <div className="flex flex-col w-full border border-[#EAECF0] rounded-lg mt-5">
        {loading ? <p className="p-8">Loading FAQs...</p> : ""}
        {error ? (
          <p className="p-8">Error loading FAQs: {error.message}</p>
        ) : (
          faqs.map((faq, index) => (
            <AccordionSetm
              title={faq.attributes.Title}
              isOpen={openIndex === index}
              onClick={() => handleToggle(index)}
              key={index}
            >
              <p>{faq.attributes.Body}</p>
            </AccordionSetm>
          ))
        )}
      </div>
    </ParentContainer>
  );
};

export default FAQs;
