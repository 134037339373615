import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";

const Button = ({ Linkto, titleof, CustomStyle, white }) => {
  const [isbutton, setIsButton] = useState(false);
  const [isWhite, setIsWhite] = useState(false);

  useEffect(() => {
    if (white) {
      setIsWhite(true);
    }
  }, []);

  return (
    <Link
      to={Linkto}
      className={`flex text-base w-1/2 gap-5 justify-center px-5 group max-md:w-full max-lg:text-center max-lg:items-center  ${CustomStyle}  `}
      onMouseEnter={() => {
        setIsButton(true);
      }}
      onMouseLeave={() => {
        setIsButton(false);
      }}
    >
      <p
        className={` max-md:text-sm ${
          isWhite ? "border-white text-white" : "border-black "
        }  border-2 rounded-3xl group-hover:px-8 transition-all py-1 px-2 font-semibold`}
      >
        {titleof}
      </p>
      {isWhite ? (
        <svg
          width="39"
          height="38"
          viewBox="0 0 39 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={` ${isbutton ? "arrowmove" : ""}  `}
        >
          <circle
            cx="19.5"
            cy="19"
            r="19"
            transform="rotate(-90 19.5 19)"
            fill="white"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            fill="url(#paint0_linear_590_3775)"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            stroke="url(#paint0_linear_590_3775)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6816 18.8779L22.9741 18.8779"
            stroke="url(#paint0_linear_590_3775)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_590_3775"
              x1="-3.10345"
              y1="4.22222"
              x2="45.1213"
              y2="7.62688"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8884D5" />
              <stop offset="1" stop-color="#CF0072" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="39"
          height="38"
          viewBox="0 0 39 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={` ${isbutton ? "arrowmove" : ""}  `}
        >
          <circle
            cx="19.5"
            cy="19"
            r="19"
            transform="rotate(-90 19.5 19)"
            fill="url(#paint0_linear_590_3775)"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            fill="white"
          />
          <path
            d="M20.4707 16.1187L23.1989 18.847L20.4707 21.5752"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6816 18.8779L22.9741 18.8779"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_590_3775"
              x1="-3.10345"
              y1="4.22222"
              x2="45.1213"
              y2="7.62688"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#8884D5" />
              <stop offset="1" stop-color="#CF0072" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </Link>
  );
};

export default Button;
