import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
gsap.registerPlugin(ScrollTrigger);

const Acceridation = ({ data }) => {
  const parentRef = useRef(null);
  const main = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 90%",
          end: "bottom 90%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle="flex flex-wrap items-start bg-gradient-to-r from-custom-primary to-custom-secondary justify-center max-md:py-12"
        containerHeight="py-36 "
      >
        <h2 className="box text-5xl font-bold text-left text-white  w-full box max-md:text-2xl">
          {data.CellSave_Licenses.Heading}{" "}
        </h2>
        <div
          ref={parentRef}
          className="w-full flex flex-wrap justify-center gap-5 pt-20 max-md:pt-5 max-md:grid-cols-2 max-lg:gap-0  max-lg:justify-between max-lg:gap-y-4"
        ></div>

        <div className="w-full flex items-center gap-2">
          {/* <div className="swiper-button-prev3">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="matrix(0 -1 -1 0 50 50)"
                fill="#37424A"
              />
              <path
                d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
                fill="white"
              />
              <path
                d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M28.709 25.0039L20.4294 25.0039"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> */}
          <Swiper
            navigation={true}
            slidesPerView={4}
            spaceBetween={30}
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: ".swiper-button-next3",
              prevEl: ".swiper-button-prev3",
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 3,
                spaceBetween: 5,
                //old value 1
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 3,
                spaceBetween: 5,
                //old value 2
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
                spaceBetween: 5,

                //old value 2
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="w-full"
          >
            {data.CellSave_Licenses.Images.map((img, index) => (
              <SwiperSlide>
                <div
                  key={index}
                  className="imgs w-full p-2 max-md:p-2 max-lg:px-2 flex justify-center rounded-xl border-white border "
                >
                  <img
                    src={ApiUrl + img.image.data.attributes.url}
                    alt={`Featured ${index}`}
                    className="w-3/5 max-md:w-full"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <div className="swiper-button-next3">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="rotate(-90 25 25)"
                fill="#37424A"
              />
              <path
                d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
                fill="white"
              />
              <path
                d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.291 24.4033L29.5706 24.4033"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_4369"
                  x1="-4.74138"
                  y1="5.55556"
                  x2="58.7123"
                  y2="10.0354"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div> */}
        </div>
      </ParentContainer>
    </div>
  );
};

export default Acceridation;
