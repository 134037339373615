import React from "react";
import ParentContainer from "./ParentContainer";
import { Link } from "react-router-dom";
import Button from "./Button";
const LifeTimeBanner = ({ Heading, ButtonText, Linkto }) => {
  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-primary to-custom-secondary flex items-center max-md:flex-wrap "
      }
    >
      <h3 className="text-4xl capitalize text-white font-bold text-left  w-1/2 max-md:!w-full max-lg:w-2/3  max-md:text-2xl max-md:pb-4">
        {Heading}{" "}
      </h3>
      <Button
        Linkto={Linkto}
        titleof={ButtonText}
        CustomStyle={"!items-center  !justify-end !px-0 max-md:!justify-start"}
        white={true}
      />
    </ParentContainer>
  );
};

LifeTimeBanner.defaultProps = {
  Heading: "A Once In A Lifetime Opportunity",
  ButtonText: "Order Now",
  Linkto: "/checkout",
};

export default LifeTimeBanner;
