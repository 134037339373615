import React from "react";
import ParentContainer from "./ParentContainer";
import assets from "../assets/assets";
import { Link } from "react-router-dom";
import { ApiUrl } from "./ApiUrl";

const NewsListing = ({ data }) => {
  return (
    <ParentContainer containerStyle={"flex flex-wrap"}>
      <Link
        to={`/blog-single/${data[0].id}`}
        className="w-1/2 flex flex-col gap-3  max-lg:w-full"
      >
        <img
          src={ApiUrl + data[0].attributes.Image.data.attributes.url}
          alt=""
          className=" object-cover h-[300px] rounded-lg"
        />
        <p
          className="text-xs text-gray-500
          "
        >
          {data[0].attributes.blog_category.data.attributes.Category}
        </p>
        <h2 className="text-2xl font-semibold underline max-md:text-xl">
          {data[0].attributes.Title}
        </h2>
      </Link>
      <div className="w-1/2 pl-5 flex flex-col  gap-2 max-lg:w-full max-lg:pl-0">
        <div className="border-b border-custom-primary pb-2 max-lg:border-t max-lg:border-b-0 max-lg:mt-4 max-lg:pt-4">
          <h3 className="text-2xl text-custom-primary font-semibold">
            Top Reads Blogs
          </h3>
        </div>
        <div className="flex flex-col">
          {data.slice(1, 5).map((top, index) => (
            <Link to={`/blog-single/${top.id}`} className="py-5" key={index}>
              <p className="text-xs text-gray-500">
                {top.attributes.blog_category.data.attributes.Category}
              </p>
              <h2 className="text-xl font-semibold underline max-md:text-base">
                {top.attributes.Title}
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </ParentContainer>
  );
};

export default NewsListing;
