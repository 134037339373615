import React, { useRef, useState } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ApiUrl } from "../../../components/ApiUrl";
import assets from "../../../assets/assets";

const FeeSchedule = () => {
  const printRef = useRef();
  const [files, setFiles] = useState([]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");
  const [buttonText, setButtonText] = useState("Send Contract");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    package: "",
    enrollmentFee: "",
    processingFee: "",
    storageFee: "",
    storagePeriod: "",
    storageBag: "",
    totalAmount: "",
    description: "",
    installmentAmount: "",
    installmentDate: "",
    representative: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEmailChange = (event) => {
    setTo(event.target.value);
  };

  const handleGeneratePdf = (event) => {
    event.preventDefault();
    setButtonText("Generating PDF...");

    const element = printRef.current;

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // Top, left, bottom, right margins in inches
      filename: "contract.pdf",
      html2canvas: { scale: 2 }, // Increase scale for better quality
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("files", pdfBlob, "fee-schedule.pdf");

        files.forEach((file) => {
          formData.append("files", file);
        });

        const recipientEmails = to
          ? `${to}, it@cellsave.com, maqcadet3@gmail.com`
          : "it@cellsave.com, maqcadet3@gmail.com";
        formData.append("to", recipientEmails);
        formData.append("subject", subject || "CellSave Fee Schedule");
        formData.append("text", text || "Your Copy of the Fee Schedule");
        formData.append("html", html || "Your Copy of the Fee Schedule");

        handleSubmit(formData);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setButtonText("Error! Try Again");
      });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (formData) => {
    setButtonText("Sending...");

    try {
      const response = await axios.post(
        ApiUrl + "/api/pdfmailer/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
      setButtonText("Sent Successfully");
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Error! Try Again");
    } finally {
      setTimeout(() => {
        setButtonText("Send Contract");
      }, 3000);
    }
  };

  return (
    <>
      {" "}
      <div className="min-h-screen bg-gray-100 flex justify-center p-8 md:p-12 lg:p-14 xl:p-16">
        <div className="w-full">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold mb-8 text-gray-600 text-left">
            C. Fee Schedule
          </h1>

          <form className="space-y-6" onSubmit={handleGeneratePdf}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-left text-gray-700">
                  Client First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-left text-gray-700">
                  Client Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <label className="block text-left text-gray-700">* Email</label>
              <input
                type="email"
                name="email"
                placeholder="Client Email Address"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block text-left text-gray-700">
                * Choose your Package
              </label>
              <select
                name="package"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.package}
                onChange={handleInputChange}
              >
                <option value="">- Select -</option>
                <option value="CryoAdvance">CryoAdvance</option>
                <option value="CryoPlus">CryoPlus</option>
                <option value="CryoUltimate">CryoUltimate</option>
                <option value="CryoInfinite">CryoInfinite</option>
              </select>
            </div>

            <div className="flex items-center">
              <div className="flex-grow">
                <label className="block text-left text-gray-700">
                  * Enrollment Fee
                </label>
                <input
                  type="text"
                  name="enrollmentFee"
                  className="block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.enrollmentFee}
                  onChange={handleInputChange}
                />
              </div>
              <div className="">
                <span className="inline-block py-2 px-4 text-gray-700 bg-gray-200 rounded-md mt-6">
                  AED
                </span>
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex-grow">
                <label className="block text-left text-gray-700">
                  * Processing Fee
                </label>
                <input
                  type="text"
                  name="processingFee"
                  className="block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.processingFee}
                  onChange={handleInputChange}
                />
              </div>
              <div className="">
                <span className="inline-block py-2 px-4 text-gray-700 bg-gray-200 rounded-md mt-6">
                  AED
                </span>
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex-grow">
                <label className="block text-left text-gray-700">
                  * Storage Fee
                </label>
                <input
                  type="text"
                  name="storageFee"
                  className="block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.storageFee}
                  onChange={handleInputChange}
                />
              </div>
              <div className="">
                <span className="inline-block py-2 px-4 text-gray-700 bg-gray-200 rounded-md mt-6">
                  AED
                </span>
              </div>
            </div>

            <div>
              <label className="block text-left text-gray-700">
                * Storage Period
              </label>
              <input
                type="text"
                name="storagePeriod"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.storagePeriod}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block text-left text-gray-700">
                * Add a 5 chamber Storage Bag option - You will be charged extra
                1,000 AED?
              </label>
              <div className="mt-1 flex">
                <label className="mr-4">
                  <input
                    type="radio"
                    name="storageBag"
                    value="Yes"
                    className="mr-2"
                    checked={formData.storageBag === "Yes"}
                    onChange={handleInputChange}
                  />{" "}
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="storageBag"
                    value="No"
                    className="mr-2"
                    checked={formData.storageBag === "No"}
                    onChange={handleInputChange}
                  />{" "}
                  No
                </label>
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex-grow">
                <label className="block text-left text-gray-700">
                  * Total Amount
                </label>
                <input
                  type="text"
                  name="totalAmount"
                  className="block w-full p-2 border border-gray-300 rounded-md"
                  value={formData.totalAmount}
                  onChange={handleInputChange}
                />
              </div>
              <div className="">
                <span className="inline-block py-2 px-4 text-gray-700 bg-gray-200 rounded-md mt-6">
                  AED
                </span>
              </div>
            </div>

            <div>
              <label className="block text-left text-gray-700">
                Description
              </label>
              <input
                type="text"
                name="description"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block text-left text-gray-700">
                Installment Amount (if applicable)
              </label>
              <input
                type="text"
                name="installmentAmount"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.installmentAmount}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block text-left text-gray-700">
                Installment Starting Date
              </label>
              <input
                type="date"
                name="installmentDate"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.installmentDate}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label className="block text-left text-gray-700">
                * Cellsave Representative
              </label>
              <select
                name="representative"
                className="mt-1 mb-5 block w-full p-2 border border-gray-300 rounded-md"
                value={formData.representative}
                onChange={handleInputChange}
              >
                <option value="">- Select -</option>
                <option value="Catia Shamia">Catia Shamia</option>
                <option value="Lana Hnaidi">Lana Hnaidi</option>
                <option value="Mawada Margani">Mawada Margani</option>
                <option value="Nadya Abbas">Nadya Abbas</option>
                <option value="Rima Kobeissi">Rima Kobeissi</option>
                <option value="Salam Abu Aladas">Salam Abu Aladas</option>
                <option value="Mina Sabah">Mina Sabah</option>
                <option value="Nermeen Hassan">Nermeen Hassan</option>
                <option value="Dalia Majedi">Dalia Majedi</option>
                <option value="Nora Abueida">Nora Abueida</option>
                <option value="Samia Ghali">Samia Ghali</option>
                <option value="Tamara Aref Abu Sa`ad">
                  Tamara Aref Abu Sa`ad
                </option>
                <option value="Majd Dahanan">Majd Dahanan</option>
                <option value="Sara Slaibi">Sara Slaibi</option>
                <option value="Yumna Alakkad ">Yumna Alakkad</option>
                <option value="Rana Ibrahim">Rana Ibrahim</option>
                <option value="Ola Yousif">Ola Yousif</option>
                <option value="Nermeen Abdelrahman">Nermeen Abdelrahman</option>
                <option value="Reem Osman">Reem Osman</option>
                <option value="Amaal Al-Mimi">Amaal Al-Mimi</option>
                <option value="Rahaf Fandi">Rahaf Fandi</option>
                <option value="Remonda Magdy">Remonda Magdy</option>
                <option value="Israa Masri">Israa Masri</option>
                <option value="Amena Rahman">Amena Rahman</option>
              </select>
            </div>

            <div className="flex justify-start">
              <button
                type="submit"
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex w-full bg-gray-600 justify-center items-center pt-10 flex-col ">
        <div
          ref={printRef}
          style={{
            background: "#fff",
            width: "100%", // Use a responsive width
            maxWidth: "210mm", // Ensure it does not exceed A4 width
            minHeight: "auto", // Adjust height dynamically
            padding: "20px", // Optional: Add padding
            boxSizing: "border-box", // Include padding in width calculations
            pageBreakInside: "avoid", // Prevent breaking inside this element
            overflowWrap: "break-word", // Ensure words break correctly
            wordWrap: "break-word", // Compatibility for word wrapping
          }}
          className="contract"
        >
          <div className="text-center mb-10">
            <p className="flex items-center w-full gap-5">
              <span
                style={{ color: "#cf0072", fontSize: "30px" }}
                className="text-left flex-1 leading-10 text-xl md:text-3xl lg:text-4xl text-gray-800 "
              >
                <strong>
                  {" "}
                  C. Fee Schedule - {formData.firstName} {formData.lastName}
                </strong>
              </span>{" "}
              <img className="w-1/5" src={assets.logoAdscc} alt="" />
            </p>
          </div>

          <div className="mb-8 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              A. ENROLLMENT FEE:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              The initial deposit (the “Enrollment Fee”) is payable before the
              birth of the Child. Upon receipt of the signed Service Agreement
              by CELLSAVE, the collection kit will be sent to you. This fee
              includes the Cord Blood (and/or, if applicable, Cord Tissue/
              Placental Tissue/ Cord Vessel/ Amnion Placental Tissue) collection
              kit, account set-up, and administration fee.
            </p>
          </div>

          <div>
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              B. PROCESSING FEE AND STORAGE FEE:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              The amount of the processing and storage fee (the “Processing Fee”
              and “Storage Fee”) is dependent on the Service you have chosen,
              and is payable prior to the time the Product is collected from the
              hospital:
            </p>
          </div>

          <div className="mt-8 ">
            <table className="w-full border border-gray-300 text-left text-sm md:text-md lg:text-lg">
              <tbody>
                <tr>
                  <td colSpan={7} className="font-semibold text-pink-600 p-4">
                    CryoPlus (Cord Blood and Cord Tissue)
                  </td>
                </tr>
                <tr className="border-t border-gray-300 ">
                  <td className="p-4 pr-32">Enrollment</td>
                  <td>AED 2,000</td>
                  <td>SAR 2,000</td>
                  <td>KWD 200</td>
                  <td>QAR 2,000</td>
                  <td>OMR 200</td>
                  <td>BHD 200</td>
                </tr>
                <tr>
                  <td className="p-4">Processing</td>
                  <td>AED 5,000</td>
                  <td>SAR 5,000</td>
                  <td>KWD 500</td>
                  <td>QAR 5,000</td>
                  <td>OMR 500</td>
                  <td>BHD 500</td>
                </tr>
                <tr>
                  <td className="p-4">Storage</td>
                  <td>AED 11,500</td>
                  <td>SAR 12,000</td>
                  <td>KWD 900</td>
                  <td>QAR 12,000</td>
                  <td>OMR 1,200</td>
                  <td>BHD 1,200</td>
                </tr>
                <tr className="font-semibold">
                  <td className="p-4" style={{ height: "80px" }}>
                    TOTAL
                  </td>
                  <td>AED 18,500</td>
                  <td>SAR 19,000</td>
                  <td>KWD 1,600</td>
                  <td>QAR 19,000</td>
                  <td>OMR 1,900</td>
                  <td>BHD 1,900</td>
                </tr>

                <tr className="border-t border-gray-300 ">
                  <td colSpan={7} className="font-semibold text-pink-600 p-4">
                    CryoAdvanced (Cord Blood, Cord Tissue and Placental Tissue)
                  </td>
                </tr>
                <tr className="border-t border-gray-300 ">
                  <td className="p-4 ">Enrollment</td>
                  <td>AED 2,000</td>
                  <td>SAR 2,000</td>
                  <td>KWD 200</td>
                  <td>QAR 2,000</td>
                  <td>OMR 200</td>
                  <td>BHD 200</td>
                </tr>
                <tr>
                  <td className="p-4">Processing</td>
                  <td>AED 6,000</td>
                  <td>SAR 6,000</td>
                  <td>KWD 600</td>
                  <td>QAR 6,000</td>
                  <td>OMR 600</td>
                  <td>BHD 600</td>
                </tr>
                <tr className="page-break">
                  <td className="p-4">Storage</td>
                  <td>AED 13,500</td>
                  <td>SAR 14,000</td>
                  <td>KWD 1,000</td>
                  <td>QAR 14,000</td>
                  <td>OMR 1,400</td>
                  <td>BHD 1,400</td>
                </tr>
                <tr className="font-semibold ">
                  <td className="p-4" style={{ height: "80px" }}>
                    TOTAL
                  </td>
                  <td>AED 21,500</td>
                  <td>SAR 22,000</td>
                  <td>KWD 1,800</td>
                  <td>QAR 22,000</td>
                  <td>OMR 2,200</td>
                  <td>BHD 2,200</td>
                </tr>

                <tr className="border-t border-gray-300">
                  <td colSpan={7} className="font-semibold text-pink-600 p-4">
                    CryoUltimate (Cord Blood, Cord Tissue, Placental Tissue,
                    Amnion Placental Tissue, and Cord Vessel)
                  </td>
                </tr>
                <tr className="border-t border-gray-300">
                  <td className="p-4">Enrollment</td>
                  <td>AED 2,000</td>
                  <td>SAR 2,000</td>
                  <td>KWD 200</td>
                  <td>QAR 2,000</td>
                  <td>OMR 200</td>
                  <td>BHD 200</td>
                </tr>
                <tr>
                  <td className="p-4">Processing</td>
                  <td>AED 7,000</td>
                  <td>SAR 7,000</td>
                  <td>KWD 700</td>
                  <td>QAR 7,000</td>
                  <td>OMR 700</td>
                  <td>BHD 700</td>
                </tr>
                <tr>
                  <td className="p-4">Storage</td>
                  <td>AED 17,500</td>
                  <td>SAR 18,000</td>
                  <td>KWD 1,400</td>
                  <td>QAR 18,000</td>
                  <td>OMR 1,800</td>
                  <td>BHD 1,800</td>
                </tr>
                <tr className="font-semibold">
                  <td className="p-4" style={{ height: "80px" }}>
                    TOTAL
                  </td>
                  <td>AED 26,500</td>
                  <td>SAR 27,000</td>
                  <td>KWD 2,300</td>
                  <td>QAR 27,000</td>
                  <td>OMR 2,700</td>
                  <td>BHD 2,700</td>
                </tr>

                <tr className="border-t border-gray-300">
                  <td colSpan={7} className="font-semibold text-pink-600 p-4">
                    CryoInfinite (Cord Blood, Cord Tissue, Placental Tissue,
                    Amnion Placental Tissue, and Cord Vessel)
                  </td>
                </tr>
                <tr className="border-t border-gray-300">
                  <td className="p-4">Enrollment</td>
                  <td>AED 2,000</td>
                  <td>SAR 2,000</td>
                  <td>KWD 200</td>
                  <td>QAR 2,000</td>
                  <td>OMR 200</td>
                  <td>BHD 200</td>
                </tr>
                <tr>
                  <td className="p-4">Processing</td>
                  <td>AED 12,000</td>
                  <td>SAR 12,000</td>
                  <td>KWD 1,200</td>
                  <td>QAR 12,000</td>
                  <td>OMR 1,200</td>
                  <td>BHD 1,200</td>
                </tr>
                <tr>
                  <td className="p-4">Storage</td>
                  <td>AED 23,500</td>
                  <td>SAR 24,000</td>
                  <td>KWD 2,000</td>
                  <td>QAR 24,000</td>
                  <td>OMR 2,400</td>
                  <td>BHD 2,400</td>
                </tr>
                <tr className="font-semibold">
                  <td className="p-4" style={{ height: "80px" }}>
                    TOTAL
                  </td>
                  <td>AED 37,500</td>
                  <td>SAR 38,000</td>
                  <td>KWD 3,400</td>
                  <td>QAR 38,000</td>
                  <td>OMR 3,800</td>
                  <td>BHD 3,800</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className=" ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              FEE SCHEDULE
            </h3>
            <div className="mt-4 pb-8">
              <table className="w-full text-left text-sm md:text-md lg:text-lg">
                <tbody>
                  <tr>
                    <td className="font-semibold">Service</td>
                    <td>{formData.package}</td>
                  </tr>
                  <tr className="">
                    <td className="font-semibold ">Enrollment Fee</td>
                    <td>{formData.enrollmentFee}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Processing</td>
                    <td>{formData.processingFee}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Storage</td>
                    <td>{formData.storageFee}</td>
                  </tr>
                  <tr className="page-break">
                    <td className="font-semibold ">Storage Period</td>
                    <td>{formData.storagePeriod}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">
                      Add a 5 chamber Storage Bag option - You will be charged
                      extra 1,000 AED?
                    </td>
                    <td>{formData.storageBag}</td>
                  </tr>
                  <tr className="">
                    <td className="font-semibold ">Total Amount</td>
                    <td>{formData.totalAmount}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Description</td>
                    <td>{formData.description}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">
                      Installment Amount (if applicable)
                    </td>
                    <td> {formData.installmentAmount}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">Installment Starting Date</td>
                    <td>{formData.installmentDate}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="mt-8 pt-8 text-sm md:text-md lg:text-lg text-left ">
              The Processing Fee includes, processing, and testing. It also
              includes the maternal blood tests.
            </p>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              The Storage Fee includes storage during the Initial Storage
              Period.
            </p>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              This Service Agreement may be terminated by written notice with
              immediate effect by CELLSAVE if CELLSAVE does not receive the
              Enrollment, Processing, or Storage Fee within the specified time.
            </p>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              Termination of this Service Agreement will not affect the Client’s
              responsibility for payment in full of all amounts invoiced and of
              all additional administrative and legal costs related to
              collecting the outstanding amounts under this Service Agreement.
            </p>
          </div>

          <div className="mt-8 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              C. ADDITIONAL FEES:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              Additional fees may be charged if additional testing is required;
              e.g. because of abnormal laboratory test results.{" "}
            </p>
          </div>

          <div className="mt-8 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              D. REFUNDS:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              The Enrollment Fee is a non-refundable down payment and should
              accompany the signed Client Service Agreement.
            </p>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left page-break">
              In the event that Specimen has been collected, received, and
              processed, yet CELLSAVE rejects the sample due to its
              non-compliance with regulatory standards, no storage will take
              place and CELLSAVE will refund the Storage Fee to the Client. The
              Processing Fee is not refundable if the Cord Blood (and/or, if
              applicable, Cord Tissue/Placental Tissue/Amnion Placental
              Tissue/Cord Vessel) Specimen has been processed and tested.
            </p>
          </div>

          <div className="mt-8">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              E. PAYMENT OPTIONS:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              Please check option(s) below for payments of the Fees as outlined
              in the Fee Schedule:
            </p>
            <div className="mt-4">
              <div className="flex items-center text-left">
                <input type="radio" name="payment_option" className="mr-2" />
                <label>Credit / Debit Card</label>
              </div>
              <div className="flex items-center text-left mt-2">
                <input type="radio" name="payment_option" className="mr-2" />
                <label>Bank Transfer</label>
              </div>
              <div className="flex items-center text-left mt-2">
                <input type="radio" name="payment_option" className="mr-2" />
                <label>Cheque</label>
              </div>
              <div className="flex items-center text-left mt-2">
                <input type="radio" name="payment_option" className="mr-2" />
                <label>Cash</label>
              </div>
            </div>
          </div>

          <div className="mt-8 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              Credit / Debit Card:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              Major credit cards are accepted. Please contact the CELLSAVE
              Office for more details.
            </p>
          </div>

          <div className="mt-4 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              Bank Transfer:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              (Important: Please mention your Client ID and name when making a
              direct bank transfer.) You can directly transfer the fees to
              Account Name: CELLSAVE ARABIA FZ-L.L.C., Bank Name: First Abu
              Dhabi Bank. Account Number: 1611323041175013, IBAN:
              AE540351611323041175013, Swift code: NBAD AE AA SZR, Branch:
              Shaikh Zayed Road Branch, Address: Shaikh Zayed Road, Dubai
            </p>
          </div>

          <div className="mt-4 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              Cheque:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              You can pay by cheque to CELLSAVE ARABIA FZ-L.L.C. or your
              country’s Authorized CELLSAVE Medical Representative.
            </p>
          </div>

          <div className="mt-4 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              Cash:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              Cash payment can be made at CELLSAVE Office or to your country’s
              Authorized CELLSAVE Medical Representative.
            </p>
          </div>

          <p className="mt-4  text-sm md:text-md lg:text-lg text-left ">
            All payments must be received and cleared at the time specified in
            the Fee Schedule. In the event of failure to meet this requirement,
            even in relation to a single invoice, the Client shall be obliged to
            pay the entire outstanding balance due in one single payment. Should
            the Client fail to do so, the Service Agreement may be terminated by
            CELLSAVE in accordance with section 6 of the General Terms and
            conditions. The storage certificate will only be released after full
            payment has been received and cleared.
          </p>

          <div className="mt-4 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              For installment plan please check the options below.
            </h3>
            <div className="mt-4">
              <div className="flex items-center text-left">
                <input
                  type="radio"
                  name="installment_option"
                  className="mr-2"
                />
                <label>Credit / Debit Card</label>
              </div>
              <div className="flex items-center text-left mt-2">
                <input
                  type="radio"
                  name="installment_option"
                  className="mr-2"
                />
                <label>Cheque</label>
              </div>
            </div>
          </div>

          <p className="mt-4  text-sm md:text-md lg:text-lg text-left ">
            Installment Plan Disclosures for any monthly installment plans - The
            plan requires a 95 AED one-time installment payment fee. This fee
            will be applied on the first installment payment transaction.
          </p>

          <div className="mt-4 ">
            <h3 className="text-md md:text-lg lg:text-xl text-left font-bold text-pink-600">
              Overdraft Fee:
            </h3>
            <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
              The client will be responsible for an additional 75 AED per month
              for every month over 30 days calendar days that there is a past
              balance due.
            </p>
          </div>

          <div className="mt-8 ">
            <div className="flex justify-between items-center">
              <div>
                <p className="mt-4 text-sm md:text-md lg:text-lg text-left ">
                  Cellsave Representative: {formData.representative}
                </p>
                <p className="mt-2 mb-5 text-xs text-gray-500 text-left">
                  Code: A.4.06-3A Fee Schedule-CSA
                </p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="mt-4 text-sm md:text-md lg:text-lg text-left ">
                SIGNATURE OF PARENT/LEGAL GUARDIAN
              </h3>
              <p className="mt-6 text-sm md:text-md lg:text-lg text-left font-semibold">
                * THIS PLAN DISCLOSURE IS VALID FOR 10 DAYS
              </p>
            </div>

            <div className="mt-12 flex items-center">
              <p className="italic text-lg text-gray-500">
                <span className="text-2xl">x</span> _ _ _ _ _ _ _ _ _ _ _
              </p>
            </div>
          </div>

          <div className="  bg-white mt-14">
            <div className="text-center mb-8">
              <h1 className="text-md md:text-lg lg:text-xl text-left font-bold">
                Signature Certificate
              </h1>
              <p className="mt-4 text-xs md:text-sm lg:text-md text-left">
                Document name: C. Fee Schedule - {formData.firstName}
                {formData.lastName}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeeSchedule;
