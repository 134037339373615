import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutSection = ({ data }) => {
  const [beforeDelimiter, afterDelimiter] =
    data.data.attributes.Reason_Section_Heading.split("||");
  const [isVisible, setIsVisible] = useState(false);
  const [isbutton, setIsButton] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  // useLayoutEffect(() => {
  //   const ctx = gsap.context(() => {
  //     const boxes = gsap.utils.toArray(".box");
  //     boxes.forEach((box) => {
  //       gsap.fromTo(
  //         box,
  //         { y: 300, opacity: 0 },
  //         {
  //           y: 0,
  //           opacity: 1,
  //           scrollTrigger: {
  //             trigger: box,
  //             start: "bottom 113%",
  //             end: "top 130%",
  //             scrub: 1,
  //             // markers: true,
  //           },
  //         }
  //       );
  //     });
  //   }, main);

  //   return () => ctx.revert();
  // }, []);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        ".box99",
        { y: 200, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: ".box99",
            start: "top 80%", // Start animation when the top of the element reaches 80% of the viewport
            end: "top 50%", // End animation when the top of the element reaches 30% of the viewport
            scrub: 2, // Smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // markers: true,
          },
        }
      );
    });
    return () => ctx.revert(); // Clean up the animation on unmount
  }, []);

  return (
    <ParentContainer
      containerStyle={`flex justify-center flex-wrap gap-5 py-20 ${
        isVisible ? "animate__animated animate__fadeInUp" : ""
      }`}
    >
      <div ref={main}>
        <div className="w-full flex justify-center flex-wrap gap-5 box99">
          <Button
            Linkto={"/stem-cells-sceince"}
            titleof={data.data.attributes.Reason_Section_Button_Text}
            CustomStyle={" max-md:order-1 "}
          />
          <h2 className="text-5xl font-bold text-center   w-1/2 max-md:w-full mx-5 max-md:mx-0 max-md:text-2xl animate__ani ">
            {beforeDelimiter}
            <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
              {afterDelimiter}
            </span>
          </h2>
          {/* <p className="w-1/2 text-center text-xl max-md:w-full max-md:text-sm ">
            Preserve your newborn's future by saving stem cells from cord
            blood,`` cord tissue, and placental tissue—a crucial choice for
            potential family treatments in the future.
          </p> */}
        </div>
      </div>
    </ParentContainer>
  );
};

export default AboutSection;
