import { ApiUrl } from "./ApiUrl";

const searchIndex = [];

function flattenAttributes(attributes) {
  let result = [];

  function recurse(obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "string") {
        result.push(value);
      } else if (typeof value === "object" && value !== null) {
        if (value.data && value.data.attributes) {
          recurse(value.data.attributes);
        } else {
          recurse(value);
        }
      }
    }
  }

  recurse(attributes);
  return result;
}

async function fetchData(apiUrl, pageUrl, name) {
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();

    console.log(`Fetched data from ${apiUrl}:`, data); // Log entire response

    // Check if attributes exist
    if (!data.data || !data.data.attributes) {
      console.error(`No attributes found for ${apiUrl}`);
      return;
    }

    // Flatten the data
    const content = flattenAttributes(data.data.attributes);

    searchIndex.push({ name: name, url: pageUrl, content: content.join(" ") });
    console.log(`Processed data from ${apiUrl}:`, content);
  } catch (error) {
    console.error(`Error fetching data from ${apiUrl}:`, error);
  }
}
const pages = [
  {
    api:
      "/api/home-page?populate=Hero_Section_Background_Video,Partners,Partners.Image_One,Partners.Image_Two,Partners.Image_Three,Partners.Image_Four,Partners.Image_Five,CryoAdvanced,CryoUltimate,CryoInfinite,Regen_Med_Image,About_Section_Image_One,Safe_Section_Image_One,Safe_Section_Image_Two,Safe_Section_Image_Three,Featured.Image_One,Featured.Image_Two,Featured.Image_Three,Featured.Image_Four,Featured.Image_Five",
    url: "/",
    name: "Home",
  },
  {
    api:
      "/api/about?populate=Hero_Section,Hero_Section.Video,Timeline,Why_Select_Image,Faqs,Translpant_Numbers,CellSave_Licenses,CellSave_Licenses.Image_One,CellSave_Licenses.Image_Two,CellSave_Licenses.Image_Three,CellSave_Licenses.Image_Four,CellSave_Licenses.Image_Five",
    url: "/about",
    name: "About",
  },
  {
    api:
      "/api/stem-cell?populate=Hero_Section,Hero_Section.Video,Impact_Med_Image_One,Impact_Med_Image_Two,Impact_Med_Image_Three,Translpant_Meds_Tab_Content_One,Translpant_Meds_Tab_Content_Two,Translpant_Meds_Tab_Content_Three,Translpant_Meds_Tab_Content_Four,Reg_Med_Image,Reg_Med_Faqs,How_Work_Slider,How_Work_Slider.Image,FAQs",
    url: "/stem-cells-sceince",
    name: "Stem Cell Science",
  },
  {
    api:
      "/api/regenerative-medicine?populate=Hero_Section,Hero_Section.Video,Uses_Image,Uses_List,Why_Valuable,Why_Valuable.Points,Power_Image,Why_CryoInfinite,Why_CryoInfinite.Points,Cell_Bank_Image,Steps_Step",
    url: "/regenerative-medicine",
    name: "Regenrative Medicine",
  },
  {
    api:
      "/api/sources-of-stem-cell?populate=Hero_Section,Hero_Section.Video,Faqs,Faqs.Points,Transformative_Image",
    url: "/sources-of-stem-cell",
    name: "Sources of Stem Cell",
  },
  {
    api:
      "/api/cord-blood?populate=Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points",
    url: "/cord-blood",
    name: "Cord Blood",
  },
  {
    api:
      "/api/stem-cell?populate=Hero_Section,Hero_Section.Video,Impact_Med_Image_One,Impact_Med_Image_Two,Impact_Med_Image_Three,Translpant_Meds_Tab_Content_One,Translpant_Meds_Tab_Content_Two,Translpant_Meds_Tab_Content_Three,Translpant_Meds_Tab_Content_Four,Reg_Med_Image,Reg_Med_Faqs,How_Work_Slider,How_Work_Slider.Image,FAQs",
    url: "/cord-tissue",
    name: "Cord Tissue",
  },
  {
    api:
      "/api/placental-tissue?populate=Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points",
    url: "/placenta-tissue",
    name: "Placenta Tissue",
  },
  {
    api:
      "/api/cord-vessel?populate=Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points",
    url: "/cord-vessel",
    name: "Cord Vessel",
  },
  {
    api:
      "/api/amnion-tissue?populate=Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points",
    url: "/amnion-tissue",
    name: "Amnion Tissue",
  },
  {
    api:
      "/api/home-page?populate=Hero_Section_Background_Video,Partners,Partners.Image_One,Partners.Image_Two,Partners.Image_Three,Partners.Image_Four,Partners.Image_Five,CryoAdvanced,CryoUltimate,CryoInfinite,Regen_Med_Image,About_Section_Image_One,Safe_Section_Image_One,Safe_Section_Image_Two,Safe_Section_Image_Three,Featured.Image_One,Featured.Image_Two,Featured.Image_Three,Featured.Image_Four,Featured.Image_Five",
    url: "/service-pricing",
    name: "Service Pricing",
  },

  {
    api: "/api/contact",
    url: "/contact",
    name: "Contact",
  },
  // Add more pages as needed
];

export const buildSearchIndex = async () => {
  await Promise.all(
    pages.map((page) => fetchData(ApiUrl + page.api, page.url, page.name))
  );
  console.log("Search index:", searchIndex);
  return searchIndex;
};
