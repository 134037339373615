import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StemCellBanking = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <ParentContainer
      containerCSS={{
        backgroundImage: `url(${assets.stemcellbanking})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      containerStyle={"overflow-hidden"}
    >
      <div className={`w-full `} ref={main}>
        <div className="w-full pb-5 cricle">
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!w-full !px-0 !justify-start circle"}
            white={true}
          />
        </div>
        <h2 className=" text-4xl text-white font-bold pb-5 circle  max-md:text-2xl">
          {data.Banking_Heading}{" "}
        </h2>
        <p className="text-base text-white w-1/2 circle  max-lg:w-full max-md:text-sm">
          {data.Banking_Para}
        </p>
      </div>
    </ParentContainer>
  );
};

export default StemCellBanking;
