import React from "react";
import ParentContainer from "../../components/ParentContainer";

const HeroSection = ({ data }) => {
  const [beforeDelimiter, afterDelimiter] = data.Heading.split("||");

  return (
    <ParentContainer containerStyle={"max-md:!pb-5"}>
      <h1 className="text-4xl  font-bold pb-5 max-md:text-2xl">
        {beforeDelimiter}
        <span className=" text-custom-primary ">{afterDelimiter}</span>
      </h1>
      <p className="text-base max-md:text-sm">{data.Para}</p>
    </ParentContainer>
  );
};

export default HeroSection;
