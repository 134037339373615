import React from "react";
import assets from "../assets/assets";

const ProfleEmpty = () => {
  return (
    <div className="flex flex-wrap p-8 bg-white max-lg:px-0">
      <div className=" border rounded-lg  ">
        <img src={assets.emptySection} className="w-full" alt="" />
      </div>
    </div>
  );
};

export default ProfleEmpty;
