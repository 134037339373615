import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../../components/Button";
gsap.registerPlugin(ScrollTrigger);

const Safe = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();

  const observerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= 0.1) {
          observerRef.current = true;
          observer.disconnect();
          initializeScrollTrigger();
        }
      },
      {
        threshold: [0.1],
      }
    );

    observer.observe(main.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const initializeScrollTrigger = () => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 100, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: 'top 70%',
              end: 'top 40%',
              scrub: 2,
              //markers: true,
            },
          }
        );
      });
    }, main);

    return () => ctx.revert();
  };
  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={
          "flex justify-center flex-wrap gap-5 py-40 max-md:!py-14"
        }
      >
        <Button
          Linkto={"stem-cells-sceince"}
          titleof={data.Safe_Section_Button_Text}
          CustomStyle={"!justify-center !px-0 box"}
        />

        <h2 className="text-5xl font-bold text-center  w-2/3 mx-5 max-md:w-full box max-md:text-2xl">
          {data.Safe_Section_Heading}
        </h2>
        <p className="w-1/2 text-center text-xl opacity-80 max-md:w-full box max-md:text-base">
          {data.Safe_Section_Para}
        </p>
      </ParentContainer>
    </div>
  );
};

export default Safe;
