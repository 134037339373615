import React, { useRef, useState } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ApiUrl } from "../../../components/ApiUrl";
import assets from "../../../assets/assets";

const ContractPDF = () => {
  const printRef = useRef();
  const [files, setFiles] = useState([]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");
  const [buttonText, setButtonText] = useState("Send Contract");

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };

  const handleGeneratePdf = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setButtonText("Generating PDF..."); // Update button text

    const element = printRef.current;

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // Top, left, bottom, right margins in inches
      filename: "contract.pdf",
      html2canvas: { scale: 2 }, // Increase scale for better quality
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("files", pdfBlob, "contract.pdf");

        files.forEach((file) => {
          formData.append("files", file);
        });

        const recipientEmails = to
          ? `${to}, it@cellsave.com, maqcadet3@gmail.com`
          : "it@cellsave.com, maqcadet3@gmail.com";
        formData.append("to", recipientEmails);
        formData.append("subject", subject || "CellSave Contract");
        formData.append("text", text || "Your Copy of the Contract");
        formData.append("html", html || "Your Copy of the Contract");

        handleSubmit(formData);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setButtonText("Error! Try Again"); // Update button text in case of error
      });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (formData) => {
    setButtonText("Sending..."); // Update button text

    try {
      const response = await axios.post(
        ApiUrl + "/api/pdfmailer/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
      setButtonText("Sent Successfully"); // Update button text on success
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Error! Try Again"); // Update button text in case of error
    } finally {
      setTimeout(() => {
        setButtonText("Send Contract"); // Reset button text after a delay
      }, 3000);
    }
  };

  return (
    <div className="flex w-full bg-gray-600 justify-center items-center pt-10 flex-col">
      <form onSubmit={handleGeneratePdf}>
        <div
          ref={printRef}
          style={{
            background: "#fff",
            width: "100%", // Use a responsive width
            maxWidth: "210mm", // Ensure it does not exceed A4 width
            minHeight: "297mm",
            padding: "20px", // Optional: Add padding
            boxSizing: "border-box", // Include padding in width calculations
            pageBreakInside: "avoid", // Prevent breaking inside this element
            overflowWrap: "break-word", // Ensure words break correctly
            wordWrap: "break-word", // Compatibility for word wrapping
          }}
          className="contract "
        >
          <p className="flex items-center w-full gap-5">
            <span
              style={{ color: "#cf0072", fontSize: "30px" }}
              className="text-left flex-1 leading-10 text-xl md:text-3xl lg:text-4xl text-gray-800 "
            >
              <strong>SERVICE AGREEMENT</strong>
            </span>{" "}
            <img className="w-1/5" src={assets.logoAdscc} alt="" />
          </p>

          <p>
            This CELLSAVE Stem Cell Storage Agreement is a Contract that has
            been developed between “ CELLSAVE ” and the “Client” which sets out
            the General Terms and Conditions by which CELLSAVE and the Client
            will cover all aspects of the Parties agreement.
          </p>
          <p>
            This is a very important document, which the Client should read
            through carefully.
          </p>
          <p>
            The Client has to complete the Contract as described below and
            return it to us. A copy will be emailed to the Client for their own
            reference/record.
          </p>
          <p>This document consists of the following sections:</p>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>A. SERVICE AGREEMENT</strong>
            </span>{" "}
            <br />
            <span style={{ color: "#cf0072" }}>
              <strong>B. INFORMED CONSENT</strong>
            </span>{" "}
            <br />
            <span style={{ color: "#cf0099" }}>
              <strong>
                <span style={{ color: "#cf0072" }}>C. FEE SCHEDULE</span>
              </strong>
            </span>
          </p>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>SERVICE AGREEMENT</strong>
            </span>
          </p>
          <ul>
            <li>
              As per Association for the Advancement of Blood &amp; Biotherapies
              (AABB) and International Standards, this form should be signed
              before the birth of your baby.
            </li>
            <li>
              Complete all parts of this form. This includes your full details,
              clinic/hospital, physician/ midwife, delivery due date, details
              for multiple births (if applicable), and loyalty reduction for
              returning Clients (if applicable).
            </li>
            <li>
              It is important that we have the Clients’ full names as per the
              passport.
            </li>
          </ul>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>INFORMED CONSENT</strong>
            </span>
          </p>
          <p>
            <ul>
              <li>
                As per AABB and International Standards, this form should be
                signed before the birth of your baby.
              </li>
              <li>Complete all parts of this form.</li>
              <li>
                This form gives us the consent for Cord Blood, Cord Tissue,
                Placental Tissue, Amnion Placental Tissue, and Cord Vessel
                collection.
              </li>
              <li>
                It is important that we have the Clients’ full names as per the
                passport.
              </li>
            </ul>
          </p>
          <p>
            <strong>
              <span style={{ color: "#cf0072" }}>FEE SCHEDULE</span>
            </strong>
          </p>
          <p>
            <ul>
              <li>
                This form provides the summary of fees payable, and will be in a
                separate page from this document.
              </li>
            </ul>
          </p>
          <p>
            <span style={{ color: "#8884d5" }}>
              <strong>
                <u>A. SERVICE</u>
              </strong>{" "}
              <strong>
                <u>AGREEMENT</u>
              </strong>
            </span>
          </p>
          <p>
            For the processing, testing, cryopreservation, and storage{" "}
            <span style={{ fontSize: "inherit" }}>
              of Cord Blood Stem Cells and/or (if applicable){" "}
            </span>
            Cord Tissue/Placental Tissue/Amnion Placental Tissue/ Cord Vessel,
            between
          </p>
          <p>
            CELLSAVE ARABIA FZ- LLC, a company with limited liability registered
            under the Dubai Healthcare City Authority (DHCCA), Organised and
            existing and operating under the laws of the United Arab Emirates;
          </p>
          <p>
            Having its registered office at P.O. Box 505152, Dubai, United Arab
            Emirates;
          </p>
          <p>
            Tel. +97143604410, E-mail Address:{" "}
            <a href="mailto:infoarabia@cellsave.com">infoarabia@cellsave.com</a>{" "}
            <br /> (Hereinafter referred to as {"  "}“CELLSAVE ”).
          </p>

          <p>And</p>
          <p>
            The Parent(s)/Legal Guardian(s) listed below on their behalf and the
            behalf of the (unborn) Child
          </p>
          <p>
            (Hereinafter jointly to be referred to as the “Client” or the
            “Clients”) ( CELLSAVE and the Client, together, the “Parties”)
          </p>
          <p>
            <strong>(Client to fill in the relevant fields below)</strong>
          </p>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>MOTHER/LEGAL GUARDIAN</strong>
            </span>
          </p>
          <p>
            <strong>Last Maiden Name</strong>:
          </p>
          <p>
            <input type="text" name="mother-last-name" required1 />
          </p>
          <p>
            <strong>First Name</strong>:
          </p>
          <p>
            <input type="text" name="mother-first-name" required1 />
          </p>
          <p>
            <strong>Address</strong>:
          </p>
          <p>
            <input type="text" name="mother-address" required1 />
          </p>
          <p>
            <strong>Country</strong>:
          </p>
          <p>
            {" "}
            <select name="mother-country" required1>
              {/* Insert country options here */}
              <option value="UAE">UAE</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Egypt">Egypt</option>
              {/* Continue listing all other countries */}
            </select>
          </p>
          <div className="page-break-aviod">
            <p>
              <strong>City</strong>:
            </p>
            <p className="page-break-aviod">
              <input type="text" name="mother-city" required1 />
            </p>
          </div>
          <p>
            <strong>Postal Code</strong>:
          </p>
          <p>
            <input type="mother-postal-code" name="postal-code" />
          </p>
          <p>
            <strong>Date of Birth</strong>:
          </p>
          <p>
            {" "}
            <input type="date" name="mother-dob-day" id="" />
          </p>
          <p>
            <strong>Telephone</strong>:
          </p>
          <p>
            <input type="text" name="mother-telephone" />
          </p>
          <p>
            <p>
              <strong>Mobile</strong>:
            </p>
            <p>
              <input type="text" name="mother-mobile" required1 />
            </p>
          </p>
          <p>
            <strong>Email Address</strong>:
          </p>
          <p>
            <input type="email" name="mother-email" required1 />
          </p>
          <p>
            <label>Mother Passport/EID:</label>
          </p>
          <p>
            <input
              type="file"
              name="mother-passport"
              required1
              onChange={handleFileChange}
            />{" "}
          </p>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>FATHER/LEGAL GUARDIAN</strong>
            </span>
          </p>
          <p>
            <strong>Last Name</strong>:
          </p>
          <p>
            <input type="text" name="father-last-name" required1 />
          </p>
          <p>
            <strong>First Name</strong>:
          </p>
          <p>
            <input type="text" name="father-first-name" required1 />
          </p>
          <p>
            <strong>Address</strong>:
          </p>
          <p>
            {" "}
            <input type="text" name="father-address" required1 />
          </p>
          <p>
            <strong>Country</strong>:
          </p>
          <p>
            <select name="father-country" required1>
              {/* Insert country options here */}
              <option value="UAE">UAE</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Egypt">Egypt</option>
              {/* Continue listing all other countries */}
            </select>
          </p>
          <p>
            <strong>City</strong>:
          </p>
          <p>
            {" "}
            <input type="text" name="father-city" required1 />
          </p>
          <p>
            <strong>Postal Code</strong>:
          </p>
          <p>
            {" "}
            <input type="text" name="father-postal-code" />
          </p>
          <p>
            <strong>Date of Birth</strong>:
          </p>
          <p>
            <input type="date" name="father-dob-day" id="" />
          </p>
          <p>
            <strong>Telephone</strong>:
          </p>
          <p>
            <input type="text" name="father-telephone" />
          </p>
          <p className="page-break-aviod">
            <p>
              <strong>Mobile</strong>:
            </p>
            <p>
              <input type="text" name="father-mobile" required1 />
            </p>
          </p>
          <p>
            <strong>Email Address</strong>:
          </p>
          <p>
            <input type="email" name="father-email" required1 />
          </p>
          <p>
            <label>Father Passport/EID:</label>
          </p>
          <p>
            <input
              type="file"
              name="father-passport"
              required1
              onChange={handleFileChange}
            />{" "}
          </p>
          <div className="page-break-aviod">
            <p>
              <strong>
                <span style={{ color: "#cf0072" }}>CLINIC/HOSPITAL</span>
                <br />
              </strong>
            </p>
            <p>
              <strong>Name</strong>:
            </p>
            <p>
              <input type="text" name="clinic-name" required1 />
            </p>
          </div>
          <p>
            <strong>Country</strong>:
          </p>
          <p>
            <select name="clinic-country" required>
              <option value="UAE">UAE</option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Brazil">Brazil</option>
              <option value="Brunei">Brunei</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cabo Verde">Cabo Verde</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo, Democratic Republic of the">
                Congo, Democratic Republic of the
              </option>
              <option value="Congo, Republic of the">
                Congo, Republic of the
              </option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="East Timor">East Timor</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Eswatini">Eswatini</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Greece">Greece</option>
              <option value="Grenada">Grenada</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-Bissau">Guinea-Bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Honduras">Honduras</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran">Iran</option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Laos">Laos</option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libya">Libya</option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia">Micronesia</option>
              <option value="Moldova">Moldova</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="North Korea">North Korea</option>
              <option value="North Macedonia">North Macedonia</option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Qatar">Qatar</option>
              <option value="Romania">Romania</option>
              <option value="Russia">Russia</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Vincent and the Grenadines">
                Saint Vincent and the Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Korea">South Korea</option>
              <option value="South Sudan">South Sudan</option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syria">Syria</option>
              <option value="Taiwan">Taiwan</option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania">Tanzania</option>
              <option value="Thailand">Thailand</option>
              <option value="Togo">Togo</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Vatican City">Vatican City</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Vietnam">Vietnam</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </p>
          <p>
            <strong>City</strong>:
          </p>
          <p>
            <input type="text" name="clinic-city" required1 />
          </p>
          <p>
            <strong>
              <span style={{ color: "#cf0072" }}>PHYSICIAN</span>
              <br />
            </strong>
          </p>
          <p>
            <strong>Last Name</strong>:
          </p>
          <p>
            <input type="text" name="physician-last-name" required1 />
          </p>
          <p>
            <strong>First Name</strong>:
          </p>
          <p>
            <input type="text" name="physician-first-name" required1 />
          </p>
          <p>
            <strong>Country</strong>:
          </p>
          <p>
            <select name="physician-country" required1>
              {/* Insert country options here */}
              <option value="UAE">UAE</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Egypt">Egypt</option>
              {/* Continue listing other countries */}
            </select>
          </p>
          <p className="page-break-aviod">
            <p>
              <strong>City</strong>:
            </p>
            <p>
              <input type="text" name="physician-city" required1 />
            </p>
          </p>
          <p className="page-break-aviod">
            <p>
              <strong>Due Date for Delivery</strong>:
            </p>
            <p>
              <input type="date" name="dueDate" id="" />
            </p>
          </p>
          <p>
            <label>Multiple Births Expected:</label>
          </p>
          <p>
            <select name="multiple-births" required1>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </p>
          <p>If Yes, how many?</p>
          <p>
            <input type="number" name="how-many" />
          </p>
          <p>
            <label>
              I (We) have previously-stored cord blood with CELLSAVE:
            </label>
          </p>
          <p>
            <select name="previous-storage" required1>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </p>
          <p>Reference of the Previous Child:</p>
          <p>
            <input type="text" name="previous-child-reference" />
          </p>
          <p>
            <span style={{ color: "#cf0072" }}>
              <strong>Service choice:</strong>
            </span>
          </p>
          <p>
            <strong>
              I (We), the undersigned Parent(s)/ and/or Legal Guardian(s) hereby
              confirm the following specific service from CELLSAVE:
            </strong>
          </p>
          <p>
            <label>
              <select name="service-choice" required1>
                <option value="CryoPlus">CryoPlus</option>
                <option value="CryoAdvanced">CryoAdvanced</option>
                <option value="CryoUltimate">CryoUltimate</option>
                <option value="CryoInfinite">CryoInfinite</option>
              </select>
            </label>
          </p>
          <p>
            Add a 5 chamber Storage Bag option -
            <strong>
              <span style={{ color: "#cf0072" }}>
                You will be charged extra 1,000 AED?
              </span>
            </strong>
          </p>
          <p>
            {" "}
            <select name="extra-storage-bag" required1>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </p>
          <p>
            <strong>
              <span style={{ color: "#cf0072" }}>KIT ID:</span>
            </strong>
          </p>
          <p>
            <input type="text" name="kit-id" required1 />
          </p>
          <p>&nbsp;</p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#8884d5" }}>
              <strong>
                <u>GENERAL</u>
              </strong>{" "}
              <strong>
                <u>TERMS AND CONDITIONS</u>
              </strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <strong>
              <span style={{ color: "#cf0072" }}>1. PURPOSE:</span>
            </strong>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The purpose of this document is to set out the terms, conditions,
            limitation, risks, and costs of the processing, testing,
            cryopreservation, and storage in an authorized cellular processing
            and storage unit release of the Product (as defined below) obtained
            from the Specimen (as defined below) collected at the time of birth
            of the Child (as defined below) of the Client.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            In this Service Agreement, the following definitions shall have the
            following meaning:
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Amnion Placental Tissue”</em>   - the thin and elastic membrane
            lining inside of the placental sac.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Child”</em> - “Any child identified by the Client in the
            Informed Consent in Section B;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>"Blood Cord”</em> - Blood collected from the umbilical cord and
            placenta of the Child following delivery;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Cord Tissue”</em> - Tissue taken from the umbilical cord of the
            Child following delivery;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Cord Vessel”</em> - Cell types that lines the Cord Tissue taken
            from the Child following delivery;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Enrollment Fee”</em> - The meaning ascribed to it in the Fee
            Schedule in Section C;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Extended Storage Period”</em> - The meaning ascribed to it in
            Section 6; 
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Fees”</em> - The meaning ascribed to it in Section 4;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Informed Consent”</em> - The consent attached to this Service
            Agreement under which the Client gives their informed consent to the
            storage of the specimen;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Initial Storage Period”</em> - The meaning ascribed to it in
            Section 6;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Maternal Blood Test”</em> - Tests which must be undertaken on
            the blood of the mother of the child (as identified and approved in
            the Informed Consent in Section B) to assess whether the Specimen is
            suitable for storage in accordance with international and local
            guidelines and regulations and as otherwise required1 thereunder;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Medical and Health History Profile”</em> - The questionnaire
            completed by the Client under which the Client provides CELLSAVE
            with certain information about his/her/their medical history. Refer
            to separate Medical and Health History Profile booklet;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Processing Fee”</em> - The meaning ascribed to it in the Fee
            Schedule in Section C; 
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Placental Tissue”</em> - Organ that connects the developing
            fetus to the uterine wall; 
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Storage Fee”</em> - The meaning ascribed to it in the Fee
            Schedule in Section C;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Product”</em> - The cryopreserved Stem Cells and the
            cryopreserved Cord Tissue (and, in the event that the CryoAdvanced
            Service is elected, the cryopreserved Stem Cells, the cryopreserved
            Cord Tissue, and the cryopreserved Placental Tissue), (and, in the
            event that the CryoUltimate Service is elected, the cryopreserved
            Stem Cells, the cryopreserved Cord Tissue, the cryopreserved
            Placental Tissue, the cryopreserved Amnion Tissue, and the
            cryopreserved Cord Vessel);
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Release Form”</em> - The meaning ascribed to it in Section 8;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Service Agreement"</em> - This Service Agreement between the
            Client and CELLSAVE which shall include the Service Agreement in
            Section A, the general terms and conditions, the informed consent in
            Section B, the Medical Health History Profile booklet, and the fee
            schedule in section C;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Specimen”</em> - The Cord Blood and Cord Tissue (and in the
            event that the CryoAdvanced Service is elected, the Cord Blood, Cord
            Tissue, and Placental Tissue), (and in the event that the
            CryoUltimate Service is elected, the Cord Blood, Cord Tissue,
            Placental Tissue, Amnion Placental Tissue, and Cord Vessel);
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Stem Cells”</em> - The Stem Cells extracted from the Cord
            Blood;
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <em>“Unit”</em> - The meaning ascribed to it above.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            By signing this Service Agreement, the Client agrees to be legally
            bound to CELLSAVE for the processing, testing, cryopreservation, and
            storage of the Specimen in any Unit. The Client also acknowledges
            that he/ she/they has/have been fully informed, and accept( s) and
            agree(s) to the conditions, risks, limitations, and associated costs
            of such activities, in accordance with these terms and conditions.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>
                2. SERVICES OF CELLSAVE:
                <br />
              </strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE’s obligations and liabilities are expressly limited to the
            services described in this Service Agreement; no other services will
            be provided by CELLSAVE to the Client and CELLSAVE expressly
            disclaims any responsibility to provide any other services other
            than the ones stated in this Service  Agreement.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE will not put the Specimen to use for any purpose other than
            to enable CELLSAVE to provide the Service to the Client as per this
            Service Agreement.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            If the Client elects to use the CELLSAVE services the following
            provisions shall apply:
          </p>
          <p style={{ paddingLeft: "40px" }}>
            On receipt of the Specimen, CELLSAVE will use best endeavors to
            process and cryopreserve Stem Cells from the Specimen for the
            purpose of direct application in humans, provided a sufficient
            number of viable Stem Cells as per its acceptance criteria can be
            identified, CELLSAVE will store the Product for the Initial Storage
            Period (as defined in Section 6 below) or longer as agreed in
            accordance with Section 6.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            In the event, after processing has commenced, there are insufficient
            Stem Cells in the Product or CELLSAVE is unable to harvest collected
            Stem Cells from the Specimen or if CELLSAVE determines that storage
            for other reasons is not possible (e.g. the Specimen is damaged or
            contaminated), no storage will take place and refunds will be as
            stipulated in Section C of the Fee schedule.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            In the event where processing has not taken place due to reasons
            such as Specimen is received by CELLSAVE more than 72 hours after
            the birth of the Child, or the collected Specimen volume is too
            small, refunds will be as stipulated in section C of the Fee
            schedule.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            With respect to Cord Tissue, on receipt of the Specimen, CELLSAVE
            will use its best endeavors to prepare it for cryopreservation and
            storage. In the event that the Cord Tissue has not been collected
            following the instructions, or if CELLSAVE determines that storage
            is for other reasons not possible (e.g. the Specimen is received by
            CELLSAVE more than 72 hours after the birth of the Child; the
            Specimen is damaged or contaminated), refunds will be as stipulated
            in section C of the Fee schedule.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            If the sample/unit is being used for a transplant within 5 years
            (from the date of delivery of the baby), CELLSAVE will give the
            Client a “transplant assistance” valued at 15,000 AED.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <strong>
              <span style={{ color: "#cf0072" }}>
                3. RESPONSIBILITIES OF CLIENT:
              </span>
              <br />
            </strong>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client agrees to complete all necessary forms accurately and in
            a timely manner as CELLSAVE may from time to time reasonably
            consider as required1 or deemed beneficial to the Service in view of
            applicable legislation or industry standards.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client is responsible for arranging the collection of the
            Specimen by the physician, obstetrician, or other medical staff at
            the birth of the Child as per the instructions received from
            CELLSAVE. It is the responsibility of the Client to either contact
            CELLSAVE or the courier company designated by CELLSAVE after the
            Specimen has been collected.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE will arrange for transport of the Specimen to the CELLSAVE
            designated Unit, on the Client’s behalf, immediately after having
            received notification by the Client of the collection of the
            Specimen by the physician, obstetrician, or other medical staff at
            the birth of the Child. CELLSAVE expressly excludes all liability
            for the collection of the Specimen by the physician, obstetrician,
            or other medical staff at the birth of the Child.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE expressly excludes all liability for the transportation of
            the Specimen if done by a third party.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client agrees to act at all times in accordance with all
            applicable local legislation and regulations and will be responsible
            for obtaining all necessary documents and permits if so required1
            for the collection and export of the Specimen to the Unit.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>4. FEES AND PAYMENTS:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The fee for the Service (the “Fee”) and the payment conditions
            applicable to the Service are laid down in the Fee Schedule in
            Section C. The Fee consists of a non-refundable Enrollment Fee and a
            Processing and Storage Fee and certain other fees, in each case as
            further described in the Fee Schedule in Section C. Client agrees to
            pay the Fees on time and in accordance with the Fee Schedule in
            Section C.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Clients understand and agree that they are jointly and severally
            liable for payment of the Fees, which means that all of the Clients
            are liable to pay the Fees, and each Client is jointly and severally
            liable for any unpaid portion of the total fee. This applies even if
            Clients who are married or live together become divorced, separated,
            or otherwise estranged. 
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>5. STORAGE:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Specimen and Product will be processed, tested, cryopreserved,
            and stored in accordance with the following terms:
          </p>
          <p style={{ paddingLeft: "80px" }}>
            a. CELLSAVE stores the Product in an authorized Unit. The Cord Blood
            Product will be split and stored in two physically separate
            locations.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            b. CELLSAVE may assign and transfer any or all of its rights and
            obligations under this Service Agreement to any third party, for
            which the Client hereby gives consent. CELLSAVE will notify the
            Client in writing to that effect.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            c. CELLSAVE does not give, nor does it purport to give, any medical
            advice, or otherwise perform any other function for the Client other
            than the services expressly described in this Service Agreement.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            d. Client hereby consents to the collection, transport, and testing
            of the Specimen and to the processing, cryopreservation, and storage
            of the Specimen in and to a Unit as stated by this Service
            Agreement.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            e. The Client’s right to storage and release of the Product is
            subject to the timely and full payment of all Fees by the Client in
            accordance with this Service Agreement.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>6. TERMS AND TERMINATION:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            These terms and conditions come into force on the date of receipt by
            CELLSAVE of the Service Agreement signed by the Client and will
            continue for the period mentioned in the Payment Plan from the date
            of the birth of the Child (the “Initial Storage Period”).
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Six months prior to the end of the Initial Storage Period, an
            agreement can be made upon the mutual agreement of the Parties in
            the event that Client wishes CELLSAVE to continue to store the
            Product on their behalf for a further period (the “Extended Storage
            Period”), and such extended storage shall be at the market prices
            valid at that time. If 30 days before the expiry of this Service
            Agreement, Parties have not agreed to the terms of an Extended
            Storage Period of the Product, or upon the termination of this
            Service Agreement by CELLSAVE or the Client in accordance with this
            Clause 6, the Client relinquishes all rights in and waives all
            rights to the Product and CELLSAVE shall have the right to destroy
            the Product.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            This Service Agreement may be terminated prior to its term by
            written notice with immediate effect:
          </p>
          <p style={{ paddingLeft: "80px" }}>
            a. By Client at any time prior to the collection of the Specimen. In
            this case, a minimal fee will be charged as indicated in the Fee
            Schedule in Section C.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            b. By CELLSAVE if the Specimen and/or Product are not suitable for
            storage, whether for medical, safety, practical, or any other
            reason, as determined by CELLSAVE in its sole and absolute
            discretion (e.g. the Specimen is received by CELLSAVE more than 72
            hours after the birth of the Child; the Specimen is damaged; the
            collected Specimen volume is too small). In these situations,
            CELLSAVE will not store the Specimen and/or CELLSAVE retains the
            sole right to make this decision.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            c. By CELLSAVE if CELLSAVE does not receive the Enrollment Fee
            and/or Processing and Storage Fee within the specific time.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Termination of this Service Agreement under clause 6c above will not
            affect the Client’s responsibility for payment in full of all
            amounts invoiced and of all additional administrative and legal
            costs related to collecting the outstanding amounts. On termination
            of this Service Agreement, CELLSAVE reserves the right to destroy
            the Specimen and/or the Product.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>7. RIGHTS TO PRODUCT:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Unless otherwise defined by applicable law, the Product remains the
            property of the Child throughout the term of this Agreement. Until
            the Child reaches legal age, the Client has the right and
            responsibility to consent to the collection, storage, use, and
            disposal of the Product on behalf of the Child.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>8. RELEASE REQUEST:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            In the event that the Product is required1 for treatment or
            manufacturing of a medicinal product, the Client shall provide
            written notification of the same to CELLSAVE. Any request for
            release has to be addressed to CELLSAVE Arabia, P.O. Box 505152,
            Dubai Healthcare City, Dubai, UAE, to the attention of the Medical
            Director, or to the e-mail address{" "}
            <a href="mailto:infoarabia@cellsave.com">
              infoarabia@cellsave.com.
            </a>{" "}
            The notice shall include the name and address of the physician and
            hospital to which the Product must be sent or the appropriately
            accredited Current Good Manufacturing Practices (cGMP) manufacturer.
            CELLSAVE shall then provide the authorized hospital or manufacturer
            with a release form (the “Release Form”) which must be returned to
            CELLSAVE. CELLSAVE will arrange for transport of the Product to the
            hospital, on the Client’s behalf.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE expressly excludes all liability for the transportation of
            the Product conducted by a third party.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE will not charge the Client any costs involved with the
            transport of the Product to the destination indicated on the Release
            Form.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>9. ACKNOWLEDGEMENT:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client understands that the actual collection of the Specimen by
            the physician, obstetrician, or other medical staff at the birth of
            the Child is not part of the Service and that the relevant doctor,
            obstetrician, or other medical staff may under certain circumstances
            refuse, be unable or fail to collect and/or adequately pack the
            Specimen and that neither CELLSAVE nor its officers, shareholders,
            employees, agents, and contractors bear any responsibility for any
            mistakes or damages caused by such doctor, obstetrician or other
            medical staff.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client also understands and confirms that CELLSAVE, its
            officers, directors, employees, shareholders, agents, and
            consultants have not made any representations and bear no
            responsibilities and obligations with respect to the possibility to
            collect Stem Cells successfully from the Specimen, the suitability
            of the Stem Cells for the contemplated treatment, the successful
            treatment of any diseases through Stem Cell transplantation or
            therapies, and the advantages of umbilical Cord Blood Stem Cell
            usage over other therapies using Stem Cells.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>10. LIMITATION OF LIABILITY:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE’s responsibility towards the Client and/or the Child whose
            Product/s are being stored is limited to the Service described in
            this Service agreement. Client agrees that should he/she/they make
            any claim against CELLSAVE, CELLSAVE’s liability shall, to the
            extent allowed under applicable law, regardless of the basis of such
            claim, whether in contract, tort or otherwise, be limited in total
            and in aggregate to (a) the amount of the Fee paid by the Client to
            CELLSAVE under this Service Agreement, or (b) in the event of
            CELLSAVE’s negligence, the amount permitted to be recovered by
            CELLSAVE under the malpractice insurance maintained by CELLSAVE in
            connection with such claims.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>11. DATA PROTECTION:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The Client agrees to the processing and storage of personal data
            (including personal data of the Child and the mother and father of
            the Child), by CELLSAVE or its agent, affiliate, or subcontractor,
            which has come to CELLSAVE’s knowledge in the course of performing
            the Services and otherwise under this Service Agreement. The Client
            agrees and permits CELLSAVE to inform the status of infectious
            disease report of the mother of the Child and the Cord Blood and
            Cord Tissue and/or Placental Tissue/Amnion Placental Tissue/Cord
            Vessel to the United Arab Emirates Government regulatory agencies
            including the Ministry of Health, the Dubai Health Authority, Dubai
            Healthcare City Authority or any other agency mandated under United
            Arab Emirates laws or under any other law to which CELLSAVE is
            subject.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE will use the Client’s personal data only to the extent
            required1 by its contractual obligations hereunder and in accordance
            with the applicable laws and regulations of the United Arab
            Emirates.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>12. FORCE MAJEURE:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE cannot be held liable for, and expressly excludes all
            liability for, any possible loss or damage due to natural disasters,
            an act of war or terror, riot, strike, vandalism, acts or omission
            of authorities, or any events beyond CELLSAVE‘s control which cause
            destruction of, or deterioration to the Specimen and/or Product.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>13. NOTICE:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            If the Client needs to send CELLSAVE any notices or communications
            under the Service Agreement, the Client must send them in writing by
            first-class or registered post/recorded delivery to the CELLSAVE
            address as set out in Section 13 below or any alternative address
            that CELLSAVE notification to the Client.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            If CELLSAVE needs to send the Client any notices or communications
            under the Service Agreement CELLSAVE will send them in writing by
            first-class or registered post/recorded delivery to the Client’s
            address as notified by the Client to CELLSAVE on page 1. The Client
            shall as soon as possible notify CELLSAVE in writing of any changes
            in the information provided, including relocation and/or change of
            address. All notifications to CELLSAVE shall be sent to CELLSAVE
            Arabia, P.O. Box 505152, Dubai Healthcare City, Al Razi Medical
            Complex 64, Dubai, UAE, or to the e-mail address:
            infoarabia@cellsave.com. CELLSAVE may completely rely on the data
            made available in writing by the Client, without any obligation to
            make further inquiries with respect to the correctness or actuality
            of such data.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>14. COMPLIANCE WITH UAE LAWS AND REGULATIONS:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            CELLSAVE operates and exists in the United Arab Emirates and thus is
            required1 to comply with the laws of the United Arab Emirates and
            the regulatory and legal requirements of governmental agencies such
            as the Ministry of Health, the Dubai Health Authority, and the Dubai
            Healthcare City Authority. The Client hereby agrees that CELLSAVE
            may take any action required1 by it in order to comply with the laws
            of the United Arab Emirates, as well as the regulatory and legal
            requirements of governmental agencies such as the Ministry of
            Health, the Dubai Health Authority, and the Dubai Healthcare City.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>15. GOVERNING LAW:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            This Service Agreement shall be governed by, and construed in
            accordance with, the laws of United Arab Emirates. The Parties may
            bring suit in any court of competent jurisdiction.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>16. ELECTRONIC COMMUNICATION:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "80px" }}>
            a. The Client(s) acknowledges that this agreement does not infringe
            any provision of the Federal Law No. (1) on Electronic Commerce and
            Transactions, of 2006.
          </p>
          <p style={{ paddingLeft: "80px" }}>
            b. This agreement shall constitute a valid and binding agreement in
            accordance with Article 18 of the Federal Law No. (1) on Electronic
            Commerce and Transactions of 2006. No written confirmation or
            reciprocal signature by the Client(s) and/or CELLSAVE is required1
            to give effect to the rights and obligations established by the
            Service Agreement.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>17. HEADINGS:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            The headings contained in this Service Agreement are for reference
            purposes only and shall not affect the meaning or interpretation of
            this Service Agreement.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>18. SEVERABILITY:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Should any provision of this Service Agreement be invalid or
            unenforceable or should this Service Agreement contain an omission,
            the remaining provisions shall remain valid. In the place of an
            invalid provision or an omission, a new, valid provision – which
            comes economically closest to the one actually agreed upon provision
            or, in the event of an omission, the intentions set forth in this
            Service Agreement – is presumed to be agreed upon by the Parties.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>19. ENTIRE AGREEMENT:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            This Service Agreement constitutes the entire understanding between
            the Parties with respect to the subject matter of this Service
            Agreement and supersedes all prior agreements, negotiations, and
            discussions between the Parties relating to it.
          </p>
          <p style={{ paddingLeft: "40px" }}>
            <span style={{ color: "#cf0072" }}>
              <strong>20. AMENDMENT:</strong>
            </span>
          </p>
          <p style={{ paddingLeft: "40px" }}>
            Neither Party can change or amend the terms of this Service
            Agreement without the prior written approval of the other Party.
          </p>
          <p style={{ paddingLeft: "40px" }}> </p>
          <p>
            <span style={{ color: "#8884d5" }}>
              <strong>
                <u>B. INFORMED CONSENT</u>
              </strong>
            </span>
          </p>
          <p style={{ textAlign: "left" }}>
            I/We on behalf of my/our unborn Child wish to enroll in the
            Umbilical Cord Blood and Cord Tissue (and/or, if applicable,
            Placental Tissue/Amnion Placental Tissue/Cord Vessel) banking
            service offered by CELLSAVE. The Specimen will be uniquely
            identified, stored, and maintained at a cryogenic storage facility.
            CELLSAVE will retrieve these cells at my/our request as per the
            Service Agreement. I/We agree to its terms of understanding as
            outlined below, and consent to have the Service performed.
          </p>
          <p style={{ textAlign: "left" }}>
            I/We understand that collection and storing my/our newborn’s Stem
            Cells may potentially benefit my/our Child should he or she need
            them in the future to treat certain diseases. I/We understand that
            it is not possible to determine whether my/our Child will develop a
            disease in the future that can be treated with these cells. I/We
            understand that these newborn Stem Cells are a perfect match for
            my/our Child and, while there is no guarantee my/our child will ever
            need them, the fact that they are a perfect match can reduce serious
            complications should cell therapy ever be needed. I/We understand
            that my Child’s cells may be suitable for use by other family
            members.
          </p>
          <p style={{ textAlign: "left" }}>
            I/We understand that, although infrequent, complications may occur
            at birth, and it may not be possible to collect Cord Blood and Cord
            Tissue (and/or, if applicable, Placental Tissue/ Amnion Placental
            Tissue /Cord Vessel) from my/our Child. Therefore, collection of
            Cord Blood and Cord Tissue (and/ or, if applicable, Placental
            Tissue/ Amnion Placental Tissue/Cord Vessel) from my/our Child
            cannot be guaranteed as its collection is arranged between my/ our
            physician/midwife and me/us. My/Our health and the health of my/our
            newborn is my/our physician/midwife’s first priority. I/We agree
            that my/our physician/midwife’s judgment shall be absolute and
            final.
          </p>
          <p style={{ textAlign: "left" }}>
            I/We understand that CELLSAVE will perform cell viability, cell
            counts, blood typing, and bacterial and fungal tests on the Cord
            Blood and Cord Tissue (and/or, if applicable, Placental Tissue/
            Amnion Placental Tissue /Cord Vessel) to determine the nature and
            quality of the Cord Blood and Cord Tissue (and/or, if applicable,
            Placental Tissue/ Amnion Placental Tissue/Cord Vessel). I/We
            understand that regulatory and accreditation associations require
            certain tests and I/we fully consent to provide the Maternal Blood
            Test. I/We consent to the use of a sample from the blood of the
            mother of the Child to be tested for certain infectious diseases .
            This is in accordance with the AABB, EU and International
            Directives, guidelines for tissue banks, and as otherwise required1
            under the laws of the United Arab Emirates.
          </p>
          <p style={{ textAlign: "left" }}>
            I/We understand that if the test results of the sample are confirmed
            positive for HIV, the Cord Blood and Cord Tissue (and/or, if
            applicable, Placental Tissue/ Amnion Placental Tissue /Cord Vessel)
            will not be stored. I/We understand that if the tests indicate the
            possible presence of hepatitis B or C, syphilis, or if my responses
            to the health questionnaire indicate a risk of one of these
            infections, my/our Child’s Cord Blood and Cord Tissue (and/or, if
            applicable, Placental Tissue/ Amnion Placental Tissue /Cord Vessel)
            will be processed and cryopreserved only with the approval of the
            CELLSAVE Medical Director.
          </p>
          <p style={{ textAlign: "left" }}>
            Based on the review of these results, I/we understand that CELLSAVE
            may decide not to permanently store the Cord Blood and Cord Tissue
            (and/or, if applicable, Placental Tissue/ Amnion Placental
            Tissue/Cord Vessel). If this occurs, I/we will be notified in
            writing of this decision. I/We authorize CELLSAVE to provide me with
            test results and to furnish them to my primary physician, my
            partner, or/and to government agencies as only required1 by law. If
            the Maternal Blood Test is positive for one of the infectious
            diseases tested for, the Client(s) should sign a continuation form
            provided by CELLSAVE.
          </p>
          <p style={{ textAlign: "left" }}>
            I/We understand that CELLSAVE will follow the United Arab Emirates
            regulations and will follow the same regulations for Cord Blood,
            Cord Tissue, Placental Tissue, Amnion Placental Tissue/Cord Vessel
            collection, and the Maternal Blood Test. I/We understand that as per
            the regulations of the United Arab Emirates, CELLSAVE processes and
            cryopreserves Cord Blood, Cord Tissue, Placental Tissue, Amnion
            Placental Tissue, and Cord Vessel collected from full-term live
            babies only, and does not process or cryopreserve Cord Blood, Cord
            Tissue, Placental Tissue, Amnion Placental Tissue, and Cord Vessel
            collected from an abortus. I/We understand that the Client(s) are
            responsible for the barcodes provided to him/her/them and issued by
            CELLSAVE.
          </p>

          <p>
            <strong>Please tick below to agree.</strong>
          </p>

          <p className="flex items-center gap-2 ">
            <input
              type="checkbox"
              name="esig-sif-1662362712879-1"
              id="esig-sif-1662362712879-1"
              required1
            />
            <label htmlFor="esig-sif-1662362712879-1">
              I/We, the undersigned Parents(s)/and/or Legal Guardian(s) hereby
              accept the General Terms and Conditions, which are attached to
              this Service Agreement.
            </label>
          </p>
          <p className="flex items-center   gap-2">
            <input
              type="checkbox"
              name="esig-sif-1662362712879-2"
              id="esig-sif-1662362712879-2"
              required1
            />
            <label htmlFor="esig-sif-1662362712879-2">
              I/We (on behalf of myself and the other Client) affirm that I/We
              have fully understood the information provided and have had the
              opportunity to ask questions and have been provided with
              satisfactory responses.
            </label>
          </p>
          <p className="flex items-center   gap-2">
            <input
              type="checkbox"
              name="esig-sif-1662362712879-3"
              id="esig-sif-1662362712879-3"
              required1
            />
            <label htmlFor="esig-sif-1662362712879-3">
              I/We herewith give my/our Informed Consent for the Cord Blood and
              Cord Tissue (and/or, if applicable, Placental Tissue/Amnion
              Placental Tissue/Cord Vessel) Collection.
            </label>
          </p>
          <p className="flex items-center  gap-2">
            <input
              type="checkbox"
              name="esig-sif-1662362712879-4"
              id="esig-sif-1662362712879-4"
              required1
            />
            <label htmlFor="esig-sif-1662362712879-4">
              I(We), the undersigned Parent(s)/and/or Legal Guardian hereby
              accept the Fee Schedule.
            </label>
          </p>
          <p className="flex items-center  gap-2">
            <input
              type="checkbox"
              name="esig-sif-1662362712879-5"
              id="esig-sif-1662362712879-5"
              required1
            />
            <label htmlFor="esig-sif-1662362712879-5">
              I/We understand and agree that I/We are jointly and severally
              liable for payment of the Fees.
            </label>
          </p>

          <p style={{ fontSize: "9px" }}>
            Code: C.2.50-4B/V1 CSA Agreement-English
          </p>
          <p>&nbsp;</p>
          <p>
            <p style={{ color: "#cf0072" }}>
              <strong>
                Please enter your Full Name and Email Address to receive a copy
                of agreement. This will be considered as signed copy of
                agreement
              </strong>
            </p>
            <p>
              {" "}
              Name <input type="text" name="signed-name" required1 />
            </p>
            <p>
              Email{" "}
              <input
                type="email"
                name="signed-email"
                required1
                onChange={handleEmailChange}
              />
            </p>
          </p>
        </div>
        <button
          type="submit"
          className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white rounded-lg px-8 py-4 m-4"
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default ContractPDF;
