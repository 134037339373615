import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "./ParentContainer";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const TranslplantNumbers = ({
  backgroundImage,
  title,
  numberone,
  numbertwo,
  numberthree,
  textone,
  texttwo,
  textthree,
  mobileBackgroundImage,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const handleEnter = () => {
    setIsVisible(true);
  };

  const parentRef = useRef(null);
  const main = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, scale: 0 },
          {
            x: 0,
            scale: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { y: 50, scale: 0 },
          {
            y: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 80%",
          end: "bottom 80%",
          scrub: 1,
          toggleActions: "play none none none",
          onEnter: () => {
            setIsCounting(true);
          },
          // markers: true,
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  const isMobile = window.innerWidth < 768;
  const backgroundImageUrl = isMobile ? mobileBackgroundImage : backgroundImage;

  return (
    <div
      ref={main}
      className="relative"
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute w-full h-full inset-0 bg-black opacity-50 z-10"></div>
      <ParentContainer
        containerStyle={
          "flex flex-col bg-center bg-no-repeat bg-cover relative "
        }
        containerHeight={"py-28"}
      >
        <p className="w-full text-center text-2xl font-bold text-white pb-20  circle max-md:text-base max-md:font-bold z-10">
          {title}
        </p>
        <div className="flex gap-5 max-md:flex-wrap z-10" ref={parentRef}>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs max-md:w-full">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white max-md:text-2xl">
              {isCounting && <CountUp start={0} end={numberone} duration={3} />}
            </h4>
            <p className="w-4/5 text-center text-lg  text-white pb-12 font-thin max-md:text-base">
              {textone}{" "}
            </p>
          </div>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs max-md:w-full">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white max-md:text-2xl">
              {isCounting && <CountUp start={0} end={numbertwo} duration={3} />}
            </h4>
            <p className="w-4/5 text-center text-lg   text-white pb-12 font-thin max-md:text-base">
              {texttwo}{" "}
            </p>
          </div>
          <div className="w-1/3 flex flex-wrap justify-center gap-2 imgs max-md:w-full">
            <h4 className="w-full leading-10 text-center text-5xl font-bold  text-white max-md:text-2xl">
              {isCounting && (
                <CountUp start={0} end={numberthree} duration={3} />
              )}
            </h4>
            <p className="w-4/5 text-center text-lg   text-white pb-12 font-thin max-md:text-base">
              {textthree}{" "}
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default TranslplantNumbers;
