import React from "react";
import assets from "../assets/assets";
import { Link } from "react-router-dom";
import ParentContainer from "./ParentContainer";

const Footer = () => {
  return (
    <ParentContainer
      containerStyle={
        "bg-gradient-to-r from-custom-gray to-black px-36 pt-36 max-md:pt-20 flex flex-col"
      }
      containerHeight={"py-0"}
    >
      <div className=" ">
        <div className="flex w-full justify-between max-md:flex-wrap">
          <div className="flex flex-col gap-5 w-2/5 max-lg:w-2/5  max-md:!w-full">
            <img
              src={assets.logofooter}
              alt=""
              className="w-2/5 max-md:w-3/5"
            />
            <p className="py-1 px-2 font-semibold text-white max-md:text-sm">
              CellSave is a leading provider of advanced stem cell preservation
              services, committed to delivering the highest standards of quality
              and care. With cutting-edge proprietary technology, experienced
              professionals, and a customer-centric approach, we ensure that
              your cells are preserved securely and effectively for future use.
            </p>
            <div>
              <ul className="flex gap-5">
                <li>
                  <Link to="https://x.com/i/flow/login?redirect_after_login=%2FArabiaSave">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005Z"
                        stroke="url(#paint0_linear_590_4193)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4193"
                          x1="-1.08621"
                          y1="4.98373"
                          x2="26.7639"
                          y2="7.39442"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/cellsavearabia/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                        stroke="url(#paint0_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.9997 11.3701C16.1231 12.2023 15.981 13.0523 15.5935 13.7991C15.206 14.5459 14.5929 15.1515 13.8413 15.5297C13.0898 15.908 12.2382 16.0397 11.4075 15.906C10.5768 15.7723 9.80947 15.3801 9.21455 14.7852C8.61962 14.1903 8.22744 13.4229 8.09377 12.5923C7.96011 11.7616 8.09177 10.91 8.47003 10.1584C8.84829 9.40691 9.45389 8.7938 10.2007 8.4063C10.9475 8.0188 11.7975 7.87665 12.6297 8.00006C13.4786 8.12594 14.2646 8.52152 14.8714 9.12836C15.4782 9.73521 15.8738 10.5211 15.9997 11.3701Z"
                        stroke="url(#paint1_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17.5 6.5H17.51"
                        stroke="url(#paint2_linear_590_4195)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4195"
                          x1="0.103448"
                          y1="4.22222"
                          x2="25.4849"
                          y2="6.01415"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_590_4195"
                          x1="7.28435"
                          y1="8.84568"
                          x2="17.4369"
                          y2="9.56245"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_590_4195"
                          x1="17.4991"
                          y1="6.61111"
                          x2="17.5118"
                          y2="6.61112"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.tiktok.com/@cellsavearabia" href="">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1292_3689)">
                        <path
                          d="M12 0C5.37361 0 0 5.37361 0 12C0 18.6264 5.37361 24 12 24C18.6264 24 24 18.6264 24 12C24 5.37361 18.6264 0 12 0ZM18.0202 9.18155V10.8064C17.254 10.8067 16.5096 10.6565 15.8075 10.3603C15.356 10.1697 14.9354 9.92414 14.5507 9.62738L14.5622 14.6286C14.5574 15.7548 14.1119 16.8129 13.3055 17.6099C12.6492 18.2587 11.8177 18.6712 10.9163 18.8047C10.7046 18.8361 10.489 18.8522 10.2711 18.8522C9.30623 18.8522 8.39025 18.5396 7.64095 17.963C7.49995 17.8544 7.36509 17.7367 7.2367 17.6099C6.36277 16.7461 5.91211 15.5755 5.988 14.3439C6.04589 13.4065 6.42122 12.5124 7.04691 11.8117C7.87472 10.8844 9.03281 10.3697 10.2711 10.3697C10.489 10.3697 10.7046 10.3861 10.9163 10.4175V11.0182V12.6894C10.7155 12.6232 10.5011 12.5867 10.2778 12.5867C9.14648 12.5867 8.23125 13.5097 8.24817 14.6418C8.25891 15.3662 8.65458 15.9994 9.23817 16.3484C9.51244 16.5125 9.82795 16.6141 10.1647 16.6326C10.4285 16.647 10.6818 16.6103 10.9163 16.5329C11.7246 16.2659 12.3077 15.5067 12.3077 14.6113L12.3104 11.2622V5.14781H14.548C14.5501 5.36953 14.5726 5.58586 14.6145 5.79548C14.7833 6.64397 15.2616 7.38009 15.9272 7.88194C16.5076 8.3197 17.2304 8.5792 18.0137 8.5792C18.0142 8.5792 18.0207 8.5792 18.0201 8.57869V9.18155H18.0202Z"
                          fill="url(#paint0_linear_1292_3689)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_1292_3689"
                          x1="0"
                          y1="12"
                          x2="24"
                          y2="12"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8D7BCE" />
                          <stop offset="1" stop-color="#C51380" />
                        </linearGradient>
                        <clipPath id="clip0_1292_3689">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="https://www.facebook.com/cellsavearabia/" href="">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 26.25C20.7655 26.25 26.25 20.7655 26.25 14C26.25 7.23451 20.7655 1.75 14 1.75C7.23451 1.75 1.75 7.23451 1.75 14C1.75 20.0377 6.11806 25.0553 11.8665 26.0649V17.7464H8.75V14.2888H11.8665V11.6536C11.8665 8.65632 13.698 7 16.5027 7C17.8452 7 19.25 7.23362 19.25 7.23362V10.1772H17.7014C16.1767 10.1772 15.7021 11.1 15.7021 12.0461V14.2888H19.1061L18.562 17.7464H15.7021V26.1052C15.1113 26.1956 14.5076 26.2444 13.8931 26.2495C13.9287 26.2498 13.9643 26.25 14 26.25Z"
                        fill="url(#paint0_linear_590_4199)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_4199"
                          x1="-0.573276"
                          y1="4.47222"
                          x2="30.519"
                          y2="6.66733"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#8884D5" />
                          <stop offset="1" stop-color="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-1/5  max-lg:w-0"></div>
          <div className="w-1/5 max-md:w-full max-md:mt-10">
            <h5 className="text-white text-base font-semibold pb-5 max-md:pb-0">
              Contact Us
            </h5>
            <ul className="flex flex-col gap-2 pt-5 text-lg max-md:text-sm">
              <li className="text-[#EAECF0] ">
                <a href="tel:009718002796">
                  24/7 customer support
                  <br /> Dubai: +971 (800) 2796
                  <br />
                  Abu Dhabi: +971 (800) 2355
                </a>
              </li>
            </ul>
          </div>
          <div className="w-auto max-md:w-full max-md:mt-10 max-md:hidden">
            <h5 className="text-white text-base font-semibold pb-5 max-md:pb-0 ">
              Quick Links
            </h5>
            <ul className="flex flex-col gap-2 pt-5">
              <li className="text-[#EAECF0] text-lg">
                <Link to="/about">About CellSave</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/service-pricing">Services & Pricing</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/blog">Resources</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/faqs">FAQs</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/terms-condition">Terms and Conditions</Link>
              </li>
              <li className="text-[#EAECF0] text-lg">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className=" py-5 mt-10 flex w-full flex-wrap  text-white border-t border-white">
          <div className="flex  w-1/2  max-lg:w-full max-lg:flex-wrap max-lg:justify-center max-lg:mb-5 max-md:text-sm">
            <h3 className="w-auto pr-5 max-lg:pr-0 max-lg:pb-1 max-md:">
              2005-2024 CellSave Arabia FZ-LLC
            </h3>
            <h3 className="pl-5">Get Support: +971 (800) 2796</h3>
          </div>
          <div className="flex w-1/2 justify-end max-lg:justify-center content-center max-lg:w-full max-lg:flex-wrap">
            <ul className="grid grid-cols-3 divide-x max-md:text-sm ">
              <li>
                <Link to="/blog">Resources</Link>
              </li>
              <li className="pl-5">
                <Link to="/faqs">FAQS</Link>
              </li>
              <li className="pl-5">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default Footer;
