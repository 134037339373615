import logo from "./logo.png";
import herovideo from "./herovideo.mp4";
import kit from "./kit.png";
import introduce from "./introduce.png";
import signup from "./signup.png";
import Featuredin from "./featuredin.png";
import a1video from "./43vid.mp4";
import logofooter from "./logoFooter.png";
import popupimg from "./popup.png";
import loginpopup from "./loginpopup.png";
import line from "./Line.png";
import VideoSection from "./videoSection.mp4";
import whysave from "./whysave.png";
import TransplantNumbers from "./transplant-number.png";
import ReasonOne from "./reason-one.png";
import ReasonOneTwo from "./reason-one2.png";
import ReasonTwo from "./reason-twopng.png";
import medone from "./medone.png";
import medtwo from "./medtwo.png";
import medthree from "./medthree.png";
import regenmed from "./regenmed.png";
import reguses from "./uses.png";
import power from "./power.png";
import Regnumbers from "./Regnumbers.png";
import sourcesBanner from "./sourcesbanner.png";
import sourceStem from "./sourceStem.png";
import sourcePower from "./sourcePower.png";
import cordBanner from "./CordBanner.png";
import cordAdvantage from "./CordAdvantage.png";
import cordTissueBannerer from "./CordTissueBanner.png";
import cordTissueAdvantage from "./cordTissueAdvantage.png";
import placentaTissueBanner from "./PlacentaTissue.png";
import cordVesselBanner from "./cordVesselBanner.png";
import amnionTissueBanner from "./AmnionTissueBanner.png";
import amnionAdvantage from "./amnionAdvantage.png";
import stemcellbanking from "./stemcellbanking.png";
import giftRegistryBanner from "./giftRegistryBanner.png";
import blogdemoBanner from "./blogdemoBanner.png";
import blogbanner from "./blogBanner.png";
import blogindexImage from "./blogIndexImage.png";
import blogsinglebanner from "./blogsinglebanner.png";
import featuredMomIndex from "./featuredMomIndex.png";
import momsingle from "./momsingle.png";
import profilepic from "./profilepic.png";
import profilehaelthline from "./profilehaelthline.png";
import emptySection from "./emptySection.png";
import voucher from "./voucher.png";
import ProductImageOne from "./ProductImageOne.png";
import checkoutProduct from "./checkoutimage.png";
import timelineOne from "./timeline.svg";
import timelineTwo from "./timeline2.svg";
import templateCord from "./templatecordpng.png";
import homeFamilies from "./homeFamlies.png";
import kitimage from "./kitimage.png";
import howitworksbg from "./howitworks.png";
import howitworks from "./howitworks1.png";
import blogMob from "./blog-mob.png";
import logosvg from "./cellsave.svg";
import success from "./success.gif";
import failed from "./failed.gif";
import slide1 from "./3.png";
import slide2 from "./2.png";
import slide3 from "./5.png";
import slide4 from "./1.png";
import slide5 from "./6.png";
import slide6 from "./4.png";
import mt1 from "./mt1.webp";
import mt2 from "./mt2.webp";
import mt3 from "./mt3.webp";
import mt4 from "./mt4.webp";
import mt5 from "./mt5.webp";
import mt6 from "./mt6.webp";
import mt7 from "./mt7.webp";
import mt8 from "./mt8.webp";
import mt9 from "./mt9.webp";
import logoAdscc from "./logo-1.webp";
import cricleLogo from "./circle-logo-1.png";
export default {
  success,
  failed,
  logo,
  herovideo,
  kit,
  introduce,
  signup,
  Featuredin,
  a1video,
  logofooter,
  popupimg,
  loginpopup,
  line,
  VideoSection,
  whysave,
  TransplantNumbers,
  ReasonOne,
  ReasonOneTwo,
  ReasonTwo,
  medone,
  medtwo,
  medthree,
  regenmed,
  reguses,
  power,
  Regnumbers,
  sourcesBanner,
  sourceStem,
  sourcePower,
  cordBanner,
  cordAdvantage,
  cordTissueBannerer,
  cordTissueAdvantage,
  placentaTissueBanner,
  cordVesselBanner,
  amnionTissueBanner,
  amnionAdvantage,
  stemcellbanking,
  giftRegistryBanner,
  blogdemoBanner,
  blogbanner,
  blogindexImage,
  blogsinglebanner,
  featuredMomIndex,
  momsingle,
  profilepic,
  profilehaelthline,
  emptySection,
  voucher,
  ProductImageOne,
  checkoutProduct,
  timelineTwo,
  timelineOne,
  templateCord,
  homeFamilies,
  kitimage,
  howitworks,
  howitworksbg,
  blogMob,
  logosvg,
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  mt1,
  mt2,
  mt3,
  mt4,
  mt5,
  mt6,
  mt7,
  mt8,
  mt9,
  cricleLogo,
  logoAdscc,
};
