import React from "react";
import ParentContainer from "../components/ParentContainer";

const RefundPolicy = () => {
  return (
    <ParentContainer>
      <div className="flex flex-col gap-3">
        <h1 className="text-5xl font-bold  text-left  w-1/2  max-md:text-2xl max-lg:w-full max-lg:mb-4">
          Refund Policy{" "}
        </h1>
        <p>
          • The Enrollment Fee includes account set and administration, the
          Collection Kit, delivery charge of the Collection Kit, and
          transportation charges to the laboratory by authorized courier/driver.
          This is a non-refundable down payment and should accompany the signed
          Client Service Agreement.
        </p>
        <p>
          • In the event that Specimen has been collected, received and
          processed, yet CELLSAVE rejects the sample due to its non-compliance
          with regulatory standards, no storage will take place and CELLSAVE
          will refund the Storage Fee to the Client. The Processing Fee is not
          refundable if the Cord Blood (and/or, if applicable, Cord
          Tissue/Placental Tissue/Amnion Placental Tissue/Cord Vessel) Specimen
          has been processed and tested.
        </p>
        <p>
          • In the event that any part of a client package is not processed, the
          client is only responsible for paying for the components that are
          successfully processed and stored. If any component of your package is
          not processed, the client will not be responsible for paying for these
          components and any costs paid will be reimbursed as per the terms and
          conditions of this agreement.
        </p>
        <p>
          • Refunds will be done only through the Original Mode of Payment.
          Please allow for up to 45 days for the refund transfer to be
          completed.
        </p>
        <p>
          • Once the payment is made, the confirmation notice will be sent to
          the client via email within 24 hours of receipt of payment.&nbsp;
        </p>
        <p>
          • Customer can cancel their order within 24 hours; refunds will be
          made back to the payment solution used initially by the customer.
        </p>
      </div>
    </ParentContainer>
  );
};

export default RefundPolicy;
