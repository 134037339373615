import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SaleforceTest = () => {

    const [lead, setLead] = useState({
        firstName: "",
        lastName: "",
        company: "",
        email: "",
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setLead({ ...lead, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Salesforce settings
        const ACCESS_TOKEN = "00DOy00000A8RLR!AQEAQPCGzLTfuI73v34mFM3KUKC1.0FgboH.RIwU2w3AsZn4bmQDf2m5Rba9824rC_9zgAdn2AZARFYK3yOcc6qZ8EbswjPS"; // Replace with your access token
        const INSTANCE_URL = "https://cryosave--qtech.sandbox.my.salesforce.com"; // Replace with your instance URL
        const SALESFORCE_API_VERSION = "v54.0"; // Replace with your Salesforce API version
    
        try {
          const response = await axios.post(
            `${INSTANCE_URL}/services/data/${SALESFORCE_API_VERSION}/sobjects/Lead`,
            {
              FirstName: lead.firstName,
              LastName: lead.lastName,
              Company: lead.company,
              Email: lead.email,
            },
            {
              headers: {
                Authorization: `Bearer ${ACCESS_TOKEN}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log("Lead successfully created:", response.data);
          alert("Lead successfully created!");
          setLead({
            firstName: "",
            lastName: "",
            company: "",
            email: "",
          });
        } catch (error) {
          console.error("Error creating lead:", error);
          alert("Error creating lead.");
        }
      };
    
      return (
        <div>
          <h2>Create a Salesforce Lead</h2>
          <form onSubmit={handleSubmit}>
            <label>
              First Name:
              <input
                type="text"
                name="firstName"
                value={lead.firstName}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                value={lead.lastName}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              Company:
              <input
                type="text"
                name="company"
                value={lead.company}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={lead.email}
                onChange={handleChange}
                required
              />
            </label>
            <br />
            <button type="submit">Send Lead</button>
          </form>
        </div>
      );
}

export default SaleforceTest