import React, { useRef, useState } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ApiUrl } from "../../../components/ApiUrl";
import assets from "../../../assets/assets";

const AdsccContract = () => {
  const printRef = useRef();
  const [files, setFiles] = useState([]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");
  const [buttonText, setButtonText] = useState("Send Contract");

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };

  const handleGeneratePdf = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setButtonText("Generating PDF..."); // Update button text

    const element = printRef.current;

    const options = {
      margin: [0.5, 0.5, 0.5, 0.5], // Top, left, bottom, right margins in inches
      filename: "contract.pdf",
      html2canvas: { scale: 2 }, // Increase scale for better quality
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("files", pdfBlob, "adscc-contract.pdf");

        files.forEach((file) => {
          formData.append("files", file);
        });

        const recipientEmails = to
          ? `${to}, it@cellsave.com, maqcadet3@gmail.com`
          : "maqcadet3@gmail.com";
        formData.append("to", "maqcadet3@gmail.com");
        formData.append("subject", subject || "Adscc Contract");
        formData.append("text", text || "Your Copy of the Contract");
        formData.append("html", html || "Your Copy of the Contract");

        handleSubmit(formData);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setButtonText("Error! Try Again"); // Update button text in case of error
      });
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async (formData) => {
    setButtonText("Sending..."); // Update button text

    try {
      const response = await axios.post(
        ApiUrl + "/api/pdfmailer/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
      setButtonText("Sent Successfully"); // Update button text on success
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Error! Try Again"); // Update button text in case of error
    } finally {
      setTimeout(() => {
        setButtonText("Send Contract"); // Reset button text after a delay
      }, 3000);
    }
  };
  return (
    <div className="flex w-full bg-gray-600 justify-center items-center pt-10 flex-col">
      <form onSubmit={handleGeneratePdf}>
        <div
          ref={printRef}
          style={{
            background: "#fff",
            width: "100%", // Use a responsive width
            maxWidth: "210mm", // Ensure it does not exceed A4 width
            minHeight: "297mm",
            padding: "20px", // Optional: Add padding
            boxSizing: "border-box", // Include padding in width calculations
            pageBreakInside: "avoid", // Prevent breaking inside this element
            overflowWrap: "break-word", // Ensure words break correctly
            wordWrap: "break-word", // Compatibility for word wrapping
          }}
          className="contract "
        >
          <p className="flex items-center w-full gap-5">
            <span
              style={{ color: "#cf0072", fontSize: "30px" }}
              className="text-left flex-1 leading-10 text-xl md:text-3xl lg:text-4xl text-gray-800 "
            >
              <strong>CLIENT SERVICE AGREEMENT</strong>
            </span>{" "}
            <img className="w-1/5" src={assets.cricleLogo} alt="" />
          </p>

          <div>
            <p className="mt-4 text-gray-700 text-left">
              This CELLSAVE Stem Cell Storage Agreement is a Contract that has
              been developed between “ CELLSAVE “ and the “Client” which sets
              out the General Terms and Conditions by which CELLSAVE and the
              Client will cover all aspects of the Parties agreement.
            </p>
            <p className="mt-4 text-gray-700 text-left">
              This is a very important document, which the Client should read
              through carefully.
            </p>
            <p className="mt-4 text-gray-700 text-left">
              The Client has to complete the Contract as described below and
              return it to us. A copy will be emailed to the Client for their
              own reference/record.
            </p>
            <p className="mt-4 text-gray-700 text-left">
              This document consists of the following sections:
            </p>
          </div>

          <div className="mt-8 text-left">
            <ol className="  text-pink-600 font-bold">
              <li>A. SERVICE AGREEMENT</li>
              <li>B. INFORMED CONSENT</li>
              <li>C. FEE SCHEDULE</li>
            </ol>
          </div>

          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-pink-600">
              SERVICE AGREEMENT
            </h3>
            <ul className="list-disc  text-gray-700 mt-4">
              <li>
                As per Association for the Advancement of Blood & Biotherapies
                (AABB) and International Standards, this form should be signed
                before the birth of your baby.
              </li>
              <li>
                Complete all parts of this form. This includes your full
                details, clinic/hospital, physician/ midwife, delivery due date,
                details for multiple births (if applicable)
              </li>
              <li>
                It is important that we have the Clients’ full names as per the
                passport.
              </li>
            </ul>
          </div>

          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-pink-600">
              INFORMED CONSENT
            </h3>
            <ul className="list-disc  text-gray-700 mt-4">
              <li>
                As per AABB and International Standards, this form should be
                signed before the birth of your baby.
              </li>
              <li>Complete all parts of this form.</li>
              <li>
                TThis form gives us the consent for Cord Blood, Cord Tissue,
                Placental Tissue, Amnion Placental Tissue, and Cord Vessel
                collection.
              </li>
              <li>
                It is important that we have the Clients’ full names as per the
                passport.
              </li>
            </ul>
          </div>

          {/* Added content from the second image */}
          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-pink-600">FEE SCHEDULE</h3>
            <ul className="list-disc  text-gray-700 mt-4">
              <li>
                This form provides the summary of fees payable, and will be in a
                separate page from this document.
              </li>
            </ul>
          </div>

          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-blue-600">
              A. SERVICE AGREEMENT
            </h3>
            <p className="mt-4 text-gray-700">
              For the processing, testing, cryopreservation, and storage of Cord
              Blood Stem Cells and/or (if applicable), Cord Tissue/Placental
              Tissue/Amnion Placental Tissue/Cord Vessel, between Abu Dhabi Stem
              Cells Center (ADSCC), an establishment duly created under the laws
              of Abu Dhabi, United Arab Emirates, located at 4600, Al Misahah
              street, Rawdhat, Abu Dhabi, United Arab Emirates, operating under
              the Trade License number CN-2751549, known herein as “ADSCC ”,
              CellSave Arabia FZ- LLC, a company with limited liability
              registered under the Dubai Healthcare City (DHCC), organised,
              existing, and operating under the laws of the United Arab
              Emirates; with its registered office at P.O. Box 505152, Dubai,
              United Arab Emirates, known herein as “CSA”; and the
              Parent(s)/Legal Guardian(s) listed below on their behalf and the
              behalf of the (unborn) Child, hereinafter jointly to be referred
              to as the “Client” or the “Clients”, ADSCC/CSA and the Client,
              together, the “Parties”.
            </p>

            <p className="mt-4 text-gray-700 font-bold">
              (Client to fill in the relevant fields below)
            </p>
          </div>

          {/* New Form Section */}
          <div className="mt-8  text-left">
            <h3 className="text-xl my-2 font-bold text-pink-600">
              MOTHER/LEGAL GUARDIAN
            </h3>
            <form className="space-y-6">
              <div>
                <p>
                  <label className="block text-gray-700">
                    Last Maiden Name:
                  </label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">First Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Address:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Country:</label>
                </p>
                <select className="mt-1 block w-full p-2 !h-[50px] border border-gray-300 rounded-md">
                  <option value="">Select Country</option>
                  {/* Add more country options here */}
                </select>
              </div>
              <div className="page-break">
                <p>
                  <label className="block text-gray-700">City:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Postal Code:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Date of Birth:</label>
                </p>
                <p>
                  <input
                    type="date"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Date"
                    required
                  />
                </p>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Telephone:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Mobile:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Email Address:</label>
                </p>
                <input
                  type="email"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  Mother Passport/EID:
                </label>
                <input
                  type="file"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </form>
          </div>

          {/* Father/Legal Guardian Form Section */}
          <div className="mt-8 text-left">
            <h3 className="text-xl mb-8 font-bold text-pink-600">
              FATHER/LEGAL GUARDIAN
            </h3>
            <form className="space-y-6">
              <div>
                <p>
                  <label className="block text-gray-700">Last Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">First Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Address:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Country:</label>
                </p>
                <select className="mt-1 block w-full p-2 !h-[50px] border border-gray-300 rounded-md">
                  <option value="">Select Country</option>
                  {/* Add more country options here */}
                </select>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">City:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Postal Code:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Date of Birth:</label>
                </p>
                <p>
                  <input
                    type="date"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Date"
                    required
                  />
                </p>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Telephone:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Mobile:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Email Address:</label>
                </p>
                <input
                  type="email"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  Father Passport/EID:
                </label>
                <input
                  type="file"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </form>
          </div>

          {/* Clinic/Hospital Form Section */}
          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-pink-600 mb-8">
              CLINIC/HOSPITAL
            </h3>
            <form className="space-y-6">
              <div>
                <p>
                  <label className="block text-gray-700">Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Country:</label>
                </p>
                <select className="mt-1 block w-full !h-[50px] p-2 border border-gray-300 rounded-md">
                  <option value="">Select Country</option>
                  {/* Add more country options here */}
                </select>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">City:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </form>
          </div>

          {/* Physician Form Section */}
          <div className="mt-8 text-left">
            <h3 className="text-xl mb-8 font-bold text-pink-600">PHYSICIAN</h3>
            <form className="space-y-6">
              <div>
                <p>
                  <label className="block text-gray-700">Last Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">First Name:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">Country:</label>
                </p>
                <select className="mt-1 block w-full !h-[50px] p-2 border border-gray-300 rounded-md">
                  <option value="">Select Country</option>
                  {/* Add more country options here */}
                </select>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">City:</label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">
                    Due Date for Delivery:
                  </label>
                </p>
                <p>
                  {" "}
                  <input
                    type="date"
                    className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Date"
                    required
                  />
                </p>
              </div>
              <div>
                <label className="block text-gray-700">
                  Multiple Births Expected:
                </label>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="multiple_births"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      name="multiple_births"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
                <div className="mt-2">
                  <p>
                    <label className="block text-gray-700">
                      If Yes, how many?
                    </label>
                  </p>
                  <input
                    type="number"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label className="block text-gray-700">
                  I (We) have previously-stored cord blood with CELLSAVE.
                </label>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="previous_cord_blood"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      name="previous_cord_blood"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </div>
              <div>
                <p>
                  <label className="block text-gray-700">
                    Reference of the Previous Child:
                  </label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </form>
          </div>

          {/* Service Choice Section */}
          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-pink-600">Service choice:</h3>
            <p className="mt-4 text-gray-700 font-bold">
              I (We), the undersigned Parent(s)/ and/or Legal Guardian(s) hereby
              confirm the following specific service from ADSCC/CSA:
            </p>
            <form className="space-y-6">
              <div>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="service_choice"
                    className="form-radio text-pink-600"
                  />
                  <span className="ml-2">CryoPlus</span>
                </label>
                <label className="flex items-center mt-2">
                  <input
                    type="radio"
                    name="service_choice"
                    className="form-radio text-pink-600"
                  />
                  <span className="ml-2">CryoAdvanced</span>
                </label>
                <label className="flex items-center mt-2">
                  <input
                    type="radio"
                    name="service_choice"
                    className="form-radio text-pink-600"
                  />
                  <span className="ml-2">CryoUltimate</span>
                </label>
                <label className="flex items-center mt-2">
                  <input
                    type="radio"
                    name="service_choice"
                    className="form-radio text-pink-600"
                  />
                  <span className="ml-2">CryoInfinite</span>
                </label>
              </div>

              <div className="mt-4">
                <p className="text-gray-700 font-bold">
                  Add a 5 chamber Storage Bag option -{" "}
                  <span className="text-pink-600">
                    You will be charged extra 1,000 AED?
                  </span>
                </p>
                <div className="mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      name="storage_bag"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">Yes</span>
                  </label>
                  <label className="inline-flex items-center ml-6">
                    <input
                      type="radio"
                      name="storage_bag"
                      className="form-radio text-pink-600"
                    />
                    <span className="ml-2">No</span>
                  </label>
                </div>
              </div>

              <div className="mt-4">
                <p>
                  <label className="block text-pink-600 font-bold">
                    KIT ID:
                  </label>
                </p>
                <input
                  type="text"
                  className="mt-1 block w-1/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
            </form>
          </div>

          {/* General Terms and Conditions Section */}
          <div className="mt-8 text-left">
            <h3 className="text-xl font-bold text-blue-600">
              GENERAL TERMS AND CONDITIONS
            </h3>

            {/* Purpose Section */}
            <div className="mt-4">
              <h4 className="text-lg font-bold text-pink-600">1. PURPOSE:</h4>
              <p className="mt-4 text-gray-700">
                The purpose of this document is to set out the terms,
                conditions, limitation, risks, and costs of the processing,
                testing, cryopreservation, and storage in an authorized cellular
                processing and storage unit release of the Product (as defined
                below) obtained from the Specimen (as defined below) collected
                at the time of birth of the Child (as defined below) of the
                Client.
              </p>
              <p className="mt-4 text-gray-700">
                In this Service Agreement, the following definitions shall have
                the following meaning:
              </p>

              <ul className="mt-4 text-gray-700 space-y-2">
                <li>
                  <span className="font-bold">“Amnion Placental Tissue”</span> -
                  the thin and elastic membrane lining inside of the placental
                  sac.
                </li>
                <li>
                  <span className="font-bold">“Child”</span> - “Any child
                  identified by the Client in the Informed Consent in Section B;
                </li>
                <li>
                  <span className="font-bold">“Blood Cord”</span> - Blood
                  collected from the umbilical cord and placenta of the Child
                  following delivery;
                </li>
                <li>
                  <span className="font-bold">“Cord Tissue”</span> - Tissue
                  taken from the umbilical cord of the Child following delivery;
                </li>
                <li>
                  <span className="font-bold">“Cord Vessel”</span> - Cell types
                  that lines the Cord Tissue taken from the Child following
                  delivery;
                </li>
                <li>
                  <span className="font-bold">“Enrollment Fee”</span> - The
                  meaning ascribed to it in the Fee Schedule in Section C;
                </li>
                <li>
                  <span className="font-bold">“Extended Storage Period”</span> -
                  The meaning ascribed to it in Section 6;
                </li>
                <li>
                  <span className="font-bold">“Fees”</span> - The meaning
                  ascribed to it in Section 4;
                </li>
                <li>
                  <span className="font-bold">“Informed Consent”</span> - The
                  consent attached to this Service Agreement under which the
                  Client gives their informed consent to the storage of the
                  specimen;
                </li>
                <li>
                  <span className="font-bold">“Initial Storage Period”</span> -
                  The meaning ascribed to it in Section 6;
                </li>
                <li>
                  <span className="font-bold">“Maternal Blood Test”</span> -
                  Tests which must be undertaken on the blood of the mother of
                  the child (as identified and approved in the Informed Consent
                  in Section B) to assess whether the Specimen is suitable for
                  storage in accordance with international and local guidelines
                  and regulations and as otherwise required thereunder;
                </li>
                <li>
                  <span className="font-bold">
                    “Medical and Health History Profile”
                  </span>{" "}
                  - The questionnaire completed by the Client under which the
                  Client provides CELLSAVE with certain information about
                  his/her/their medical history. Refer to separate Medical and
                  Health History Profile booklet;
                </li>
                <li>
                  <span className="font-bold">“Processing Fee”</span> - The
                  meaning ascribed to it in the Fee Schedule in Section C;
                </li>
                <li>
                  <span className="font-bold">“Placental Tissue”</span> - Organ
                  that connects the developing fetus to the uterine wall;
                </li>
                <li>
                  <span className="font-bold">“Storage Fee”</span> - The meaning
                  ascribed to it in the Fee Schedule in Section C;
                </li>
                <li>
                  <span className="font-bold">“Product”</span> - The
                  cryopreserved Stem Cells and the cryopreserved Cord Tissue
                  (and, in the event that the CryoAdvanced Service is elected,
                  the cryopreserved Stem Cells, the cryopreserved Cord Tissue,
                  and the cryopreserved Placental Tissue), (and, in the event
                  that the CryoUltimate Service is elected, the cryopreserved
                  Stem Cells, the cryopreserved Cord Tissue, the cryopreserved
                  Placental Tissue, the cryopreserved Amnion Tissue, and the
                  cryopreserved Cord Vessel);
                </li>
                <li>
                  <span className="font-bold">“Release Form”</span> - The
                  meaning ascribed to it in Section 8;
                </li>
                <li>
                  <span className="font-bold">“Service Agreement”</span> - This
                  Service Agreement between the Client and CELLSAVE which shall
                  include the Service Agreement in Section A, the general terms
                  and conditions, the informed consent in Section B, the Medical
                  Health History Profile booklet, and the fee schedule in
                  Section C;
                </li>
                <li>
                  <span className="font-bold">“Specimen”</span> - The Cord Blood
                  and Cord Tissue (and in the event that the CryoAdvanced
                  Service is elected, the Cord Blood, Cord Tissue, and Placental
                  Tissue), (and in the event that the CryoUltimate Service is
                  elected, the Cord Blood, Cord Tissue, Placental Tissue, Amnion
                  Placental Tissue, and Cord Vessel);
                </li>
                <li>
                  <span className="font-bold">“Stem Cells”</span> - The Stem
                  Cells extracted from the Cord Blood;
                </li>
                <li>
                  <span className="font-bold">“Unit”</span> - The meaning
                  ascribed to it above;
                </li>
              </ul>

              <p className="mt-4 text-gray-700">
                By signing this Service Agreement, the Client agrees to be
                legally bound to CELLSAVE for the processing, testing,
                cryopreservation, and storage of the Specimen in any Unit. The
                Client also acknowledges that he/ she/they has/ have been fully
                informed, and accept(s) and agree(s) to the conditions, risks,
                limitations, and associated costs of such activities, in
                accordance with these terms and conditions.
              </p>
            </div>
          </div>

          {/* Services of CELLSAVE Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              2. SERVICES OF ADSCC/CSA:
            </h4>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA’s obligations and liabilities are expressly limited to
              the services described in this Service Agreement; no other
              services will be provided by ADSCC/CSA to the Client and ADSCC/CSA
              expressly disclaims any responsibility to provide any other
              services other than the ones stated in this Service Agreement.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA will not put the Specimen to use for any purpose other
              than to enable ADSCC/CSA to provide the Service to the Client as
              per this Service Agreement.
            </p>
            <p className="mt-4 text-gray-700">
              If the Client elects to use the ADSCC/CSA services the following
              provisions shall apply:
            </p>

            <p className="mt-4 text-gray-700">
              On receipt of the Specimen, ADSCC/CSA will use best endeavors to
              process and cryopreserve Stem Cells from the Specimen for the
              purpose of direct application in humans, provided a sufficient
              number of viable Stem Cells as per its acceptance criteria can be
              identified, ADSCC/CSA will store the Product for the Initial
              Storage Period (as defined in Section 6 below) or longer as agreed
              in accordance with Section 6.
            </p>

            <p className="mt-4 text-gray-700">
              In the event, after processing has commenced, there are
              insufficient Stem Cells in the Product or ADSCC/CSA is unable to
              harvest collected Stem Cells from the Specimen or if ADSCC/CSA
              determines that storage for other reasons is not possible (e.g.
              the Specimen is damaged or contaminated), no storage will take
              place and refunds will be as stipulated in Section C of the Fee
              schedule.
            </p>

            <p className="mt-4 text-gray-700">
              In the event where processing has not taken place due to reasons
              such as Specimen is received by ADSCC/CSA more than 72 hours after
              the birth of the Child, or the collected Specimen volume is too
              small, refunds will be as stipulated in section C of the Fee
              schedule.
            </p>

            <p className="mt-4 text-gray-700">
              With respect to Cord Tissue, on receipt of the Specimen, ADSCC/CSA
              will use its best endeavors to prepare it for cryopreservation and
              storage. In the event that the Cord Tissue has not been collected
              following the instructions, or if ADSCC/CSA determines that
              storage is for other reasons not possible (e.g. the Specimen is
              received by ADSCC/CSA more than 72 hours after the birth of the
              Child; the Specimen is damaged or contaminated), refunds will be
              as stipulated in section C of the Fee schedule.
            </p>

            <p className="mt-4 text-gray-700">
              If the sample/unit is being used for a transplant within 5 years
              (from the date of delivery of the baby), ADSCC/CSA will give the
              Client a “transplant assistance” valued at 15,000 AED.
            </p>
          </div>

          {/* Responsibilities of Client Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              3. RESPONSIBILITIES OF CLIENT:
            </h4>
            <p className="mt-4 text-gray-700">
              The Client agrees to complete all necessary forms accurately and
              in a timely manner as ADSCC/CSA may from time to time reasonably
              consider as required or deemed beneficial to the Service in view
              of applicable legislation or industry standards.
            </p>
            <p className="mt-4 text-gray-700">
              The Client is responsible for arranging the collection of the
              Specimen by the physician, obstetrician, or other medical staff at
              the birth of the Child as per the instructions received from
              ADSCC/CSA. It is the responsibility of the Client to either
              contact ADSCC/CSA or the courier company designated by ADSCC/CSA
              after the Specimen has been collected.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA will arrange for transport of the Specimen to the
              ADSCC/CSA designated Unit, on the Client’s behalf, immediately
              after having received notification by the Client of the collection
              of the Specimen by the physician, obstetrician, or other medical
              staff at the birth of the Child. ADSCC/CSA expressly excludes all
              liability for the collection of the Specimen by the physician,
              obstetrician, or other medical staff at the birth of the Child.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA expressly excludes all liability for the transportation
              of the Specimen if done by a third party.
            </p>
            <p className="mt-4 text-gray-700">
              The Client agrees to act at all times in accordance with all
              applicable local legislation and regulations and will be
              responsible for obtaining all necessary documents and permits if
              so required for the collection and export of the Specimen to the
              Unit.
            </p>
          </div>

          {/* Fees and Payments Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              4. FEES AND PAYMENTS:
            </h4>
            <p className="mt-4 text-gray-700">
              The fee for the Service (the “Fee”) and the payment conditions
              applicable to the Service are laid down in the Fee Schedule in
              Section C. The Fee consists of a non-refundable Enrollment Fee and
              a Processing and Storage Fee and certain other fees, in each case
              as further described in the Fee Schedule in Section C. Client
              agrees to pay the Fees on time and in accordance with the Fee
              Schedule in Section C.
            </p>
            <p className="mt-4 text-gray-700">
              Clients understand and agree that they are jointly and severally
              liable for payment of the Fees, which means that all of the
              Clients are liable to pay the Fees, and each Client is jointly and
              severally liable for any unpaid portion of the total fee. This
              applies even if Clients who are married or live together become
              divorced, separated, or otherwise estranged.
            </p>
          </div>

          {/* Storage Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">5. STORAGE:</h4>
            <p className="mt-4 text-gray-700">
              The Specimen and Product will be processed, tested, cryopreserved,
              and stored in accordance with the following terms:
            </p>

            <ul className="mt-4 text-gray-700 space-y-2">
              <li>
                <span className="font-bold">a.</span>ADSCC/CSA stores the
                Product in an authorized Unit. The Cord Blood Product will be
                split and stored in two physically separate locations.
              </li>
              <li>
                <span className="font-bold">b.</span>ADSCC/CSA may assign and
                transfer any or all of its rights and obligations under this
                Service Agreement to any third party, for which the Client
                hereby gives consent. ADSCC/CSA will notify the Client in
                writing to that effect.
              </li>
              <li>
                <span className="font-bold">c.</span> ADSCC/CSA does not give,
                nor does it purport to give, any medical advice, or otherwise
                perform any other function for the Client other than the
                services expressly described in this Service Agreement.
              </li>
              <li>
                <span className="font-bold">d.</span> Client hereby consents to
                the collection, transport, and testing of the Specimen and to
                the processing, cryopreservation, and storage of the Specimen in
                and to a Unit as stated by this Service Agreement.
              </li>
              <li>
                <span className="font-bold">e.</span> The Client’s right to
                storage and release of the Product is subject to the timely and
                full payment of all Fees by the Client in accordance with this
                Service Agreement.
              </li>
            </ul>
          </div>

          {/* Terms and Termination Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              6. TERMS AND TERMINATION:
            </h4>
            <p className="mt-4 text-gray-700">
              These terms and conditions come into force on the date of receipt
              by ADSCC/CSA of the Service Agreement signed by the Client and
              will continue for the period mentioned in the Payment Plan from
              the date of the birth of the Child (the “Initial Storage Period”).
            </p>
            <p className="mt-4 text-gray-700">
              Six months prior to the end of the Initial Storage Period, an
              agreement can be made upon the mutual agreement of the Parties in
              the event that Client wishes ADSCC/CSA to continue to store the
              Product on their behalf for a further period (the “Extended
              Storage Period”), and such extended storage shall be at the market
              prices valid at that time. If 30 days before the expiry of this
              Service Agreement, Parties have not agreed to the terms of an
              Extended Storage Period of the Product, or upon the termination of
              this Service Agreement by ADSCC/CSA or the Client in accordance
              with this Clause 6, the Client relinquishes all rights in and
              waives all rights to the Product and ADSCC/CSA shall have the
              right to destroy the Product.
            </p>
            <p className="mt-4 text-gray-700">
              This Service Agreement may be terminated prior to its term by
              written notice with immediate effect:
            </p>
            <ul className="mt-4 text-gray-700 space-y-2">
              <li>
                <span className="font-bold">a.</span> By Client at any time
                prior to the collection of the Specimen. In this case, a minimal
                fee will be charged as indicated in the Fee Schedule in Section
                C.
              </li>
              <li>
                <span className="font-bold">b.</span> B By ADSCC/CSA if the
                Specimen and/or Product are not suitable for storage, whether
                for medical, safety, practical, or any other reason, as
                determined by ADSCC/CSA in its sole and absolute discretion
                (e.g. the Specimen is received by ADSCC/CSA more than 72 hours
                after the birth of the Child; the Specimen is damaged; the
                collected Specimen volume is too small). In these situations,
                ADSCC/CSA will not store the Specimen and/or ADSCC/CSA retains
                the sole right to make this decision.
              </li>
              <li>
                <span className="font-bold">c.</span> By ADSCC/CSA if ADSCC/CSA
                does not receive the Enrollment Fee and/or Processing and
                Storage Fee within the specific time.
              </li>
            </ul>
            <p className="mt-4 text-gray-700">
              Termination of this Service Agreement under clause 6c above will
              not affect the Client’s responsibility for payment in full of all
              amounts invoiced and of all additional administrative and legal
              costs related to collecting the outstanding amounts. On
              termination of this Service Agreement, ADSCC/CSA reserves the
              right to destroy the Specimen and/or the Product.
            </p>
          </div>

          {/* Rights to Product Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              7. RIGHTS TO PRODUCT:
            </h4>
            <p className="mt-4 text-gray-700">
              Unless otherwise defined by applicable law, the Product remains
              the property of the Child throughout the term of this Agreement.
              Until the Child reaches legal age, the Client has the right and
              responsibility to consent to the collection, storage, use, and
              disposal of the Product on behalf of the Child.
            </p>
          </div>

          {/* Release Request Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              8. RELEASE REQUEST:
            </h4>
            <p className="mt-4 text-gray-700">
              In the event that the Product is required for treatment or
              manufacturing of a medicinal product, the Client shall provide
              written notification of the same to ADSCC/CSA. Any request for
              release has to be addressed to ADSCC/CSA, P.O. Box 505152, Dubai
              Healthcare City, Dubai, UAE, to the attention of the Medical
              Director, or to the e-mail address{" "}
              <a href="mailto:info@adscc.ae" className="text-blue-600">
                info@adscc.ae
              </a>{" "}
              /{" "}
              <a
                href="mailto:infoarabia@cellsave.com"
                className="text-blue-600"
              >
                {" "}
                infoarabia@cellsave.com
              </a>
              . The notice shall include the name and address of the physician
              and hospital to which the Product must be sent or the
              appropriately accredited Current Good Manufacturing Practices
              (cGMP) manufacturer. ADSCC/CSA shall then provide the authorized
              hospital or manufacturer with a release form (the “Release Form”)
              which must be returned to ADSCC/CSA. ADSCC/CSA will arrange for
              transport of the Product to the hospital, on the Client’s behalf.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA expressly excludes all liability for the transportation
              of the Product conducted by a third party.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA will not charge the Client any costs involved with the
              transport of the Product to the destination indicated on the
              Release Form.
            </p>
          </div>

          {/* Acknowledgement Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              9. ACKNOWLEDGEMENT:
            </h4>
            <p className="mt-4 text-gray-700">
              The Client understands that the actual collection of the Specimen
              by the physician, obstetrician, or other medical staff at the
              birth of the Child is not part of the Service and that the
              relevant doctor, obstetrician, or other medical staff may under
              certain circumstances refuse, be unable or fail to collect and/or
              adequately pack the Specimen and that neither ADSCC/CSA nor its
              officers, shareholders, employees, agents, and contractors bear
              any responsibility for any mistakes or damages caused by such
              doctor, obstetrician or other medical staff.
            </p>
            <p className="mt-4 text-gray-700">
              The Client also understands and confirms that ADSCC/CSA, its
              officers, directors, employees, shareholders, agents, and
              consultants have not made any representations and bear no
              responsibilities and obligations with respect to the possibility
              to collect Stem Cells successfully from the Specimen, the
              suitability of the Stem Cells for the contemplated treatment, the
              successful treatment of any diseases through Stem Cell
              transplantation or therapies, and the advantages of umbilical Cord
              Blood Stem Cell usage over other therapies using Stem Cells.
            </p>
          </div>

          {/* Limitation of Liability Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              10. LIMITATION OF LIABILITY:
            </h4>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA’s responsibility towards the Client and/or the Child
              whose Product/s are being stored is limited to the Service
              described in this Service agreement. Client agrees that should
              he/she/they make any claim against ADSCC/CSA, ADSCC/CSA’s
              liability shall, to the extent allowed under applicable law,
              regardless of the basis of such claim, whether in contract, tort
              or otherwise, be limited in total and in aggregate to (a) the
              amount of the Fee paid by the Client to ADSCC/CSA under this
              Service Agreement, or (b) in the event of ADSCC/CSA’s negligence,
              the amount permitted to be recovered by ADSCC/CSA under the
              malpractice insurance maintained by ADSCC/CSA in connection with
              such claims.{" "}
            </p>
          </div>

          {/* Data Protection Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              11. DATA PROTECTION:
            </h4>
            <p className="mt-4 text-gray-700">
              The Client agrees to the processing and storage of personal data
              (including personal data of the Child and the mother and father of
              the Child), by ADSCC/CSA or its agent, affiliate, or
              subcontractor, which has come to ADSCC/CSA’s knowledge in the
              course of performing the Services and otherwise under this Service
              Agreement. The Client agrees and permits ADSCC/CSA to inform the
              status of infectious disease report of the mother of the Child and
              the Cord Blood and Cord Tissue and/or Placental Tissue/Amnion
              Placental Tissue/Cord Vessel to the United Arab Emirates
              Government regulatory agencies including the Ministry of Health,
              the Dubai Health Authority, Dubai Healthcare City Authority or any
              other agency mandated under United Arab Emirates laws or under any
              other law to which ADSCC/CSA is subject.
            </p>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA will use the Client’s personal data only to the extent
              required by its contractual obligations hereunder and in
              accordance with the applicable laws and regulations of the United
              Arab Emirates.
            </p>
          </div>

          {/* Force Majeure Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              12. FORCE MAJEURE:
            </h4>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA cannot be held liable for, and expressly excludes all
              liability for, any possible loss or damage due to natural
              disasters, an act of war or terror, riot, strike, vandalism, acts
              or omission of authorities, or any events beyond ADSCC/CSA‘s
              control which cause destruction of, or deterioration to the
              Specimen and/or Product.
            </p>
          </div>

          {/* Notice Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">13. NOTICE:</h4>
            <p className="mt-4 text-gray-700">
              If the Client needs to send ADSCC/CSA any notices or
              communications under the Service Agreement, the Client must send
              them in writing by first-class or registered post/recorded
              delivery to the ADSCC/CSA address as set out in Section 13 below
              or any alternative address that ADSCC/CSA notification to the
              Client.
            </p>
            <p className="mt-4 text-gray-700">
              If ADSCC/CSA needs to send the Client any notices or
              communications under the Service Agreement ADSCC/CSA will send
              them in writing by first-class or registered post/recorded
              delivery to the Client’s address as notified by the Client to
              ADSCC/CSA on page 1. The Client shall as soon as possible notify
              ADSCC/CSA in writing of any changes in the information provided,
              including relocation and/or change of address. All notifications
              to ADSCC/CSA shall be sent to ADSCC/CSA Arabia, P.O. Box 505152,
              Dubai Healthcare City, Al Razi Medical Complex 64, Dubai, UAE, or
              to the e-mail address: infoarabia@cellsave.com. ADSCC/CSA may
              completely rely on the data made available in writing by the
              Client, without any obligation to make further inquiries with
              respect to the correctness or actuality of such data.
            </p>
          </div>

          {/* Compliance with UAE Laws Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              14. COMPLIANCE WITH UAE LAWS AND REGULATIONS:
            </h4>
            <p className="mt-4 text-gray-700">
              ADSCC/CSA operates and exists in the United Arab Emirates and thus
              is required to comply with the laws of the United Arab Emirates
              and the regulatory and legal requirements of governmental agencies
              such as the Ministry of Health, the Dubai Health Authority, and
              the Dubai Healthcare City Authority. The Client hereby agrees that
              ADSCC/CSA may take any action required by it in order to comply
              with the laws of the United Arab Emirates, as well as the
              regulatory and legal requirements of governmental agencies such as
              the Ministry of Health, the Dubai Health Authority, and the Dubai
              Healthcare City.
            </p>
          </div>

          {/* Governing Law Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              15. GOVERNING LAW:
            </h4>
            <p className="mt-4 text-gray-700">
              This Service Agreement shall be governed by, and construed in
              accordance with, the laws of United Arab Emirates. The Parties may
              bring suit in any court of competent jurisdiction.
            </p>
          </div>

          {/* Electronic Communication Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              16. ELECTRONIC COMMUNICATION:
            </h4>
            <ul className="mt-4 text-gray-700 space-y-2">
              <li>
                <span className="font-bold">a.</span> The Client(s) acknowledges
                that this agreement does not infringe any provision of the
                Federal Law No. (1) on Electronic Commerce and Transactions, of
                2006.
              </li>
              <li>
                <span className="font-bold">b.</span> This agreement shall
                constitute a valid and binding agreement in accordance with
                Article 18 of the Federal Law No. (1) on Electronic Commerce and
                Transactions of 2006. No written confirmation or reciprocal
                signature by the Client(s) and/or ADSCC/CSA is required to give
                effect to the rights and obligations established by the Service
                Agreement.
              </li>
            </ul>
          </div>

          {/* Headings Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">17. HEADINGS:</h4>
            <p className="mt-4 text-gray-700">
              The headings contained in this Service Agreement are for reference
              purposes only and shall not affect the meaning or interpretation
              of this Service Agreement.
            </p>
          </div>

          {/* Severability Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              18. SEVERABILITY:
            </h4>
            <p className="mt-4 text-gray-700">
              Should any provision of this Service Agreement be invalid or
              unenforceable or should this Service Agreement contain an
              omission, the remaining provisions shall remain valid. In the
              place of an invalid provision or an omission, a new, valid
              provision – which comes economically closest to the one actually
              agreed upon provision or, in the event of an omission, the
              intentions set forth in this Service Agreement – is presumed to be
              agreed upon by the Parties.
            </p>
          </div>

          {/* Entire Agreement Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">
              19. ENTIRE AGREEMENT:
            </h4>
            <p className="mt-4 text-gray-700">
              This Service Agreement constitutes the entire understanding
              between the Parties with respect to the subject matter of this
              Service Agreement and supersedes all prior agreements,
              negotiations, and discussions between the Parties relating to it.
            </p>
          </div>

          {/* Amendment Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-pink-600">20. AMENDMENT:</h4>
            <p className="mt-4 text-gray-700">
              Neither Party can change or amend the terms of this Service
              Agreement without the prior written approval of the other Party.
            </p>
          </div>

          {/* Informed Consent Section */}
          <div className="mt-8 text-left">
            <h4 className="text-lg font-bold text-blue-600">
              B. INFORMED CONSENT
            </h4>
            <p className="mt-4 text-gray-700">
              I/We on behalf of my/our unborn Child wish to enroll in the
              Umbilical Cord Blood and Cord Tissue (and/or, if applicable,
              Placental Tissue/Amnion Placental Tissue/Cord Vessel) banking
              service offered by ADSCC/CSA. The Specimen will be uniquely
              identified, stored, and maintained at a cryogenic storage
              facility. ADSCC/CSA will retrieve these cells at my/our request as
              per the Service Agreement. I/We agree to its terms of
              understanding as outlined below, and consent to have the Service
              performed.
            </p>
            <p className="mt-4 text-gray-700">
              I/We understand that collection and storing my/our newborn’s Stem
              Cells may potentially benefit my/our Child should he or she need
              them in the future to treat certain diseases. I/We understand that
              it is not possible to determine whether my/our Child will develop
              a disease in the future that can be treated with these cells. I/We
              understand that these newborn Stem Cells are a perfect match for
              my/our Child and, while there is no guarantee my/our child will
              ever need them, the fact that they are a perfect match can reduce
              serious complications should cell therapy ever be needed. I/We
              understand that my Child’s cells may be suitable for use by other
              family members.
            </p>
            <p className="mt-4 text-gray-700">
              I/We understand that, although infrequent, complications may occur
              at birth, and it may not be possible to collect Cord Blood and
              Cord Tissue (and/or, if applicable, Placental Tissue/ Amnion
              Placental Tissue /Cord Vessel) from my/our Child. Therefore,
              collection of Cord Blood and Cord Tissue (and/ or, if applicable,
              Placental Tissue/ Amnion Placental Tissue/Cord Vessel) from my/our
              Child cannot be guaranteed as its collection is arranged between
              my/ our physician/midwife and me/us. My/Our health and the health
              of my/our newborn is my/our physician/midwife’s first priority.
              I/We agree that my/our physician/midwife’s judgment shall be
              absolute and final.
            </p>
            <p className="mt-4 text-gray-700">
              I/We understand that ADSCC/CSA will perform cell viability, cell
              counts, blood typing, and bacterial and fungal tests on the Cord
              Blood and Cord Tissue (and/or, if applicable, Placental Tissue/
              Amnion Placental Tissue /Cord Vessel) to determine the nature and
              quality of the Cord Blood and Cord Tissue (and/or, if applicable,
              Placental Tissue/ Amnion Placental Tissue/Cord Vessel). I/We
              understand that regulatory and accreditation associations require
              certain tests and I/we fully consent to provide the Maternal Blood
              Test. I/We consent to the use of a sample from the blood of the
              mother of the Child to be tested for certain infectious diseases .
              This is in accordance with the AABB, EU and International
              Directives, guidelines for tissue banks, and as otherwise required
              under the laws of the United Arab Emirates.
            </p>

            {/* Additional Content */}
            <p className="mt-4 text-gray-700">
              I/We understand that if the test results of the sample are
              confirmed positive for HIV, the Cord Blood and Cord Tissue
              (and/or, if applicable, Placental Tissue/ Amnion Placental Tissue
              /Cord Vessel) will not be stored. I/We understand that if the
              tests indicate the possible presence of hepatitis B or C,
              syphilis, or if my responses to the health questionnaire indicate
              a risk of one of these infections, my/our Child’s Cord Blood and
              Cord Tissue (and/or, if applicable, Placental Tissue/ Amnion
              Placental Tissue /Cord Vessel) will be processed and cryopreserved
              only with the approval of the ADSCC/CSA Medical Director. Based on
              the review of these results, I/we understand that ADSCC/CSA may
              decide not to permanently store the Cord Blood and Cord Tissue
              (and/or, if applicable, Placental Tissue/ Amnion Placental
              Tissue/Cord Vessel). If this occurs, I/we will be notified in
              writing of this decision. I/We authorize ADSCC/CSA to provide me
              with test results and to furnish them to my primary physician, my
              partner, or/and to government agencies as only required by law. If
              the Maternal Blood Test is positive for one of the infectious
              diseases tested for, the Client(s) should sign a continuation form
              provided by ADSCC/CSA.
            </p>
            <p className="mt-4 text-gray-700">
              I/We understand that ADSCC/CSA will follow the United Arab
              Emirates regulations and will follow the same regulations for Cord
              Blood, Cord Tissue, Placental Tissue, Amnion Placental Tissue/Cord
              Vessel collection, and the Maternal Blood Test. I/We understand
              that as per the regulations of the United Arab Emirates, ADSCC/CSA
              processes and cryopreserves Cord Blood, Cord Tissue, Placental
              Tissue, Amnion Placental Tissue, and Cord Vessel collected from
              full-term live babies only, and does not process or cryopreserve
              Cord Blood, Cord Tissue, Placental Tissue, Amnion Placental
              Tissue, and Cord Vessel collected from an abortus. I/We understand
              that the Client(s) are responsible for the barcodes provided to
              him/her/them and issued by ADSCC/CSA.
            </p>
          </div>

          {/* Agreement Checklist Section */}
          <div className="mt-8 text-left">
            <p className="font-bold text-gray-900">
              Please tick below to agree.
            </p>

            <div className="mt-4 space-y-4">
              <label className="flex items-start">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-pink-600"
                />
                <span className="ml-3 text-gray-700 leading-normal w-full">
                  I /We, the undersigned Parents(s)/ and/ or Legal Guardian(s)
                  hereby accept the General Terms and Conditions, which are
                  attached to this Service Agreement.
                </span>
              </label>
              <label className="flex items-start">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-pink-600"
                />
                <span className="ml-3 text-gray-700 leading-normal w-full">
                  I/We (on behalf of myself and the other Client) affirm that
                  I/We have fully understood the information provided and have
                  had the opportunity to ask questions and have been provided
                  with satisfactory responses.
                </span>
              </label>
              <label className="flex items-start">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-pink-600"
                />
                <span className="ml-3 text-gray-700 leading-normal w-full">
                  I/We herewith give my/our Informed Consent for the Cord Blood
                  and Cord Tissue (and/or, if applicable, Placental
                  Tissue/Amnion Placental Tissue/Cord Vessel) Collection.
                </span>
              </label>
              <label className="flex items-start">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-pink-600"
                />
                <span className="ml-3 text-gray-700 leading-normal w-full">
                  I/We, the undersigned Parent(s) and/or Legal Guardian hereby
                  accept the Fee Schedule.
                </span>
              </label>
              <label className="flex items-start">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-pink-600"
                />
                <span className="ml-3 text-gray-700 leading-normal w-full">
                  I/We understand and agree that I/We are jointly and severally
                  liable for payment of the Fees.
                </span>
              </label>
            </div>

            <p className="mt-8 text-pink-600 font-bold">
              Please enter your Full Name and Email Address to receive a copy of
              agreement.
            </p>
            <p className="mt-2 text-xs text-gray-500">
              Code: C.2.50-4D CSA Agreement-EN ADSCC
            </p>
            <div className="flex flex-col mt-4 space-y-4">
              <input
                type="text"
                placeholder="Your legal name"
                className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="email"
                className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Your email address"
                name="signed-email"
                required
                // onChange={handleEmailChange}
              />

              <input
                type="date"
                className="mt-2 block w-full p-2 border border-gray-300 rounded-md"
                placeholder="Date"
                required
              />
            </div>

            {/* Signature Section */}
            {/* <div className="mt-8 flex items-center mt-20 mb-20">
              <div className="relative">
                <p className="text-gray-400 text-xl">
                  <span className="text-gray-700">X</span> _ _ _ _ _ _ _ _ _ _ _
                  _ _ _
                </p>
                <p
                  className="absolute top-0 left-0 text-gray-500 italic w-full text-center"
                  style={{ transform: "translateY(-50%)" }}
                >
                  Sign Here
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <button
          type="submit"
          className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white rounded-lg px-8 py-4 m-4"
        >
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default AdsccContract;
