import axios from "axios";
import React, { useRef, useState } from "react";

const Contract = () => {
  const formRef = useRef();
  const [status, setStatus] = useState(null);
  const [formData, setFormData] = useState(null);

  const handleSubmit2 = async (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
  };

  const [files, setFiles] = useState([]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHtml] = useState("");

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    // Append each file to the FormData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    formData.append("to", to);
    formData.append("subject", subject);
    formData.append("text", text);
    formData.append("html", html);

    try {
      const response = await axios.post(
        "http://localhost:1337/api/pdfmailer/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Email sent successfully:", response.data);
    } catch (error) {
      console.error(
        "Error sending email:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="to">To:</label>
          <input
            type="email"
            id="to"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="subject">Subject:</label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="text">Text:</label>
          <textarea
            id="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="html">HTML:</label>
          <textarea
            id="html"
            value={html}
            onChange={(e) => setHtml(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="file">Attachments:</label>
          <input
            type="file"
            id="file"
            onChange={handleFileChange}
            multiple
            required
          />
        </div>

        <button type="submit">Send Email</button>
      </form>

      <form
        ref={formRef}
        onSubmit={handleSubmit2}
        className="contract px-40 py-16"
      >
        <p>
          This CELLSAVE Stem Cell Storage Agreement is a Contract that has been
          developed between “ CELLSAVE ” and the “Client” which sets out the
          General Terms and Conditions by which CELLSAVE and the Client will
          cover all aspects of the Parties agreement.
        </p>
        <p>
          This is a very important document, which the Client should read
          through carefully.
        </p>
        <p>
          The Client has to complete the Contract as described below and return
          it to us. A copy will be emailed to the Client for their own
          reference/record.
        </p>
        <p>This document consists of the following sections:</p>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>     A. SERVICE AGREEMENT</strong>
          </span>{" "}
          <br />
          <span style={{ color: "#cf0072" }}>
            <strong>     B. INFORMED CONSENT</strong>
          </span>{" "}
          <br />
          <span style={{ color: "#cf0099" }}>
            <strong>
              <span style={{ color: "#cf0072" }}>     C. FEE SCHEDULE</span>    
            </strong>
          </span>
        </p>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>SERVICE AGREEMENT</strong>
          </span>
        </p>
        <ul>
          <li>
            As per Association for the Advancement of Blood &amp; Biotherapies
            (AABB) and International Standards, this form should be signed
            before the birth of your baby.
          </li>
          <li>
            Complete all parts of this form. This includes your full details,
            clinic/hospital, physician/ midwife, delivery due date, details for
            multiple births (if applicable), and loyalty reduction for returning
            Clients (if applicable).
          </li>
          <li>
            It is important that we have the Clients’ full names as per the
            passport.
          </li>
        </ul>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>INFORMED CONSENT</strong>
          </span>
        </p>
        <p>
          <ul>
            <li>
              As per AABB and International Standards, this form should be
              signed before the birth of your baby.
            </li>
            <li>Complete all parts of this form.</li>
            <li>
              This form gives us the consent for Cord Blood, Cord Tissue,
              Placental Tissue, Amnion Placental Tissue, and Cord Vessel
              collection.
            </li>
            <li>
              It is important that we have the Clients’ full names as per the
              passport.
            </li>
          </ul>
        </p>
        <p>
          <strong>
            <span style={{ color: "#cf0072" }}>FEE SCHEDULE</span>
          </strong>
        </p>
        <p>
          <ul>
            <li>
              This form provides the summary of fees payable, and will be in a
              separate page from this document.
            </li>
          </ul>
        </p>
        <p>
          <span style={{ color: "#8884d5" }}>
            <strong>
              <u>A. SERVICE</u>
            </strong>{" "}
            <strong>
              <u>AGREEMENT</u>
            </strong>
          </span>
        </p>
        <p>
          For the processing, testing, cryopreservation, and storage{" "}
          <span style={{ fontSize: "inherit" }}>
            of Cord Blood Stem Cells and/or (if applicable){" "}
          </span>
          Cord Tissue/Placental Tissue/Amnion Placental Tissue/ Cord Vessel,
          between
        </p>
        <p>
          CELLSAVE ARABIA FZ- LLC, a company with limited liability registered
          under the Dubai Healthcare City Authority (DHCCA), Organised and
          existing and operating under the laws of the United Arab Emirates;
        </p>
        <p>
          Having its registered office at P.O. Box 505152, Dubai, United Arab
          Emirates;
        </p>
        <p>
          Tel. +97143604410, E-mail Address:{" "}
          <a href="mailto:infoarabia@cellsave.com">infoarabia@cellsave.com</a>{" "}
          (Hereinafter referred to as “CELLSAVE ”).
        </p>
        <p>And</p>
        <p>
          The Parent(s)/Legal Guardian(s) listed below on their behalf and the
          behalf of the (unborn) Child
        </p>
        <p>
          (Hereinafter jointly to be referred to as the “Client” or the
          “Clients”) ( CELLSAVE and the Client, together, the “Parties”)
        </p>
        <p>
          <strong>(Client to fill in the relevant fields below)</strong>
        </p>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>MOTHER/LEGAL GUARDIAN</strong>
          </span>
        </p>
        <p>
          <strong>Last Maiden Name</strong>:
          <input type="text" name="mother-last-name" required />
        </p>
        <p>
          <strong>First Name</strong>:
          <input type="text" name="mother-first-name" required />
        </p>
        <p>
          <strong>Address</strong>:
          <input type="text" name="mother-address" required />
        </p>
        <p>
          <strong>Country</strong>:
          <select name="mother-country" required>
            {/* Insert country options here */}
            <option value="UAE">UAE</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Egypt">Egypt</option>
            {/* Continue listing all other countries */}
          </select>
        </p>
        <p>
          <strong>City</strong>:
          <input type="text" name="mother-city" required />
        </p>
        <p>
          <strong>Postal Code</strong>:
          <input type="mother-postal-code" name="postal-code" />
        </p>
        <p>
          <strong>Date of Birth</strong>:
          <select name="mother-dob-day" required>
            <option value="">Day</option>
            {Array.from({ length: 31 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <select name="mother-dob-month" required>
            <option value="">Month</option>
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </select>
          <select name="mother-dob-year" required>
            <option value="">Year</option>
            {Array.from({ length: 71 }, (_, i) => (
              <option key={1950 + i} value={1950 + i}>
                {1950 + i}
              </option>
            ))}
          </select>
        </p>
        <p>
          <strong>Telephone</strong>:
          <input type="text" name="mother-telephone" />
        </p>
        <p>
          <strong>Mobile</strong>:
          <input type="text" name="mother-mobile" required />
        </p>
        <p>
          <strong>Email Address</strong>:
          <input type="email" name="mother-email" required />
        </p>
        <p>
          <label>
            Mother Passport/EID:
            <input type="file" name="mother-passport" required />
          </label>
        </p>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>FATHER/LEGAL GUARDIAN</strong>
          </span>
        </p>
        <p>
          <strong>Last Name</strong>:
          <input type="text" name="father-last-name" required />
        </p>
        <p>
          <strong>First Name</strong>:
          <input type="text" name="father-first-name" required />
        </p>
        <p>
          <strong>Address</strong>:
          <input type="text" name="father-address" required />
        </p>
        <p>
          <strong>Country</strong>:
          <select name="father-country" required>
            {/* Insert country options here */}
            <option value="UAE">UAE</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Egypt">Egypt</option>
            {/* Continue listing all other countries */}
          </select>
        </p>
        <p>
          <strong>City</strong>:
          <input type="text" name="father-city" required />
        </p>
        <p>
          <strong>Postal Code</strong>:
          <input type="text" name="father-postal-code" />
        </p>
        <p>
          <strong>Date of Birth</strong>:
          <select name="father-dob-day" required>
            <option value="">Day</option>
            {Array.from({ length: 31 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <select name="father-dob-month" required>
            <option value="">Month</option>
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </select>
          <select name="father-dob-year" required>
            <option value="">Year</option>
            {Array.from({ length: 71 }, (_, i) => (
              <option key={1950 + i} value={1950 + i}>
                {1950 + i}
              </option>
            ))}
          </select>
        </p>
        <p>
          <strong>Telephone</strong>:
          <input type="text" name="father-telephone" />
        </p>
        <p>
          <strong>Mobile</strong>:
          <input type="text" name="father-mobile" required />
        </p>
        <p>
          <strong>Email Address</strong>:
          <input type="email" name="father-email" required />
        </p>
        <p>
          <label>
            Father Passport/EID:
            <input type="file" name="father-passport" required />
          </label>
        </p>
        <p>
          <strong>
            <span style={{ color: "#cf0072" }}>CLINIC/HOSPITAL</span>
            <br />
          </strong>
        </p>
        <p>
          <strong>Name</strong>:
          <input type="text" name="clinic-name" required />
        </p>
        <p>
          <strong>Country</strong>:
          <select name="clinic-country" required>
            {/* Insert country options here */}
            <option value="UAE">UAE</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Egypt">Egypt</option>
            {/* Continue listing all other countries */}
          </select>
        </p>
        <p>
          <strong>City</strong>:
          <input type="text" name="clinic-city" required />
        </p>
        <p>
          <strong>
            <span style={{ color: "#cf0072" }}>PHYSICIAN</span>
            <br />
          </strong>
        </p>
        <p>
          <strong>Last Name</strong>:
          <input type="text" name="physician-last-name" required />
        </p>
        <p>
          <strong>First Name</strong>:
          <input type="text" name="physician-first-name" required />
        </p>
        <p>
          <strong>Country</strong>:
          <select name="physician-country" required>
            {/* Insert country options here */}
            <option value="UAE">UAE</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Egypt">Egypt</option>
            {/* Continue listing other countries */}
          </select>
        </p>
        <p>
          <strong>City</strong>:
          <input type="text" name="physician-city" required />
        </p>
        <p>
          <strong>Due Date for Delivery</strong>:
          <select name="delivery-day" required>
            <option value="">Day</option>
            {Array.from({ length: 31 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
          <select name="delivery-month" required>
            <option value="">Month</option>
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </select>
          <select name="delivery-year" required>
            <option value="">Year</option>
            {Array.from({ length: 20 }, (_, i) => (
              <option key={2021 + i} value={2021 + i}>
                {2021 + i}
              </option>
            ))}
          </select>
        </p>
        <p>
          <label>
            Multiple Births Expected:
            <select name="multiple-births" required>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </label>
        </p>
        <p>
          If Yes, how many? <input type="number" name="how-many" />
        </p>
        <p>
          <label>
            I (We) have previously-stored cord blood with CELLSAVE:
            <select name="previous-storage" required>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </label>
        </p>
        <p>
          Reference of the Previous Child:
          <input type="text" name="previous-child-reference" />
        </p>
        <p>
          <span style={{ color: "#cf0072" }}>
            <strong>Service choice:</strong>
          </span>
        </p>
        <p>
          <strong>
            I (We), the undersigned Parent(s)/ and/or Legal Guardian(s) hereby
            confirm the following specific service from CELLSAVE:
          </strong>
        </p>
        <p>
          <label>
            <select name="service-choice" required>
              <option value="CryoPlus">CryoPlus</option>
              <option value="CryoAdvanced">CryoAdvanced</option>
              <option value="CryoUltimate">CryoUltimate</option>
              <option value="CryoInfinite">CryoInfinite</option>
            </select>
          </label>
        </p>
        <p>
          Add a 5 chamber Storage Bag option -
          <strong>
            <span style={{ color: "#cf0072" }}>
              You will be charged extra 1,000 AED?
            </span>
            <select name="extra-storage-bag" required>
              <option value="Yes">Yes</option>
              <option selected value="No">
                No
              </option>
            </select>
          </strong>
        </p>
        <p>
          <strong>
            <span style={{ color: "#cf0072" }}>KIT ID:</span>
            <input type="text" name="kit-id" required />
          </strong>
        </p>
        <p>&nbsp;</p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#8884d5" }}>
            <strong>
              <u>GENERAL</u>
            </strong>{" "}
            <strong>
              <u>TERMS AND CONDITIONS</u>
            </strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <strong>
            <span style={{ color: "#cf0072" }}>1. PURPOSE:</span>
          </strong>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The purpose of this document is to set out the terms, conditions,
          limitation, risks, and costs of the processing, testing,
          cryopreservation, and storage in an authorized cellular processing and
          storage unit release of the Product (as defined below) obtained from
          the Specimen (as defined below) collected at the time of birth of the
          Child (as defined below) of the Client.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          In this Service Agreement, the following definitions shall have the
          following meaning:
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Amnion Placental Tissue”</em>   - the thin and elastic membrane
          lining inside of the placental sac.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Child”</em> - “Any child identified by the Client in the Informed
          Consent in Section B;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>"Blood Cord”</em> - Blood collected from the umbilical cord and
          placenta of the Child following delivery;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Cord Tissue”</em> - Tissue taken from the umbilical cord of the
          Child following delivery;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Cord Vessel”</em> - Cell types that lines the Cord Tissue taken
          from the Child following delivery;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Enrollment Fee”</em> - The meaning ascribed to it in the Fee
          Schedule in Section C;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Extended Storage Period”</em> - The meaning ascribed to it in
          Section 6; 
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Fees”</em> - The meaning ascribed to it in Section 4;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Informed Consent”</em> - The consent attached to this Service
          Agreement under which the Client gives their informed consent to the
          storage of the specimen;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Initial Storage Period”</em> - The meaning ascribed to it in
          Section 6;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Maternal Blood Test”</em> - Tests which must be undertaken on the
          blood of the mother of the child (as identified and approved in the
          Informed Consent in Section B) to assess whether the Specimen is
          suitable for storage in accordance with international and local
          guidelines and regulations and as otherwise required thereunder;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Medical and Health History Profile”</em> - The questionnaire
          completed by the Client under which the Client provides CELLSAVE with
          certain information about his/her/their medical history. Refer to
          separate Medical and Health History Profile booklet;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Processing Fee”</em> - The meaning ascribed to it in the Fee
          Schedule in Section C; 
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Placental Tissue”</em> - Organ that connects the developing fetus
          to the uterine wall; 
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Storage Fee”</em> - The meaning ascribed to it in the Fee
          Schedule in Section C;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Product”</em> - The cryopreserved Stem Cells and the
          cryopreserved Cord Tissue (and, in the event that the CryoAdvanced
          Service is elected, the cryopreserved Stem Cells, the cryopreserved
          Cord Tissue, and the cryopreserved Placental Tissue), (and, in the
          event that the CryoUltimate Service is elected, the cryopreserved Stem
          Cells, the cryopreserved Cord Tissue, the cryopreserved Placental
          Tissue, the cryopreserved Amnion Tissue, and the cryopreserved Cord
          Vessel);
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Release Form”</em> - The meaning ascribed to it in Section 8;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Service Agreement"</em> - This Service Agreement between the
          Client and CELLSAVE which shall include the Service Agreement in
          Section A, the general terms and conditions, the informed consent in
          Section B, the Medical Health History Profile booklet, and the fee
          schedule in section C;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Specimen”</em> - The Cord Blood and Cord Tissue (and in the event
          that the CryoAdvanced Service is elected, the Cord Blood, Cord Tissue,
          and Placental Tissue), (and in the event that the CryoUltimate Service
          is elected, the Cord Blood, Cord Tissue, Placental Tissue, Amnion
          Placental Tissue, and Cord Vessel);
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Stem Cells”</em> - The Stem Cells extracted from the Cord Blood;
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <em>“Unit”</em> - The meaning ascribed to it above.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          By signing this Service Agreement, the Client agrees to be legally
          bound to CELLSAVE for the processing, testing, cryopreservation, and
          storage of the Specimen in any Unit. The Client also acknowledges that
          he/ she/they has/have been fully informed, and accept( s) and agree(s)
          to the conditions, risks, limitations, and associated costs of such
          activities, in accordance with these terms and conditions.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>
              2. SERVICES OF CELLSAVE:
              <br />
            </strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE’s obligations and liabilities are expressly limited to the
          services described in this Service Agreement; no other services will
          be provided by CELLSAVE to the Client and CELLSAVE expressly disclaims
          any responsibility to provide any other services other than the ones
          stated in this Service  Agreement.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE will not put the Specimen to use for any purpose other than
          to enable CELLSAVE to provide the Service to the Client as per this
          Service Agreement.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          If the Client elects to use the CELLSAVE services the following
          provisions shall apply:
        </p>
        <p style={{ paddingLeft: "40px" }}>
          On receipt of the Specimen, CELLSAVE will use best endeavors to
          process and cryopreserve Stem Cells from the Specimen for the purpose
          of direct application in humans, provided a sufficient number of
          viable Stem Cells as per its acceptance criteria can be identified,
          CELLSAVE will store the Product for the Initial Storage Period (as
          defined in Section 6 below) or longer as agreed in accordance with
          Section 6.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          In the event, after processing has commenced, there are insufficient
          Stem Cells in the Product or CELLSAVE is unable to harvest collected
          Stem Cells from the Specimen or if CELLSAVE determines that storage
          for other reasons is not possible (e.g. the Specimen is damaged or
          contaminated), no storage will take place and refunds will be as
          stipulated in Section C of the Fee schedule.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          In the event where processing has not taken place due to reasons such
          as Specimen is received by CELLSAVE more than 72 hours after the birth
          of the Child, or the collected Specimen volume is too small, refunds
          will be as stipulated in section C of the Fee schedule.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          With respect to Cord Tissue, on receipt of the Specimen, CELLSAVE will
          use its best endeavors to prepare it for cryopreservation and storage.
          In the event that the Cord Tissue has not been collected following the
          instructions, or if CELLSAVE determines that storage is for other
          reasons not possible (e.g. the Specimen is received by CELLSAVE more
          than 72 hours after the birth of the Child; the Specimen is damaged or
          contaminated), refunds will be as stipulated in section C of the Fee
          schedule.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          If the sample/unit is being used for a transplant within 5 years (from
          the date of delivery of the baby), CELLSAVE will give the Client a
          “transplant assistance” valued at 15,000 AED.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <strong>
            <span style={{ color: "#cf0072" }}>
              3. RESPONSIBILITIES OF CLIENT:
            </span>
            <br />
          </strong>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client agrees to complete all necessary forms accurately and in a
          timely manner as CELLSAVE may from time to time reasonably consider as
          required or deemed beneficial to the Service in view of applicable
          legislation or industry standards.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client is responsible for arranging the collection of the Specimen
          by the physician, obstetrician, or other medical staff at the birth of
          the Child as per the instructions received from CELLSAVE. It is the
          responsibility of the Client to either contact CELLSAVE or the courier
          company designated by CELLSAVE after the Specimen has been collected.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE will arrange for transport of the Specimen to the CELLSAVE
          designated Unit, on the Client’s behalf, immediately after having
          received notification by the Client of the collection of the Specimen
          by the physician, obstetrician, or other medical staff at the birth of
          the Child. CELLSAVE expressly excludes all liability for the
          collection of the Specimen by the physician, obstetrician, or other
          medical staff at the birth of the Child.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE expressly excludes all liability for the transportation of
          the Specimen if done by a third party.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client agrees to act at all times in accordance with all
          applicable local legislation and regulations and will be responsible
          for obtaining all necessary documents and permits if so required for
          the collection and export of the Specimen to the Unit.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>4. FEES AND PAYMENTS:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The fee for the Service (the “Fee”) and the payment conditions
          applicable to the Service are laid down in the Fee Schedule in Section
          C. The Fee consists of a non-refundable Enrollment Fee and a
          Processing and Storage Fee and certain other fees, in each case as
          further described in the Fee Schedule in Section C. Client agrees to
          pay the Fees on time and in accordance with the Fee Schedule in
          Section C.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Clients understand and agree that they are jointly and severally
          liable for payment of the Fees, which means that all of the Clients
          are liable to pay the Fees, and each Client is jointly and severally
          liable for any unpaid portion of the total fee. This applies even if
          Clients who are married or live together become divorced, separated,
          or otherwise estranged. 
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>5. STORAGE:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Specimen and Product will be processed, tested, cryopreserved, and
          stored in accordance with the following terms:
        </p>
        <p style={{ paddingLeft: "80px" }}>
          a. CELLSAVE stores the Product in an authorized Unit. The Cord Blood
          Product will be split and stored in two physically separate locations.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          b. CELLSAVE may assign and transfer any or all of its rights and
          obligations under this Service Agreement to any third party, for which
          the Client hereby gives consent. CELLSAVE will notify the Client in
          writing to that effect.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          c. CELLSAVE does not give, nor does it purport to give, any medical
          advice, or otherwise perform any other function for the Client other
          than the services expressly described in this Service Agreement.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          d. Client hereby consents to the collection, transport, and testing of
          the Specimen and to the processing, cryopreservation, and storage of
          the Specimen in and to a Unit as stated by this Service Agreement.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          e. The Client’s right to storage and release of the Product is subject
          to the timely and full payment of all Fees by the Client in accordance
          with this Service Agreement.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>6. TERMS AND TERMINATION:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          These terms and conditions come into force on the date of receipt by
          CELLSAVE of the Service Agreement signed by the Client and will
          continue for the period mentioned in the Payment Plan from the date of
          the birth of the Child (the “Initial Storage Period”).
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Six months prior to the end of the Initial Storage Period, an
          agreement can be made upon the mutual agreement of the Parties in the
          event that Client wishes CELLSAVE to continue to store the Product on
          their behalf for a further period (the “Extended Storage Period”), and
          such extended storage shall be at the market prices valid at that
          time. If 30 days before the expiry of this Service Agreement, Parties
          have not agreed to the terms of an Extended Storage Period of the
          Product, or upon the termination of this Service Agreement by CELLSAVE
          or the Client in accordance with this Clause 6, the Client
          relinquishes all rights in and waives all rights to the Product and
          CELLSAVE shall have the right to destroy the Product.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          This Service Agreement may be terminated prior to its term by written
          notice with immediate effect:
        </p>
        <p style={{ paddingLeft: "80px" }}>
          a. By Client at any time prior to the collection of the Specimen. In
          this case, a minimal fee will be charged as indicated in the Fee
          Schedule in Section C.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          b. By CELLSAVE if the Specimen and/or Product are not suitable for
          storage, whether for medical, safety, practical, or any other reason,
          as determined by CELLSAVE in its sole and absolute discretion (e.g.
          the Specimen is received by CELLSAVE more than 72 hours after the
          birth of the Child; the Specimen is damaged; the collected Specimen
          volume is too small). In these situations, CELLSAVE will not store the
          Specimen and/or CELLSAVE retains the sole right to make this decision.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          c. By CELLSAVE if CELLSAVE does not receive the Enrollment Fee and/or
          Processing and Storage Fee within the specific time.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Termination of this Service Agreement under clause 6c above will not
          affect the Client’s responsibility for payment in full of all amounts
          invoiced and of all additional administrative and legal costs related
          to collecting the outstanding amounts. On termination of this Service
          Agreement, CELLSAVE reserves the right to destroy the Specimen and/or
          the Product.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>7. RIGHTS TO PRODUCT:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Unless otherwise defined by applicable law, the Product remains the
          property of the Child throughout the term of this Agreement. Until the
          Child reaches legal age, the Client has the right and responsibility
          to consent to the collection, storage, use, and disposal of the
          Product on behalf of the Child.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>8. RELEASE REQUEST:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          In the event that the Product is required for treatment or
          manufacturing of a medicinal product, the Client shall provide written
          notification of the same to CELLSAVE. Any request for release has to
          be addressed to CELLSAVE Arabia, P.O. Box 505152, Dubai Healthcare
          City, Dubai, UAE, to the attention of the Medical Director, or to the
          e-mail address{" "}
          <a href="mailto:infoarabia@cellsave.com">infoarabia@cellsave.com.</a>{" "}
          The notice shall include the name and address of the physician and
          hospital to which the Product must be sent or the appropriately
          accredited Current Good Manufacturing Practices (cGMP) manufacturer.
          CELLSAVE shall then provide the authorized hospital or manufacturer
          with a release form (the “Release Form”) which must be returned to
          CELLSAVE. CELLSAVE will arrange for transport of the Product to the
          hospital, on the Client’s behalf.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE expressly excludes all liability for the transportation of
          the Product conducted by a third party.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE will not charge the Client any costs involved with the
          transport of the Product to the destination indicated on the Release
          Form.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>9. ACKNOWLEDGEMENT:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client understands that the actual collection of the Specimen by
          the physician, obstetrician, or other medical staff at the birth of
          the Child is not part of the Service and that the relevant doctor,
          obstetrician, or other medical staff may under certain circumstances
          refuse, be unable or fail to collect and/or adequately pack the
          Specimen and that neither CELLSAVE nor its officers, shareholders,
          employees, agents, and contractors bear any responsibility for any
          mistakes or damages caused by such doctor, obstetrician or other
          medical staff.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client also understands and confirms that CELLSAVE, its officers,
          directors, employees, shareholders, agents, and consultants have not
          made any representations and bear no responsibilities and obligations
          with respect to the possibility to collect Stem Cells successfully
          from the Specimen, the suitability of the Stem Cells for the
          contemplated treatment, the successful treatment of any diseases
          through Stem Cell transplantation or therapies, and the advantages of
          umbilical Cord Blood Stem Cell usage over other therapies using Stem
          Cells.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>10. LIMITATION OF LIABILITY:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE’s responsibility towards the Client and/or the Child whose
          Product/s are being stored is limited to the Service described in this
          Service agreement. Client agrees that should he/she/they make any
          claim against CELLSAVE, CELLSAVE’s liability shall, to the extent
          allowed under applicable law, regardless of the basis of such claim,
          whether in contract, tort or otherwise, be limited in total and in
          aggregate to (a) the amount of the Fee paid by the Client to CELLSAVE
          under this Service Agreement, or (b) in the event of CELLSAVE’s
          negligence, the amount permitted to be recovered by CELLSAVE under the
          malpractice insurance maintained by CELLSAVE in connection with such
          claims.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>11. DATA PROTECTION:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The Client agrees to the processing and storage of personal data
          (including personal data of the Child and the mother and father of the
          Child), by CELLSAVE or its agent, affiliate, or subcontractor, which
          has come to CELLSAVE’s knowledge in the course of performing the
          Services and otherwise under this Service Agreement. The Client agrees
          and permits CELLSAVE to inform the status of infectious disease report
          of the mother of the Child and the Cord Blood and Cord Tissue and/or
          Placental Tissue/Amnion Placental Tissue/Cord Vessel to the United
          Arab Emirates Government regulatory agencies including the Ministry of
          Health, the Dubai Health Authority, Dubai Healthcare City Authority or
          any other agency mandated under United Arab Emirates laws or under any
          other law to which CELLSAVE is subject.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE will use the Client’s personal data only to the extent
          required by its contractual obligations hereunder and in accordance
          with the applicable laws and regulations of the United Arab Emirates.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>12. FORCE MAJEURE:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE cannot be held liable for, and expressly excludes all
          liability for, any possible loss or damage due to natural disasters,
          an act of war or terror, riot, strike, vandalism, acts or omission of
          authorities, or any events beyond CELLSAVE‘s control which cause
          destruction of, or deterioration to the Specimen and/or Product.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>13. NOTICE:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          If the Client needs to send CELLSAVE any notices or communications
          under the Service Agreement, the Client must send them in writing by
          first-class or registered post/recorded delivery to the CELLSAVE
          address as set out in Section 13 below or any alternative address that
          CELLSAVE notification to the Client.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          If CELLSAVE needs to send the Client any notices or communications
          under the Service Agreement CELLSAVE will send them in writing by
          first-class or registered post/recorded delivery to the Client’s
          address as notified by the Client to CELLSAVE on page 1. The Client
          shall as soon as possible notify CELLSAVE in writing of any changes in
          the information provided, including relocation and/or change of
          address. All notifications to CELLSAVE shall be sent to CELLSAVE
          Arabia, P.O. Box 505152, Dubai Healthcare City, Al Razi Medical
          Complex 64, Dubai, UAE, or to the e-mail address:
          infoarabia@cellsave.com. CELLSAVE may completely rely on the data made
          available in writing by the Client, without any obligation to make
          further inquiries with respect to the correctness or actuality of such
          data.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>14. COMPLIANCE WITH UAE LAWS AND REGULATIONS:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          CELLSAVE operates and exists in the United Arab Emirates and thus is
          required to comply with the laws of the United Arab Emirates and the
          regulatory and legal requirements of governmental agencies such as the
          Ministry of Health, the Dubai Health Authority, and the Dubai
          Healthcare City Authority. The Client hereby agrees that CELLSAVE may
          take any action required by it in order to comply with the laws of the
          United Arab Emirates, as well as the regulatory and legal requirements
          of governmental agencies such as the Ministry of Health, the Dubai
          Health Authority, and the Dubai Healthcare City.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>15. GOVERNING LAW:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          This Service Agreement shall be governed by, and construed in
          accordance with, the laws of United Arab Emirates. The Parties may
          bring suit in any court of competent jurisdiction.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>16. ELECTRONIC COMMUNICATION:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "80px" }}>
          a. The Client(s) acknowledges that this agreement does not infringe
          any provision of the Federal Law No. (1) on Electronic Commerce and
          Transactions, of 2006.
        </p>
        <p style={{ paddingLeft: "80px" }}>
          b. This agreement shall constitute a valid and binding agreement in
          accordance with Article 18 of the Federal Law No. (1) on Electronic
          Commerce and Transactions of 2006. No written confirmation or
          reciprocal signature by the Client(s) and/or CELLSAVE is required to
          give effect to the rights and obligations established by the Service
          Agreement.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>17. HEADINGS:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          The headings contained in this Service Agreement are for reference
          purposes only and shall not affect the meaning or interpretation of
          this Service Agreement.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>18. SEVERABILITY:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Should any provision of this Service Agreement be invalid or
          unenforceable or should this Service Agreement contain an omission,
          the remaining provisions shall remain valid. In the place of an
          invalid provision or an omission, a new, valid provision – which comes
          economically closest to the one actually agreed upon provision or, in
          the event of an omission, the intentions set forth in this Service
          Agreement – is presumed to be agreed upon by the Parties.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>19. ENTIRE AGREEMENT:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          This Service Agreement constitutes the entire understanding between
          the Parties with respect to the subject matter of this Service
          Agreement and supersedes all prior agreements, negotiations, and
          discussions between the Parties relating to it.
        </p>
        <p style={{ paddingLeft: "40px" }}>
          <span style={{ color: "#cf0072" }}>
            <strong>20. AMENDMENT:</strong>
          </span>
        </p>
        <p style={{ paddingLeft: "40px" }}>
          Neither Party can change or amend the terms of this Service Agreement
          without the prior written approval of the other Party.
        </p>
        <p style={{ paddingLeft: "40px" }}> </p>
        <p>
          <span style={{ color: "#8884d5" }}>
            <strong>
              <u>B. INFORMED CONSENT</u>
            </strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          I/We on behalf of my/our unborn Child wish to enroll in the Umbilical
          Cord Blood and Cord Tissue (and/or, if applicable, Placental
          Tissue/Amnion Placental Tissue/Cord Vessel) banking service offered by
          CELLSAVE. The Specimen will be uniquely identified, stored, and
          maintained at a cryogenic storage facility. CELLSAVE will retrieve
          these cells at my/our request as per the Service Agreement. I/We agree
          to its terms of understanding as outlined below, and consent to have
          the Service performed.
        </p>
        <p style={{ textAlign: "left" }}>
          I/We understand that collection and storing my/our newborn’s Stem
          Cells may potentially benefit my/our Child should he or she need them
          in the future to treat certain diseases. I/We understand that it is
          not possible to determine whether my/our Child will develop a disease
          in the future that can be treated with these cells. I/We understand
          that these newborn Stem Cells are a perfect match for my/our Child
          and, while there is no guarantee my/our child will ever need them, the
          fact that they are a perfect match can reduce serious complications
          should cell therapy ever be needed. I/We understand that my Child’s
          cells may be suitable for use by other family members.
        </p>
        <p style={{ textAlign: "left" }}>
          I/We understand that, although infrequent, complications may occur at
          birth, and it may not be possible to collect Cord Blood and Cord
          Tissue (and/or, if applicable, Placental Tissue/ Amnion Placental
          Tissue /Cord Vessel) from my/our Child. Therefore, collection of Cord
          Blood and Cord Tissue (and/ or, if applicable, Placental Tissue/
          Amnion Placental Tissue/Cord Vessel) from my/our Child cannot be
          guaranteed as its collection is arranged between my/ our
          physician/midwife and me/us. My/Our health and the health of my/our
          newborn is my/our physician/midwife’s first priority. I/We agree that
          my/our physician/midwife’s judgment shall be absolute and final.
        </p>
        <p style={{ textAlign: "left" }}>
          I/We understand that CELLSAVE will perform cell viability, cell
          counts, blood typing, and bacterial and fungal tests on the Cord Blood
          and Cord Tissue (and/or, if applicable, Placental Tissue/ Amnion
          Placental Tissue /Cord Vessel) to determine the nature and quality of
          the Cord Blood and Cord Tissue (and/or, if applicable, Placental
          Tissue/ Amnion Placental Tissue/Cord Vessel). I/We understand that
          regulatory and accreditation associations require certain tests and
          I/we fully consent to provide the Maternal Blood Test. I/We consent to
          the use of a sample from the blood of the mother of the Child to be
          tested for certain infectious diseases . This is in accordance with
          the AABB, EU and International Directives, guidelines for tissue
          banks, and as otherwise required under the laws of the United Arab
          Emirates.
        </p>
        <p style={{ textAlign: "left" }}>
          I/We understand that if the test results of the sample are confirmed
          positive for HIV, the Cord Blood and Cord Tissue (and/or, if
          applicable, Placental Tissue/ Amnion Placental Tissue /Cord Vessel)
          will not be stored. I/We understand that if the tests indicate the
          possible presence of hepatitis B or C, syphilis, or if my responses to
          the health questionnaire indicate a risk of one of these infections,
          my/our Child’s Cord Blood and Cord Tissue (and/or, if applicable,
          Placental Tissue/ Amnion Placental Tissue /Cord Vessel) will be
          processed and cryopreserved only with the approval of the CELLSAVE
          Medical Director. Based on the review of these results, I/we
          understand that CELLSAVE may decide not to permanently store the Cord
          Blood and Cord Tissue (and/or, if applicable, Placental Tissue/ Amnion
          Placental Tissue/Cord Vessel). If this occurs, I/we will be notified
          in writing of this decision. I/We authorize CELLSAVE to provide me
          with test results and to furnish them to my primary physician, my
          partner, or/and to government agencies as only required by law. If the
          Maternal Blood Test is positive for one of the infectious diseases
          tested for, the Client(s) should sign a continuation form provided by
          CELLSAVE.
        </p>
        <p style={{ textAlign: "left" }}>
          I/We understand that CELLSAVE will follow the United Arab Emirates
          regulations and will follow the same regulations for Cord Blood, Cord
          Tissue, Placental Tissue, Amnion Placental Tissue/Cord Vessel
          collection, and the Maternal Blood Test. I/We understand that as per
          the regulations of the United Arab Emirates, CELLSAVE processes and
          cryopreserves Cord Blood, Cord Tissue, Placental Tissue, Amnion
          Placental Tissue, and Cord Vessel collected from full-term live babies
          only, and does not process or cryopreserve Cord Blood, Cord Tissue,
          Placental Tissue, Amnion Placental Tissue, and Cord Vessel collected
          from an abortus. I/We understand that the Client(s) are responsible
          for the barcodes provided to him/her/them and issued by CELLSAVE.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Please tick below to agree.</strong>
        </p>
        <p>
          <p>
            <input
              type="checkbox"
              name="esig-sif-1662362712879-1"
              id="esig-sif-1662362712879-1"
              required
            />
            <label htmlFor="esig-sif-1662362712879-1">
              I/We, the undersigned Parents(s)/and/or Legal Guardian(s) hereby
              accept the General Terms and Conditions, which are attached to
              this Service Agreement.
            </label>
          </p>

          <p>
            <input
              type="checkbox"
              name="esig-sif-1662362712879-2"
              id="esig-sif-1662362712879-2"
              required
            />
            <label htmlFor="esig-sif-1662362712879-2">
              I/We (on behalf of myself and the other Client) affirm that I/We
              have fully understood the information provided and have had the
              opportunity to ask questions and have been provided with
              satisfactory responses.
            </label>
          </p>

          <p>
            <input
              type="checkbox"
              name="esig-sif-1662362712879-3"
              id="esig-sif-1662362712879-3"
              required
            />
            <label htmlFor="esig-sif-1662362712879-3">
              I/We herewith give my/our Informed Consent for the Cord Blood and
              Cord Tissue (and/or, if applicable, Placental Tissue/Amnion
              Placental Tissue/Cord Vessel) Collection.
            </label>
          </p>

          <p>
            <input
              type="checkbox"
              name="esig-sif-1662362712879-4"
              id="esig-sif-1662362712879-4"
              required
            />
            <label htmlFor="esig-sif-1662362712879-4">
              I(We), the undersigned Parent(s)/and/or Legal Guardian hereby
              accept the Fee Schedule.
            </label>
          </p>

          <p>
            <input
              type="checkbox"
              name="esig-sif-1662362712879-5"
              id="esig-sif-1662362712879-5"
              required
            />
            <label htmlFor="esig-sif-1662362712879-5">
              I/We understand and agree that I/We are jointly and severally
              liable for payment of the Fees.
            </label>
          </p>
        </p>
        <p style={{ fontSize: "9px" }}>
          Code: C.2.50-4B/V1 CSA Agreement-English
        </p>
        <p>&nbsp;</p>
        <p>
          <p style={{ color: "#cf0072" }}>
            <strong>
              Please enter your Full Name and Email Address to receive a copy of
              agreement. This will be considered as signed copy of agreement
            </strong>
          </p>
          <p>
            {" "}
            Name <input type="text" name="signed-name" required />
          </p>
          <p>
            {" "}
            Email <input type="Email" name="signed-email" required />
          </p>
        </p>
        <button
          className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
          type="submit"
        >
          {status ? status : "Submit"}
        </button>
      </form>
    </>
  );
};

export default Contract;
