import axios from "axios";
import { ApiUrl } from "./components/ApiUrl";

const API_URL = "http://localhost:5000/api/faqs";

export const getAllFAQs = async () => {
  try {
    const response = await axios.get(ApiUrl + "/api/faqs/");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching FAQs:", error);
    throw error;
  }
};

export const getFAQById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching FAQ with id ${id}:`, error);
    throw error;
  }
};

export const getFAQsByCategory = async (category) => {
  try {
    const response = await axios.get(
      ApiUrl + "/api/faqs-categories/" + category + "?populate=*"
    );
    return response.data.data.attributes.faqs.data;
  } catch (error) {
    console.error(`Error fetching FAQs in category ${category}:`, error);
    throw error;
  }
};

export const searchFAQs = async (query) => {
  try {
    const response = await axios.get(
      ApiUrl +
        "/api/faqs?filters[Title][$contains]=" +
        query +
        "&filters[Body][$contains]=" +
        query
    );
    return response.data.data;
  } catch (error) {
    console.error(`Error searching FAQs with query ${query}:`, error);
    throw error;
  }
};
